import React, { useState } from "react";
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import api from '../../services/api'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import Modal from '../Modal/Modal';
import CustoItens from '../Table/CustoItens'
import ModalFollow from '../../components/Modal/ModalFollow'
import Follow from '../../components/Follow'

  const StyledTableRow = withStyles(() => ({
    root: {
      fontSize: '.6rem',
      '&:nth-of-type(odd)': {
        backgroundColor: '#2c2c2c',
      },
      body: {
        
      }
    },
  }))(TableRow);
    
  const StyledTableCell = withStyles(() => ({
    root: {
        borderBottom: "none"
      },
    head: {
        backgroundColor: "#2c2c2c",
        color: "#858585",
        fontSize: 12,
        fontWeight: 'bold',
    },
    body: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#8F929B'
    }
  }))(TableCell);
   const useStyles = makeStyles(() => ({
      root: {
          backgroundColor: "#8F929B",
          
        },
      tablePagination: {
        },
      tablePaginationCaption: {
          color:  "#8F929B",
          fontWeight: 'bold',
          fontSize: '0.9rem'
        },
      tablePaginationSelectIcon: {
          color:  "#8F929B",
          fontWeight: 'bold',
        },
      tablePaginationSelect: {
          color:  "#8F929B",
          fontWeight: 'bold',
        },
      tablePaginationActions: {
          color:  "#8F929B",
          fontWeight: 'bold',
        },
  }));
  
  export default function   TableProcessos ({data}){
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [getTitle, setTitle] = React.useState('');
    const [dataCustoItens, setDataCustoItens] = useState([]);  
    const [dataProcessoFollow, setDataProcesso] = useState([]);
    const [dataTracking, setDataTracking] = useState([]);
    const [dataFollowUp, setDataFollowUp] = useState([]);
    const [getStateItens, setStateItens] = React.useState(false);
    const [getStateFollowUp, setStateFollowUp] = React.useState(false);
  
    const showModalFollowUp = () => {
        setStateFollowUp(true);
    }
    
    const hideModalFollowUp = () => {
        setStateFollowUp(false);
    }

    const showModalItens = () => {
        setStateItens(true);
    }
    
    const hideModalItens = () => {
        setStateItens(false);
    }

    const handleClickOpenCustosItens = (idProcesso, processo, ano) => {    
        const GetDataCustoItens = async () => {    
          api.get('/api/empresas/custoprocesso/'+processo+"&"+ano)
            .then(response => {      
            setDataCustoItens(response.data.result);        
            });    			    
        }  
        GetDataCustoItens();
        setTitle("Processo: " + processo + "/" + ano);
        showModalItens(true);
    };
    
    const handleClickOpenFollowUp = (event, processo, ano) => { 
        const GetData = async () => {    
        const result = await api.get('/api/empresas/f/'+processo+"&"+ano);  
        setDataProcesso(result.data.cabecalho);
        setDataTracking(result.data.tracking);
        setDataFollowUp(result.data.followup);                        
        }  
        GetData();    
        setTitle("Processo: " + processo + "/" + ano);
        showModalFollowUp(true);
    }

    const handleChangePage = (event, newPage) => {  
        setPage(newPage);  
    };  
    
    const handleChangeRowsPerPage = event => {  
        setRowsPerPage(+event.target.value);  
        setPage(0);  
    };  

  
    function descendingComparator(a, b, orderBy) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
  
    function getComparator(order, orderBy) {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }
  
    function stableSort(array, comparator) {      
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      setSelected(newSelected);
    };
  
    return (
      <>
       
          <TableContainer>
            <Table aria-labelledby="tableTitle" size='small' aria-label="enhanced table">  
              <TableHead> 
                <TableRow>
                    <StyledTableCell align="center" colSpan={8} style={{borderRight: '1px solid grey', backgroundColor: '#2c2c2c'}}>REFERÊNCIAS DO PROCESSO</StyledTableCell>
                    <StyledTableCell align="center" colSpan={6} style={{borderRight: '1px solid grey', backgroundColor: '#2c2c2c'}}>HISTÓRICO DO EMBARQUE</StyledTableCell>
                    <StyledTableCell align="center" colSpan={6} style={{backgroundColor: '#2c2c2c'}}>HISTÓRICO DO DESEMBARAÇO</StyledTableCell>                    
                </TableRow>
                <TableRow style={{borderBottom: '3px solid #858585 '}}>
                    <StyledTableCell align="center" style={{whiteSpace: 'nowrap',}}>PROCESSO</StyledTableCell>
                    <StyledTableCell align="center" style={{whiteSpace: 'nowrap',}} className="App__table__body">TIPO PROCESSO</StyledTableCell>
                    <StyledTableCell align="center" style={{whiteSpace: 'nowrap',}}>STATUS</StyledTableCell>                            
                    <StyledTableCell align="center" style={{whiteSpace: 'nowrap',}}>REF. CLIENTE</StyledTableCell>
                    <StyledTableCell align="center" style={{whiteSpace: 'nowrap',}}>FORNECEDOR</StyledTableCell>
                    <StyledTableCell align="center" style={{whiteSpace: 'nowrap',}}>FATURA(S)</StyledTableCell>
                    <StyledTableCell align="center" style={{whiteSpace: 'nowrap',}}>PESO BRUTO</StyledTableCell>
                    <StyledTableCell align="center" style={{borderRight: '1px solid grey'}}>PESO LÍQUIDO</StyledTableCell>
                    <StyledTableCell align="center" style={{whiteSpace: 'nowrap',}}>MODALIDADE</StyledTableCell>
                    <StyledTableCell align="center" style={{whiteSpace: 'nowrap',}}>TRANSPORTADOR</StyledTableCell>
                    <StyledTableCell align="center" style={{whiteSpace: 'nowrap',}}>ORIGEM</StyledTableCell>
                    <StyledTableCell align="center" style={{whiteSpace: 'nowrap',}}>DATA EMBARQUE</StyledTableCell>
                    <StyledTableCell align="center" style={{whiteSpace: 'nowrap',}}>DESTINO</StyledTableCell>
                    <StyledTableCell align="center" style={{borderRight: '1px solid grey'}}>DATA CHEGADA</StyledTableCell>
                    <StyledTableCell align="center" style={{whiteSpace: 'nowrap', width: '20%'}}>DI</StyledTableCell>
                    <StyledTableCell align="center" style={{whiteSpace: 'nowrap',}}>REGISTRO</StyledTableCell>
                    <StyledTableCell align="center" style={{whiteSpace: 'nowrap',}}>CANAL</StyledTableCell>
                    <StyledTableCell align="center" style={{whiteSpace: 'nowrap',}}>LIBERAÇÃO</StyledTableCell>
                    <StyledTableCell colspan={2} align="center" style={{whiteSpace: 'nowrap',}}>VALORES</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {  
               
                    return (  
                        <StyledTableRow key={row.processoid}>
                            <td align="center" style={{width: '2%', height: 'auto',  textAlign: 'center'}} className="App__table__cell">
                                <Tooltip title="Follow-UP" aria-label="Follow-UP">
                                    <button  onClick={(event) => handleClickOpenFollowUp(event, row.nro_pro, row.ano_pro)} className="App__table__btn__grey">
                                    {row.nro_pro}/{row.ano_pro}
                                    </button>
                                </Tooltip>
                            </td>
                            <td align="center" style={{whiteSpace: 'nowrap', textAlign: 'center'}} className="App__table__cell">{row.tipo_pro}</td>
                            <td align="center" style={{whiteSpace: 'nowrap'}} className="App__table__cell">
                                {row.dt_enc_pro === null ? <button variant="outlined" size="small" className="App__table__btn__ativo">ABERTO</button> : <button variant="outlined" size="small" className="App__table__btn__cancelado">ENCERRADO</button>}
                                {row.CanceladoData === '' ? <button variant="outlined" size="small" className="App__table__btn__ativo">ATIVO</button> : <button variant="outlined" size="small" className="App__table__btn__cancelado">CANCELADO</button>}
                            </td>                                
                            <td align="center" style={{whiteSpace: 'nowrap',}} className="App__table__cell">{row.ident_cli_pro}</td>
                            <td align="center" style={{whiteSpace: 'nowrap',}} className="App__table__cell">{row.fornecedor}</td>
                            <td align="center" style={{whiteSpace: 'nowrap',}} className="App__table__cell">{row.fatura}</td>
                            <td align="center" style={{whiteSpace: 'nowrap',}} className="App__table__cell">{row.peso_bruto_pro}</td>
                            <td align="center" style={{borderRight: '1px solid grey'}} className="App__table__cell">{row.peso_liquido_merc_pro}</td>
                            <td align="center" style={{whiteSpace: 'nowrap',}} className="App__table__cell">{row.nome_fretemodo}</td>
                            <td align="center" style={{whiteSpace: 'nowrap',}} className="App__table__cell">{row.transportador}</td>
                            <td align="center" style={{whiteSpace: 'nowrap',}} className="App__table__cell">{row.origem}</td>
                            <td align="center" style={{whiteSpace: 'nowrap',}} className="App__table__cell">{row.dataembarque}</td>
                            <td align="center" style={{whiteSpace: 'nowrap',}} className="App__table__cell">{row.destino}</td>
                            <td align="center" style={{borderRight: '1px solid grey'}} className="App__table__cell">{row.datachegada}</td>
                            <td align="center" style={{whiteSpace: 'nowrap', width: '20%'}} className="App__table__cell">{row.di_pro}</td>
                            <td align="center" style={{whiteSpace: 'nowrap',}} className="App__table__cell">{row.dt_reg_di_pro}</td>
                            <td align="center" style={{whiteSpace: 'nowrap',}} className="App__table__cell">{row.canal}</td>
                            <td align="center" style={{whiteSpace: 'nowrap',}} className="App__table__cell">{row.dt_lib_pro}</td>
                            <td align="center" className="App__table__cell">
                                <button  onClick={(event) => handleClickOpenCustosItens(event, row.nro_pro, row.ano_pro)} className="App__table__btn__grey">
                                    <MonetizationOnOutlinedIcon  align="center"/>
                                </button>  
                            </td>
                        </StyledTableRow>
                        );  
                    })}  
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination  
                  rowsPerPageOptions={[5, 10, 15]}  
                  component="div"  
                  count={data.length}  
                  rowsPerPage={rowsPerPage}  
                  page={page} 
                  labelRowsPerPage={"REGISTROS POR PÁGINA"}
                  classes={{
                      root: classes.tablePagination,
                      caption: classes.tablePaginationCaption,
                      selectIcon: classes.tablePaginationSelectIcon,
                      select: classes.tablePaginationSelect,
                      actions: classes.tablePaginationActions,
                    }}
                  onChangePage={handleChangePage}  
                  onChangeRowsPerPage={handleChangeRowsPerPage}  
          />        
          {/* <Modal show={getStateItens} title={getTitle} handleClose={hideModalItens} >
            <CustoItens data={dataCustoItens} />
          </Modal> */}

          <Modal show={getStateItens} title={getTitle} handleClose={hideModalItens} >
            <CustoItens data={dataCustoItens} />
          </Modal>

          <ModalFollow show={getStateFollowUp} title={getTitle} handleClose={hideModalFollowUp}>
            <Follow dataProcesso={dataProcessoFollow} dataTracking={dataTracking} dataFollowUp={dataFollowUp}/>
          </ModalFollow>
    
    </>
    )
  }
  