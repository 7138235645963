import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: '#242628',
    },
  },
}))(TableRow);

const StyledTableRowModal = withStyles(() => ({
  root: {
    border: "none",

    "&:nth-of-type(odd)": {
      backgroundColor: "#8F929B",
      border: "none",
    },
  },
}))(TableRow);

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: "none",
  },
  head: {
    backgroundColor: "#2c2c2c",
    color: "#858585",
    fontSize: 12,
    fontWeight: "bold",
  },
  body: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#8F929B",
  },
}))(TableCell);

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#8F929B",
    color: "#858585",
  },
  tableTr: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    backgroundColor: "#8F929B",
    color: "#858585",
  },
  tableTdL: {
    width: "250px",
    textAlign: "left",
  },
  tableTdR: {
    textAlign: "right",
  },
  tableTdRW: {
    textAlign: "right",
    fontWeight: "bold",
  },
  tablePagination: {},
  tablePaginationCaption: {
    color: "#8F929B",
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
  tablePaginationSelectIcon: {
    color: "#8F929B",
    fontWeight: "bold",
  },
  tablePaginationSelect: {
    color: "#8F929B",
    fontWeight: "bold",
  },
  tablePaginationActions: {
    color: "#8F929B",
    fontWeight: "bold",
  },
  indicator: {
    backgroundColor: "#22b3A7",
    height: "3px",
  },
}));

const StyledTableRowFollow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: '#8F929B',
    },
    body: {},
  },
}))(TableRow);

const StyledTableRowTracking = withStyles(() => ({
  root: {
    backgroundColor: "#8F929B",
    "&:nth-of-type(odd)": {
      backgroundColor: "#8F929B",
      border: "none",
    },
  },
}))(TableRow);

const StyledTableCellTracking = withStyles(() => ({
  root: {
    height: "30px",
    borderBottom: "none",
  },
  head: {
    backgroundColor: "#242628",
    color: "#858585",
    fontSize: 12,
    fontWeight: "bold",
  },
  body: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#8F929B",
  },
}))(TableCell);

const StyledTableCellFollow = withStyles(() => ({
  root: {
    borderBottom: "none",
  },
  head: {
    backgroundColor: "#8F929B",
    color: "#858585",
    fontSize: 10,
    fontWeight: "bold",
  },
  body: {
    fontSize: 12,

    color: "#8F929B",
  },
}))(TableCell);

const FollowTrading = ({ dataProcesso, dataTracking, dataFollowUp }) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          size="small"
        >
          <TableBody>
            {dataProcesso.map((row) => {
              return (
                <div className="App__table__followup">
                  <div className="App__table__followup__div">
                    <div className="App__table__followup__div__left">
                      <span>Importador: </span>
                      {row.IMPORTADOR}
                    </div>
                    <div className="App__table__followup__div__right">
                      <span>Ref. Cliente: </span>
                      {row.REFERENCIA !== "null" ? row.REFERENCIA : ""}
                    </div>
                  </div>

                  <div className="App__table__followup__div">
                    <div className="App__table__followup__div__left">
                      <span>Exportador: </span>
                      {row.EXPORTADOR !== "null" ? row.EXPORTADOR : ""}
                    </div>
                    <div className="App__table__followup__div__right">
                      <span>Processo: </span>
                      {row.PROCESSO}
                    </div>
                  </div>
                  <div className="App__table__followup__div">
                    <div className="App__table__followup__div__left">
                      <span>Modalidade: </span>
                      {row.MODALIDADE !== "null" ? row.MODALIDADE : ""}
                    </div>
                    <div className="App__table__followup__div__midle">
                      <span>Fatura: </span>
                      {row.INV_ESP_NUMERO !== "null" ? row.INV_ESP_NUMERO : ""}
                    </div>
                  </div>
                  <div className="App__table__followup__div">
                    <div className="App__table__followup__div__left">
                      <span>DI: </span>
                      {row.DI !== "null" ? row.DI : ""}
                    </div>
                    <div className="App__table__followup__div__midle">
                      <span>Canal: </span>
                      {row.CANAL !== "null" ? row.CANAL : ""}
                    </div>
                  </div>
                  <div className="App__table__followup__div">
                    <div className="App__table__followup__div__left">
                      <span>Peso Líquido: </span>
                      {row.PESO_LIQUIDO}
                    </div>
                    <div className="App__table__followup__div__midle">
                      <span>Valor FOB: </span>
                      {row.INV_PRE_VLRTOTAL}
                    </div>
                  </div>
                  <div className="App__table__followup__div">
                    <div className="App__table__followup__div__left">
                      <span>Conhecimento: </span>
                      {row.CONHECIMENTO !== "null" ? row.CONHECIMENTO : ""}
                    </div>
                    <div className="App__table__followup__div__midle">
                      <span>Incoterm: </span>
                      {row.INCOTERM !== "null" ? row.INCOTERM : ""}
                    </div>
                  </div>
                </div>
              );
            })}
          </TableBody>
        </Table>
        <br />
        <Table size="small">
          <TableHead>
            <StyledTableRowTracking>
              <StyledTableCellTracking>Origem</StyledTableCellTracking>
              <StyledTableCellTracking>Destino</StyledTableCellTracking>
              <StyledTableCellTracking>Embarque</StyledTableCellTracking>
              <StyledTableCellTracking>Desembarque</StyledTableCellTracking>
              <StyledTableCellTracking>Navio/Vôo</StyledTableCellTracking>
            </StyledTableRowTracking>
          </TableHead>
          <TableBody>
            {dataProcesso.map((row) => {
              return (
                <StyledTableRow>
                  <StyledTableCellFollow>
                    {row.ORIGEM !== "null" ? row.ORIGEM : ""}
                  </StyledTableCellFollow>
                  <StyledTableCellFollow>
                    {row.DESTINO !== "null" ? row.DESTINO : ""}
                  </StyledTableCellFollow>
                  <StyledTableCellFollow>
                    {row.CONF_EMBARQUE !== "00/00/0000"
                      ? "Conf. " + row.CONF_EMBARQUE
                      : row.PREV_EMBARQUE !== "00/00/0000"
                      ? "Prev. " + row.PREV_EMBARQUE
                      : null}
                  </StyledTableCellFollow>
                  <StyledTableCellFollow>
                    {row.DI_DATA_CHEGADA !== "00/00/0000"
                      ? "Conf. " + row.DI_DATA_CHEGADA
                      : row.PREV_CHEGADA !== "00/00/0000"
                      ? "Prev. " + row.PREV_CHEGADA
                      : null}
                  </StyledTableCellFollow>
                  <StyledTableCellFollow>
                    {row.VEI_DES_NOME !== "null" ? row.VEI_DES_NOME : ""}
                  </StyledTableCellFollow>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>

        <Table
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          size="small"
          className="App__table"
        >
          <TableHead>
            <StyledTableRowTracking>
              <StyledTableCellTracking colSpan={2}>
                Observações
              </StyledTableCellTracking>
            </StyledTableRowTracking>
          </TableHead>
          <TableBody>
            {dataFollowUp.map((row) => {
              return (
                <StyledTableRowFollow
                  hover
                  className={classes.row}
                  key={row.procinformeid}
                >
                  <StyledTableCellFollow
                    style={{
                      width: "10%",
                      height: "auto",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {row.PRV_DTA_EVENTO}
                  </StyledTableCellFollow>
                  <StyledTableCellFollow>
                    {row.TEV_ESP_DESCRICAO} {row.PRV_MEM_DESCRICAO}
                  </StyledTableCellFollow>
                </StyledTableRowFollow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FollowTrading;
