import React, { useState, useEffect, useRef } from "react";
import api from '../../services/api';
import { useReactToPrint } from 'react-to-print';
import { setNrProcesso, getNivelUsuario, setAnoPro, getNrProcesso, getAnoPro } from '../../services/auth';
import { ComponentToPrint } from './ComponentToPrint';
import { Input, Button } from './styles'
import { Redirect } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header'
import Container from '../../components/Container'

const Demonstrativo = () => {
    const currentPath = useLocation();
    const [ data, setData ] = useState(false);
    const [ pesquisa, setPesquisa ] = useState(false);
    const [ newPesquisa, setNewPesquisa ] = useState(false);
    const componentRef = useRef();
  
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const showRel = async ()  =>{
      if(getNrProcesso() > 0 && getAnoPro() > 0){
        pesquisa ? setPesquisa(false) : setPesquisa(true);      
        setData(true);      
        setNewPesquisa(true);
        if(newPesquisa){        
          window.location.reload();
          setNewPesquisa(true);
        }
      }
      
    }

    const handleNewSeacrh = () => {
      setNrProcesso('');
      setAnoPro('');
      setData(false); 
      window.location.reload();
    }

    if ((getNivelUsuario() === 0)){
      return <Redirect to='/'  />
    }
    if ((getNivelUsuario() === 3)){
      return <Redirect to='/'  />
    }

  return (
    <>
    <Header currentPath={currentPath.pathname}/> 
    <Container>
      <div>
        <div style={{display: 'flex', width: '100%', padding: '1rem'}}>
        <Input placeholder="Processo" onChange={e => setNrProcesso(e.target.value)} style={{width:'5rem'}}/>
        <Input placeholder="Ano" onChange={e => setAnoPro(e.target.value)} style={{width:'4rem'}}/>
        <Button type="button" onClick={showRel}>
          PESQUISAR
        </Button>
        <Button type="button" onClick={handlePrint}>
        Gerar PDF
        </Button>
        <Button type="button" onClick={handleNewSeacrh}>
        NOVA CONSULTA
        </Button>
        </div>
        {data ? <ComponentToPrint ref={componentRef} /> : ""} 
      </div>
    </Container>
    </>
  );
};

export default Demonstrativo;