import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import api from "../../services/api";
import {
  Download,
  DownloadHS,
  newDowload,
  arrayBufferToBase64,
} from "../../Helpers/format";
import fileDownload from "js-file-download";
import axios from "axios";

var jszip = require("jszip");
var saveAs = require("file-saver");

const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#2c2c2c",
    },
  },
}))(TableRow);

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: "none",
  },
  head: {
    backgroundColor: "#2c2c2c",
    color: "#858585",
    fontSize: 12,
    fontWeight: "bold",
  },
  body: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#8F929B",
  },
}))(TableCell);

function formatSizeUnits(bytes) {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes > 1) {
    bytes = bytes + " bytes";
  } else if (bytes === 1) {
    bytes = bytes + " byte";
  } else {
    bytes = "0 bytes";
  }
  return bytes;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function download(url, filename) {
  var link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
// console.log(result.data.result);
//  DownloadHS(result.data.result, name)
//  fileDownload(result.data.result, name)
//  return blob;
// const url = result.data.result;
// // const url = 'https://headcargo.blob.core.windows.net/leader-log/7a1982f1-38c3-40cf-ba04-7e86fcd35cc4?sv=2021-08-06&se=2023-03-28T15%3A32%3A31Z&sr=b&sp=r&rscd=attachment%3B+filename%3DTeste&sig=%2Bv1B8FQ9jUASWwl%2BDBYdnw8A0PCuuzCp21I%2BO%2BzYxbU%3D';
// const link = document.createElement('a');
// link.href = url;
// link.setAttribute('download',name, // File name with type
//                 );
// document.body.appendChild(link);
// link.click();
// link.parentNode.removeChild(link);

const StyledTableRowModal = withStyles(() => ({
  root: {
    border: "none",
    cursor: "pointer",
    "&:nth-of-type(odd)": {
      backgroundColor: "#242628",
      border: "none",
    },
  },
}))(TableRow);

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#2c2c2c",
    color: "#22b3A7",
  },
  tableTr: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    backgroundColor: "#232323",
    color: "#858585",
  },
  tableTdL: {
    width: "250px",
    textAlign: "left",
  },
  tableTdR: {
    textAlign: "right",
  },
  tableTdRW: {
    textAlign: "right",
    fontWeight: "bold",
  },
  tablePagination: {},
  tablePaginationCaption: {
    color: "#858585",
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
  tablePaginationSelectIcon: {
    color: "#858585",
    fontWeight: "bold",
  },
  tablePaginationSelect: {
    color: "#858585",
    fontWeight: "bold",
  },
  tablePaginationActions: {
    color: "#858585",
    fontWeight: "bold",
  },
  indicator: {
    backgroundColor: "#858585",
    height: "3px",
  },
}));

const FilesHS = ({ dataFile }) => {
  const classes = useStyles();
  const [getNameZip, setNameZip] = React.useState("");
  const [value, setValue] = React.useState(0);
  const [isFinished, setFinished] = React.useState(false);
  const [isLoading, setLoadingFile] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const FileDownloadBlob = async (id) => {
    setLoadingFile(true);
    const result = await api.get(`/api/empresas/arquivoURL/${id}`);
    setLoadingFile(false);
    let a = document.createElement("a");
    a.href = result.data.result.url;
    a.download = result.data.result.filename;
    // setisDownloading(false);
    a.click();
    // download(result.data.result, name)
  };

  const FileDownloadFilesBlob = async (dataFile, qual) => {
    var zip = new jszip();
    dataFile.forEach(async (element) => {
      // console.log(element);
      const blob = getAll(element.IdArquivo);
      var img = zip.folder("processo");
      img.file(element.Nome, blob);
    });
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, getNameZip + ".zip");
    });
  };

  const getAll = async (id) => {
    const result = await api.get("/api/hs/arquivo/" + id);
    // console.log("GetAll " + id);
    var blob = newDowload(
      result.data.result.recordset[0].Dados_Arquivo.data,
      "application/" +
        result.data.result.recordset[0].Nome.substring(
          result.data.result.recordset[0].Nome.indexOf(".") + 1
        ),
      result.data.result.recordset[0].Nome
    );
    return blob;
  };

  return (
    <>
      {/* <button
        onClick={(event) => FileDownloadFilesBlob(dataFile)}
        className="App__table__btn__grey"
        style={{ cursor: "pointer" }}
      >
        <div style={{ height: "20px" }}>
          <span>Baixar Todos</span>&nbsp;
          <CloudDownloadOutlinedIcon
            align="bottom"
            style={{ height: "20px", verticalAlign: "middle" }}
          />
        </div>
      </button> */}

      <TableContainer>
        {/* <button onClick={(event) => FileDownloadFilesBlob(dataFile, "Processo")} className="App__table__btn__grey" style={{height:'25px', position:'relative', cursor: 'pointer'}}>
               
                </button> */}
        <Table
          size="small"
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{ width: "70%", height: "auto" }}>
                NOME&nbsp;
                {isLoading ? (
                  <>
                    <CircularProgress style={{ color: "white" }} size="1rem" />
                    <span style={{ color: "white" }}>Aguarde....</span>
                  </>
                ) : (
                  <></>
                )}
              </StyledTableCell>
              <StyledTableCell style={{ width: "30%" }}>DATA</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {dataFile.map((row, index) => {
              return (
                <StyledTableRowModal
                  hover
                  className={classes.row}
                  key={row.IdArquivo}
                >
                  <StyledTableCell
                    style={{ width: "70%", height: "auto" }}
                    onClick={(event) =>
                      FileDownloadBlob(
                        `${row.DCM_COD}&${row.ADF_COD_SEQ}&${row.FIL_COD}`
                      )
                    }
                  >
                    {row.Descricao}
                  </StyledTableCell>

                  {/* <StyledTableCell style={{ width: '10%' }}>{row.Data_Arquivo}</StyledTableCell> */}
                  <StyledTableCell style={{ width: "10%" }}>
                    {row.DataArquivo}
                  </StyledTableCell>
                </StyledTableRowModal>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FilesHS;
