import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Person from '@material-ui/icons/Person'
import Mail from '@material-ui/icons/Email'
import Telefone from '@material-ui/icons/Phone'
import Language from '@material-ui/icons/Language'
import BaseDialog from './BaseDialog';
import Avatar from '@material-ui/core/Avatar'
import { getNomeAccount, getEmailAccount, getTelefoneAccount, getRamalAccount, getUnidadeAccount, getFotoAccount } from '../../services/auth';

const styles = () => ({
  container: {      
    flexDirection: 'column',
  },
  avatar:{
    margin: '0 auto',
    height: 150,
    width: 150,
  },
});

class InstructionDialog extends Component {
  render() {
    
    const { classes } = this.props;    
    return (
      <BaseDialog {...this.props} >
         <div className="App__modal__table">
          {/* <Typography component={'span'} variant="body2" gutterBottom>*/ }
          <div component={'span'} variant="body2" gutterBottom>
          <Avatar 
              className={classes.avatar} 
           
           src={getFotoAccount()}
              />
          </div>
        </div>
        <div className="App__modal__dados">
          <div className="App__modal__dados__account">
            <Person /><span className="App__modal__dados__info">{getNomeAccount()}</span>
          </div>
          <div className="App__modal__dados__account">
            <Mail /><span className="App__modal__dados__info">{getEmailAccount()}</span>
          </div>
          <div className="App__modal__dados__account">
            <Telefone /><span className="App__modal__dados__info">{getTelefoneAccount()}</span>
          </div>
          <div className="App__modal__dados__account">
            <span className="App__modal__dados__ramal">Ramal: {getRamalAccount()}</span>
          </div>          
          <div className="App__modal__dados__account">
            <Language /><span className="App__modal__dados__info">Unidade: {getUnidadeAccount()}</span>
          </div>
        </div>            
      </BaseDialog>
    );
  }
}

export default withRouter(withStyles(styles)(InstructionDialog));
