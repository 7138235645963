import React, { useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AttachFile from "@material-ui/icons/AttachFile";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../services/api";
import Modal from "../../components/Modal";
import ModalFollow from "../../components/Modal/ModalFollow";
import { Download, newDowload } from "../../Helpers/format";
import CustoItens from "../Modal/CustoItens";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Follow from "../../components/Follow";
import CustosProcesso from "../../components/CustosProcesso";
import {
  Container,
  Row,
  Column,
  BoxStyle,
  Card,
  Title,
  TitleLine,
  Detail,
  Options,
} from "./styles";
import ReorderOutlinedIcon from "@material-ui/icons/ReorderOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import CustosItens from "../../components/CustosItens";
import Files from "../../components/Files";

const Kanban = ({ data }) => {
  const [getStateFollowUp, setStateFollowUp] = React.useState(false);
  const [getStateCustoProcesso, setStateCustoProcesso] = React.useState(false);
  const [loadingFollowUp, setLoadingFollowUp] = useState(false);
  const [loadingCustos, setLoadingCustos] = useState(false);
  const [getStateCustoItens, setStateCustoItens] = React.useState(false);
  const [getStateAnexo, setStateAnexo] = React.useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [totalGeralCustos, setTotalGeralCustos] = React.useState("");
  const [totalGeralRecebido, setTotalGeralRecebido] = React.useState("");
  const [getTitle, setTitle] = React.useState("");
  const [dataProcesso, setDataProcesso] = useState([]);
  const [dataTracking, setDataTracking] = useState([]);
  const [dataFollowUp, setDataFollowUp] = useState([]);
  const [dataFile, setDataFile] = useState([]);
  const [dataCusto, setDataCusto] = useState([]);
  const [dataCustoTotal, setDataCustoTotal] = useState([]);
  const [dataCustoItens, setDataCustoItens] = useState([]);
  const [dataCustoSubItens, setDataCustoSubItens] = useState([]);
  const [existeProcesso, setExisteProcesso] = React.useState(false);
  const [existeFinanceiro, setExisteFinanceiro] = React.useState(false);
  const [getNameZip, setNameZip] = React.useState("");

  const handleClickOpenFollowUp = (event, processo, ano) => {
    const GetData = async () => {
      setLoadingFollowUp(true);
      const result = await api.get("/api/empresas/f/" + processo + "&" + ano);
      setDataProcesso(result.data.cabecalho);
      setDataTracking(result.data.tracking);
      setDataFollowUp(result.data.followup);
      setLoadingFollowUp(false);
    };
    GetData();
    setTitle("FollowUp");
    setStateFollowUp(true);
  };

  const handleClickOpenCustos = (event, idProcesso, processo, ano) => {
    setLoadingCustos(true);
    const GetDataCusto = async () => {
      api
        .get("/api/empresas/custosp/" + processo + "&" + ano)
        .then((response) => {
          setDataCusto(response.data.result);
          setDataCustoTotal(response.data.totais);
          setLoadingCustos(false);
          if (response.data.totais.length > 0) {
            setTotalGeralCustos(response.data.totalCusto[0].total);
            setTotalGeralRecebido(response.data.totalRecebido[0].total);
          }
        });
    };
    GetDataCusto();
    setTitle("Processo: " + processo + "/" + ano);
    setStateCustoProcesso(true);
  };

  const handleClickOpenCustosItens = (event, idProcesso, processo, ano) => {
    const GetDataCustoItens = async () => {
      api
        .get("/api/empresas/custosi/" + processo + "&" + ano)
        .then((response) => {
          setDataCustoItens(response.data.result);
          setDataCustoSubItens(response.data.sub);
        });
    };
    GetDataCustoItens();
    setTitle("Processo: " + processo + "/" + ano);
    setStateCustoItens(true);
  };

  const handleClickOpenFile = (event, idProcesso, processo, ano) => {
    setNameZip("Processo_" + processo + ano);
    setLoadingFile(true);
    const GetDataFiles = async () => {
      const result = await api.get("/api/empresas/arquivos/" + idProcesso);
      setDataFile(result.data.result);
      setLoadingFile(false);

      result.data.result.forEach((element) => {
        if (element["Folder"] !== "Financeiro") {
          setExisteProcesso(true);
        }
        if (element["Folder"] === "Financeiro") {
          setExisteFinanceiro(true);
        }
      });
    };
    GetDataFiles();
    setTitle("Processo: " + processo + "/" + ano);
    setStateAnexo(true);
  };

  const hideModalFollowUp = () => {
    setStateFollowUp(false);
  };

  const hideModalCustoItens = () => {
    setStateCustoItens(false);
  };

  const hideModalCustoProcesso = () => {
    setStateCustoProcesso(false);
  };

  const hideModalAnexo = () => {
    setStateAnexo(false);
  };

  return (
    <>
      <Row>
        <Column mobile="2" tablet="6" desktop="2">
          <BoxStyle>
            <Title>
              Aguardando Embarque -{" "}
              {
                data.filter(
                  (process) =>
                    process.dataembarque2 === "" &&
                    process.dt_enc_pro === "aberto"
                ).length
              }
            </Title>
            <TitleLine />
            {data
              .filter(
                (process) =>
                  process.dataembarque2 === "" &&
                  process.dt_enc_pro === "aberto"
              )
              .map((row, index) => {
                return (
                  <Card>
                    <Detail>
                      {" "}
                      {row.nro_pro}/{row.ano_pro} - {row.via_fretemodo}
                    </Detail>
                    <Detail> {row.ident_cli_pro}</Detail>
                    <Detail> {row.fatura}</Detail>
                    <Options>
                      <ReorderOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFollowUp(
                            event,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AssignmentOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenCustos(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AssignmentOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenCustosItens(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AttachFileOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFile(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                    </Options>
                  </Card>
                );
              })}
          </BoxStyle>
        </Column>
        <Column mobile="2" tablet="6" desktop="2">
          <BoxStyle>
            <Title>
              Em Trânsito -{" "}
              {
                data.filter(
                  (process) =>
                    process.dataembarque2 !== "" &&
                    process.datachegadaok === "" &&
                    process.transito !== "" &&
                    process.dt_enc_pro === "aberto"
                ).length
              }
            </Title>
            <TitleLine />
            {data
              .filter(
                (process) =>
                  process.dataembarque2 !== "" &&
                  process.datachegadaok === "" &&
                  process.transito !== "" &&
                  process.dt_enc_pro === "aberto"
              )
              .map((row, index) => {
                return (
                  <Card>
                    <Detail>
                      {" "}
                      {row.nro_pro}/{row.ano_pro} - {row.via_fretemodo}
                    </Detail>
                    <Detail> {row.ident_cli_pro}</Detail>
                    <Detail> {row.fatura}</Detail>
                    <Options>
                      <ReorderOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFollowUp(
                            event,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AssignmentOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenCustos(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AssignmentOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenCustosItens(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AttachFileOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFile(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                    </Options>
                  </Card>
                );
              })}
          </BoxStyle>
        </Column>
        <Column mobile="2" tablet="6" desktop="2">
          <BoxStyle>
            <Title>
              Desembaraço -{" "}
              {
                data.filter(
                  (process) =>
                    process.datachegadaok !== "" &&
                    process.dt_enc_pro === "aberto"
                ).length
              }
            </Title>
            <TitleLine />
            {data
              .filter(
                (process) =>
                  process.datachegadaok !== "" &&
                  process.dt_enc_pro === "aberto"
              )
              .map((row, index) => {
                return (
                  <Card>
                    <Detail>
                      {" "}
                      {row.nro_pro}/{row.ano_pro} - {row.via_fretemodo}
                    </Detail>
                    <Detail> {row.ident_cli_pro}</Detail>
                    <Detail> {row.fatura}</Detail>
                    <Options>
                      <ReorderOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFollowUp(
                            event,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AssignmentOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenCustos(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AssignmentOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenCustosItens(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AttachFileOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFile(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                    </Options>
                  </Card>
                );
              })}
          </BoxStyle>
        </Column>
        <Column mobile="2" tablet="6" desktop="2">
          <BoxStyle>
            <Title>
              Faturamento -{" "}
              {
                data.filter(
                  (process) =>
                    process.FaturamentoLiberacaoData !== "" &&
                    process.dt_fat === "" &&
                    process.dt_enc_pro === "aberto"
                ).length
              }
            </Title>
            <TitleLine />
            {data
              .filter(
                (process) =>
                  process.FaturamentoLiberacaoData !== "" &&
                  process.dt_fat === "" &&
                  process.dt_enc_pro === "aberto"
              )
              .map((row, index) => {
                return (
                  <Card>
                    <Detail>
                      {" "}
                      {row.nro_pro}/{row.ano_pro} - {row.via_fretemodo}
                    </Detail>
                    <Detail> {row.ident_cli_pro}</Detail>
                    <Detail> {row.fatura}</Detail>
                    <Options>
                      <ReorderOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFollowUp(
                            event,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AssignmentOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenCustos(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AssignmentOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenCustosItens(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AttachFileOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFile(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                    </Options>
                  </Card>
                );
              })}
          </BoxStyle>
        </Column>
        <Column mobile="2" tablet="6" desktop="2">
          <BoxStyle>
            <Title>
              Entrega -{" "}
              {
                data.filter(
                  (process) =>
                    process.FaturamentoLiberacaoData !== "" &&
                    process.dt_fat !== "" &&
                    process.dt_enc_pro === "aberto"
                ).length
              }
            </Title>
            <TitleLine />
            {data
              .filter(
                (process) =>
                  process.FaturamentoLiberacaoData !== "" &&
                  process.dt_fat !== "" &&
                  process.dt_enc_pro === "aberto"
              )
              .map((row, index) => {
                return (
                  <Card>
                    <Detail>
                      {" "}
                      {row.nro_pro}/{row.ano_pro} - {row.via_fretemodo}
                    </Detail>
                    <Detail> {row.ident_cli_pro}</Detail>
                    <Detail> {row.fatura}</Detail>
                    <Options>
                      <ReorderOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFollowUp(
                            event,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AssignmentOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenCustos(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AssignmentOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenCustosItens(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AttachFileOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFile(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                    </Options>
                  </Card>
                );
              })}
          </BoxStyle>
        </Column>
        <Column mobile="2" tablet="6" desktop="2">
          <BoxStyle>
            <Title>
              Concluído -{" "}
              {data.filter((process) => process.dt_enc_pro !== "aberto").length}
            </Title>
            <TitleLine />
            {data
              .filter((process) => process.dt_enc_pro !== "aberto")
              .map((row, index) => {
                return (
                  <Card>
                    <Detail>
                      {" "}
                      {row.nro_pro}/{row.ano_pro} - {row.via_fretemodo}
                    </Detail>
                    <Detail> {row.ident_cli_pro}</Detail>
                    <Detail> {row.fatura}</Detail>
                    <Options>
                      <ReorderOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFollowUp(
                            event,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AssignmentOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenCustos(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AssignmentOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenCustosItens(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                      <AttachFileOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFile(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                    </Options>
                  </Card>
                );
              })}
          </BoxStyle>
        </Column>
      </Row>
      {/*FollowUp*/}
      <ModalFollow
        show={getStateFollowUp}
        title={getTitle}
        handleClose={hideModalFollowUp}
      >
        <div style={{ margin: "0 auto" }}>
          {loadingFollowUp ? (
            <CircularProgress style={{ color: "white" }} />
          ) : (
            ""
          )}
        </div>
        <Follow
          dataProcesso={dataProcesso}
          dataTracking={dataTracking}
          dataFollowUp={dataFollowUp}
        />
      </ModalFollow>
      {/*Custo Processo*/}
      <Modal
        show={getStateCustoProcesso}
        title={getTitle}
        handleClose={hideModalCustoProcesso}
      >
        <div style={{ margin: "0 auto" }}>
          {loadingCustos ? <CircularProgress style={{ color: "white" }} /> : ""}
        </div>
        <CustosProcesso
          dataCustoTotal={dataCustoTotal}
          dataCusto={dataCusto}
          totalGeralCustos={totalGeralCustos}
          totalGeralRecebido={totalGeralRecebido}
        />
      </Modal>
      {/*Custo Itens*/}
      <Modal
        show={getStateCustoItens}
        title={getTitle}
        handleClose={hideModalCustoItens}
      >
        <CustosItens
          dataCustoItens={dataCustoItens}
          dataCustoSubItens={dataCustoSubItens}
        />
      </Modal>
      {/*Arquivos*/}
      <Modal show={getStateAnexo} title={getTitle} handleClose={hideModalAnexo}>
        <div style={{ margin: "0 auto" }}>
          {loadingFile ? <CircularProgress style={{ color: "white" }} /> : ""}
        </div>
        <Files
          dataFile={dataFile}
          existeProcesso={existeProcesso}
          existeFinanceiro={existeFinanceiro}
        />
      </Modal>
    </>
  );
};

export default Kanban;
