import styled from 'styled-components';

export const Container = styled.div`
    /* max-width: 1360px; */
    padding-right: 15px;
    padding-left:15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    &:before,
    &:after{
        content: " "            ;
        display: table;
    }
    &:after{
        clear: both;
    }
`;

export const Row = styled.div`
    width:100%;
    height:auto;
    float:left;
    /* background-color: #3D3F44; */
    box-sizing: border-box;
    &:before,
    &:after{
        content: " " ;
        display: table;
    }
    &:after{
        clear: both;
    }
`;

export const BoxStyle = styled.div`
    height: auto;
    min-height: 20rem;
    max-height: 45rem;
    overflow: auto;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    /* display: flex; */
    margin-bottom: 1rem;
    padding: .375rem .45rem;
    font-size: 1rem;      
    line-height: 1.5;
    color: #858585;
    /* background-color: #3D3F44; */
    background-clip: padding-box;
    border-left: 1px solid #858585;
    /* border-radius: .25rem; */
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;   

`;

export const Card = styled.div`
    height: auto;
    box-shadow: 0 1px 5px 0 rgb(48 54 77 / 40%);  
    /* border: 1px solid; */
    width: 100%;
    overflow: auto;
    font-family: 'Poppins', sans-serif;
    /* display: flex; */
    margin-bottom: 1rem;
    padding: .375rem .25rem;
    font-size: .5rem;      
    line-height: 1.5;
    color: #858585;
    background-color: #2c2c2c;
    background-clip: padding-box;
    /* border: 1px solid #ced4da; */
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    
`;

export const Title = styled.span`
    font-family: 'Poppins', sans-serif;
    width:100%;
    /* margin-left: .5rem; */
    font-size: 0.75rem;
    font-weight: bold;    
`;

export const ListResume = styled.div`
    font-family: 'Poppins', sans-serif;
    display: flex;
    height: 3.5rem;
    align-items: center;         
`;

export const TitleLine = styled.span`
    &::after{
        white-space: pre;        
    }
    margin-bottom: .5rem;
    display: block;
    border-bottom: .02rem solid #8F929B;
    /* opacity: .8; */
`;

export const Detail = styled.span`
    font-size: .8rem;
    margin-bottom: .5rem;
    display: block;
    overflow: hidden;
    white-space: nowrap;
`;

export const Options = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Button = styled.button`
    font-family: 'Poppins', sans-serif;
    margin-right: .5rem;
    font-weight: 500;
    font-size: .8rem;
    background: #2c2c2c;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    width: 6rem;
    border-radius: 5px;
    height: 2.2rem;
    border: none;
    outline: none;
    transition: 0.15s;
    text-align: center;
    
    &:hover {
      opacity: .7;
    }
  `



function getWidthGrid(value){
    if (!value) return;

    let width = value / 12 * 100;
    return `width ${width}%;`;
}

export const Column = styled.div`
    float: left;
    padding: .15rem;
    min-height: 1px;
    box-sizing: border-box;

    @media only screen and (max-width: 768px) {
        ${({mobile}) => mobile && getWidthGrid(mobile)}
    }

    @media only screen and (min-width: 768px) {
        ${({tablet}) => tablet && getWidthGrid(tablet)}
    }

    @media only screen and (min-width: 1000px) {
        ${({desktop}) => desktop && getWidthGrid(desktop)}
    }
`;
