import React, { Component } from 'react';
import Select from 'react-select'
import api from '../../services/api';
import { getIdUsuario, setIdEmpresa, setNomeEmpresa, getNomeEmpresa, 
        setAccount, setNomeAccount, setEmailAccount, setTelefoneAccount, setRamalAccount, setUnidadeAccount, setFotoAccount,
        setComercial, setNomeComercial, setEmailComercial, setTelefoneComercial, setRamalComercial, setUnidadeComercial, setFotoComercial, setCNPJ} from '../../services/auth';

export default class Empresa extends Component{
    componentDidMount(){
      this.getOptions()
    }
    constructor(props){
      super(props)
      this.state = {
        selectOptions: [],
        id: "",
        name: "",
        account: "",
        comercial: "",
        cnpj: "",
      }
    }
    
    async getOptions(){
      
      const res = await api.get('/api/empresas/'+getIdUsuario())
      const data = res.data.result          
      const options = data.map(d => ({
        "value" : d.cod_emp,
        "label" : d.fantasia_emp + '(' + d.cgc_emp + ')',
        "account": d.AccountId,
        "comercial": d.PromotorID,
        "cnpj": d.cgc_emp
      }))
      this.setState({selectOptions: options})
    }
    
    async getAccount(codAccount){     
      setAccount("");
      setNomeAccount("");
      setEmailAccount("");
      setTelefoneAccount("");
      setRamalAccount("");
      setUnidadeAccount("");
      setFotoAccount("");
      const res = await api.get('/api/empresas/account/'+codAccount);
      const data = res.data.result;
      if (data.length > 0){
        setAccount(data[0].acc_codExt);
        setNomeAccount(data[0].acc_nome);
        setEmailAccount(data[0].acc_email);
        setTelefoneAccount(data[0].acc_telefone);
        setRamalAccount(data[0].acc_ramal);
        setUnidadeAccount(data[0].acc_unidade);
        setFotoAccount(data[0].acc_url_foto);        
      }else{
        setAccount("");
        setNomeAccount("");
        setEmailAccount("");
        setTelefoneAccount("");
        setRamalAccount("");
        setUnidadeAccount("");
        setFotoAccount("");
      }
      // 
    }

    async getComercial(codComercial){
      const res = await api.get('/api/empresas/comercial/'+codComercial);
      const data = res.data.result;      
      if (data.length > 0){
        setComercial(data[0].cmo_codExt);
        setNomeComercial(data[0].cmo_nome);
        setEmailComercial(data[0].cmo_email);
        setTelefoneComercial(data[0].cmo_telefone);
        setRamalComercial(data[0].cmo_ramal);
        setUnidadeComercial(data[0].cmo_unidade);
        setFotoComercial(data[0].cmo_url_foto);        
      }else{
        setComercial("");
        setNomeComercial("");
        setEmailComercial("");
        setTelefoneComercial("");
        setRamalComercial("");
        setUnidadeComercial("");
        setFotoComercial("");
      }
      window.location.reload();    
    }

    handleSelectCity = (option)=> {
      this.setState({id:option.value, name:option.label, account:option.AccountId});
      setIdEmpresa(option.value);
      setNomeEmpresa(option.label); 
      setCNPJ(option.cnpj)
      this.getAccount(option.account);
      this.getComercial(option.PromotorID);
      
   }

    render(){
      const {selectOptions} = this.state;      
      return (
        
        <Select className="App__select"  autoFocus={true} options={selectOptions} placeholder={getNomeEmpresa() === "" ? "Selecione a Empresa" : getNomeEmpresa()} onChange={this.handleSelectCity} /> 
    );
  }
}  

