import React, { useState } from "react";
import logo from "../../assets/imgs/logo.png";
import logo2 from "../../assets/imgs/3SCORP_Logo2 branco.png";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Menu from "../Menu";
import Empresa from "../../components/Select/Empresa";
import {
  getFotoAccount,
  getIdEmpresa,
  getEmailAccount,
  getNivelUsuario,
  getIdUsuario,
} from "../../services/auth";
import logoBI from "../../assets/imgs/logobi.png";
import InstructionDialog from "../../components/Modal/InstructionDialog";

export default function Header() {
  const [getStartedDialog, setStatedDialog] = useState(false);
  const openGetStartedDialog = () => {
    setStatedDialog(true);
  };

  const closeGetStartedDialog = () => {
    setStatedDialog(false);
  };

  const handleClickBI = () => {
    console.log(`IDempresa -> ${getIdEmpresa()} Usuario -> ${getIdUsuario()}`);
    if (
      getIdEmpresa() === "563" ||
      getIdEmpresa() === "3208" ||
      getIdEmpresa() === "1813" ||
      (getIdEmpresa() === "5146" && getIdUsuario() === "679")
    ) {
      window.open("https://app.powerbi.com/", "_blank");
    } else {
      alert("Serviço não foi contratado, fale com seu comercial");
      window.location.href = `mailto:${getEmailAccount()}`;
    }
  };

  return (
    <header className="App__header">
      {getNivelUsuario() === "0" ? (
        <>
          <Link to="/home">
            <img className="App__header__logo" width={150} src={logo2} alt="" />
          </Link>
          <Empresa />
          <Menu />
          <a href="#" onClick={handleClickBI} rel="noreferrer">
            <img
              width="40"
              src={logoBI}
              alt="Power BI"
              style={{ color: "#fff" }}
            />
          </a>
          {/* <button style={{backgroundColor: '#2c2c2c'}} onClick={openGetStartedDialog} className="App__header__account">
                <span className="App__header__account__span">ACCOUNT</span>
                <Avatar src={getFotoAccount()}/>
            </button>
            <InstructionDialog open={getStartedDialog} onClose={closeGetStartedDialog}/> */}
        </>
      ) : (
        <>
          <Link to="/seguro">
            <img className="App__header__logo" width={150} src={logo2} alt="" />
          </Link>
          <Menu />
        </>
      )}
    </header>
  );
}
