import React, { useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AttachFile from "@material-ui/icons/AttachFile";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../services/api";
import Modal from "../Modal";
import ModalFollow from "../Modal/ModalFollow";
import { Download, newDowload } from "../../Helpers/format";
import CustoItens from "../Modal/CustoItens";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Follow from "../FollowTrading";
import CustosProcesso from "../CustosProcesso";
import {
  Container,
  Row,
  Column,
  BoxStyle,
  Card,
  Title,
  TitleLine,
  Detail,
  Options,
} from "./styles";
import ReorderOutlinedIcon from "@material-ui/icons/ReorderOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import CustosItens from "../CustosItens";
import Files from "../FilesHS";

const KanbanTrad = ({ data }) => {
  const [getStateFollowUp, setStateFollowUp] = React.useState(false);
  const [getStateCustoProcesso, setStateCustoProcesso] = React.useState(false);
  const [loadingFollowUp, setLoadingFollowUp] = useState(false);
  const [loadingCustos, setLoadingCustos] = useState(false);
  const [getStateCustoItens, setStateCustoItens] = React.useState(false);
  const [getStateAnexo, setStateAnexo] = React.useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [totalGeralCustos, setTotalGeralCustos] = React.useState("");
  const [totalGeralRecebido, setTotalGeralRecebido] = React.useState("");
  const [getTitle, setTitle] = React.useState("");
  const [dataProcesso, setDataProcesso] = useState([]);
  const [dataTracking, setDataTracking] = useState([]);
  const [dataFollowUp, setDataFollowUp] = useState([]);
  const [dataFile, setDataFile] = useState([]);
  const [dataCusto, setDataCusto] = useState([]);
  const [dataCustoTotal, setDataCustoTotal] = useState([]);
  const [dataCustoItens, setDataCustoItens] = useState([]);
  const [dataCustoSubItens, setDataCustoSubItens] = useState([]);
  const [existeProcesso, setExisteProcesso] = React.useState(false);
  const [existeFinanceiro, setExisteFinanceiro] = React.useState(false);
  const [getNameZip, setNameZip] = React.useState("");

  const handleClickOpenFollowUp = (event, processo, filial) => {
    const GetData = async () => {
      setLoadingFollowUp(true);
      const result = await api.get(
        "/api/cnx/processo/d/" + processo + "&" + filial
      );
      setDataProcesso(result.data.cabecalho);
      setDataFollowUp(result.data.followup);
      // setDataTracking(result.data.tracking.recordset);
      setLoadingFollowUp(false);
    };
    GetData();
    setTitle("FollowUp");
    setStateFollowUp(true);
  };

  const handleClickOpenFile = (event, processo, filial, referencia) => {
    const GetDataFiles = async () => {
      // console.log(`GetDataFiles -> ${processo}_${filial}`);
      const result = await api.get(
        `/api/empresas/arquivoscnx/T&${processo}_${filial}`
      );
      setDataFile(result.data.result);
      // console.log(`Dados -> ${result.data.result}`);
      setLoadingFile(false);
      // setExisteProcesso(true);
    };
    GetDataFiles();
    setTitle("Processo: " + referencia);
    setStateAnexo(true);
  };

  const hideModalFollowUp = () => {
    setStateFollowUp(false);
  };
  const hideModalAnexo = () => {
    setStateAnexo(false);
  };

  return (
    <>
      <Row>
        <Column mobile="2" tablet="6" desktop="2">
          <BoxStyle>
            <Title>
              Aguardando Embarque -{" "}
              {
                data.filter(
                  (process) =>
                    process.Status === "Ag. Embarque" &&
                    process.PRI_VLD_STATUS_DESC === "ABERTO"
                ).length
              }
            </Title>
            <TitleLine />
            {data
              .filter(
                (process) =>
                  process.Status === "Ag. Embarque" &&
                  process.PRI_VLD_STATUS_DESC === "ABERTO"
              )
              .map((row, index) => {
                return (
                  <Card>
                    <Detail>
                      {" "}
                      {row.PROCESSO} - {row.MODALIDADE}
                    </Detail>
                    <Detail>{row.SUAREFERENCIA}</Detail>
                    <Detail>
                      {" "}
                      {row.EXPORTADOR !== "null" ? row.EXPORTADOR : ""}
                    </Detail>
                    <Options>
                      <ReorderOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFollowUp(
                            event,
                            row.PRI_COD,
                            row.FIL_COD
                          )
                        }
                      />
                      <AttachFileOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFile(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                    </Options>
                  </Card>
                );
              })}
          </BoxStyle>
        </Column>
        <Column mobile="2" tablet="6" desktop="2">
          <BoxStyle>
            <Title>
              Em Trânsito -{" "}
              {
                data.filter(
                  (process) =>
                    process.Status === "Em Transito" &&
                    process.PRI_VLD_STATUS_DESC === "ABERTO"
                ).length
              }
            </Title>
            <TitleLine />
            {data
              .filter(
                (process) =>
                  process.Status === "Em Transito" &&
                  process.PRI_VLD_STATUS_DESC === "ABERTO"
              )
              .map((row, index) => {
                return (
                  <Card>
                    <Detail>
                      {" "}
                      {row.PROCESSO} - {row.MODALIDADE}
                    </Detail>
                    <Detail>{row.SUAREFERENCIA}</Detail>
                    <Detail>
                      {row.EXPORTADOR !== "null" ? row.EXPORTADOR : ""}
                    </Detail>
                    <Options>
                      <ReorderOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFollowUp(
                            event,
                            row.PRI_COD,
                            row.FIL_COD
                          )
                        }
                      />
                      <AttachFileOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFile(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                    </Options>
                  </Card>
                );
              })}
          </BoxStyle>
        </Column>
        <Column mobile="2" tablet="6" desktop="2">
          <BoxStyle>
            <Title>
              Aguardando Desembaraço -{" "}
              {
                data.filter(
                  (process) =>
                    process.Status === "Em Ag. Desembaraço" &&
                    process.PRI_VLD_STATUS_DESC === "ABERTO"
                ).length
              }
            </Title>
            <TitleLine />
            {data
              .filter(
                (process) =>
                  process.Status === "Em Ag. Desembaraço" &&
                  process.PRI_VLD_STATUS_DESC === "ABERTO"
              )
              .map((row, index) => {
                return (
                  <Card>
                    <Detail>
                      {" "}
                      {row.PROCESSO} - {row.MODALIDADE}
                    </Detail>
                    <Detail>{row.SUAREFERENCIA}</Detail>
                    <Detail>
                      {" "}
                      {row.EXPORTADOR !== "null" ? row.EXPORTADOR : ""}
                    </Detail>
                    <Options>
                      <ReorderOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFollowUp(
                            event,
                            row.PRI_COD,
                            row.FIL_COD
                          )
                        }
                      />
                      <AttachFileOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFile(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                    </Options>
                  </Card>
                );
              })}
          </BoxStyle>
        </Column>
        <Column mobile="2" tablet="6" desktop="2">
          <BoxStyle>
            <Title>
              Desembaraçado -{" "}
              {
                data.filter(
                  (process) =>
                    process.Status === "Desembaraçada" &&
                    process.PRI_VLD_STATUS_DESC === "ABERTO"
                ).length
              }
            </Title>
            <TitleLine />
            {data
              .filter(
                (process) =>
                  process.Status === "Desembaraçada" &&
                  process.PRI_VLD_STATUS_DESC === "ABERTO"
              )
              .map((row, index) => {
                return (
                  <Card>
                    <Detail>
                      {" "}
                      {row.PROCESSO} - {row.MODALIDADE}
                    </Detail>
                    <Detail>{row.SUAREFERENCIA}</Detail>
                    <Detail>
                      {" "}
                      {row.EXPORTADOR !== "null" ? row.EXPORTADOR : ""}
                    </Detail>
                    <Options>
                      <ReorderOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFollowUp(
                            event,
                            row.PRI_COD,
                            row.FIL_COD
                          )
                        }
                      />
                      <AttachFileOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFile(
                            event,
                            row.processoid,
                            row.nro_pro,
                            row.ano_pro
                          )
                        }
                      />
                    </Options>
                  </Card>
                );
              })}
          </BoxStyle>
        </Column>
        <Column mobile="2" tablet="6" desktop="2">
          <BoxStyle>
            <Title>
              Concluído -{" "}
              {
                data.filter(
                  (process) => process.PRI_VLD_STATUS_DESC !== "ABERTO"
                ).length
              }
            </Title>
            <TitleLine />
            {data
              .filter((process) => process.PRI_VLD_STATUS_DESC !== "ABERTO")
              .map((row, index) => {
                return (
                  <Card>
                    <Detail>
                      {" "}
                      {row.PROCESSO} - {row.MODALIDADE}
                    </Detail>
                    <Detail> {row.SUAREFERENCIA}</Detail>
                    <Detail>
                      {row.EXPORTADOR !== "null" ? row.EXPORTADOR : ""}
                    </Detail>
                    <Options>
                      <ReorderOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFollowUp(
                            event,
                            row.PRI_COD,
                            row.FIL_COD
                          )
                        }
                      />
                      <AttachFileOutlinedIcon
                        style={{ cursor: "pointer" }}
                        onClick={(event) =>
                          handleClickOpenFile(
                            event,
                            row.PRI_COD,
                            row.FIL_COD,
                            row.PROCESSO
                          )
                        }
                      />
                    </Options>
                  </Card>
                );
              })}
          </BoxStyle>
        </Column>
      </Row>
      {/*FollowUp*/}
      <ModalFollow
        show={getStateFollowUp}
        title={getTitle}
        handleClose={hideModalFollowUp}
      >
        <div style={{ margin: "0 auto" }}>
          {loadingFollowUp ? (
            <CircularProgress style={{ color: "white" }} />
          ) : (
            ""
          )}
        </div>
        <Follow
          dataProcesso={dataProcesso}
          dataTracking={dataTracking}
          dataFollowUp={dataFollowUp}
        />
      </ModalFollow>
      {/*Arquivos*/}
      <Modal show={getStateAnexo} title={getTitle} handleClose={hideModalAnexo}>
        <div style={{ margin: "0 auto" }}>
          {loadingFile ? <CircularProgress style={{ color: "white" }} /> : ""}
        </div>
        <Files
          dataFile={dataFile}
          // existeProcesso={existeProcesso}
          // existeFinanceiro={existeFinanceiro}
        />
      </Modal>
    </>
  );
};

export default KanbanTrad;
