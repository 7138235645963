import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";

import api from "../../services/api";
import { Download, newDowload } from "../../Helpers/format";

var jszip = require("jszip");
var saveAs = require("file-saver");

const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#2c2c2c",
    },
  },
}))(TableRow);

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: "none",
  },
  head: {
    backgroundColor: "#2c2c2c",
    color: "#858585",
    fontSize: 12,
    fontWeight: "bold",
  },
  body: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#8F929B",
  },
}))(TableCell);

function formatSizeUnits(bytes) {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes > 1) {
    bytes = bytes + " bytes";
  } else if (bytes === 1) {
    bytes = bytes + " byte";
  } else {
    bytes = "0 bytes";
  }
  return bytes;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const FileDownloadBlob = async (processo) => {
  const result = await api.get("/api/empresas/file/" + processo);
  Download(
    result.data.result[0].File.data,
    "application/" +
      result.data.result[0].FileName.substring(
        result.data.result[0].FileName.indexOf(".") + 1
      ),
    result.data.result[0].FileName
  );
};

const StyledTableRowModal = withStyles(() => ({
  root: {
    border: "none",
    cursor: "pointer",
    "&:nth-of-type(odd)": {
      backgroundColor: "#242628",
      border: "none",
    },
  },
}))(TableRow);

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#2c2c2c",
    color: "#22b3A7",
  },
  tableTr: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    backgroundColor: "#232323",
    color: "#858585",
  },
  tableTdL: {
    width: "250px",
    textAlign: "left",
  },
  tableTdR: {
    textAlign: "right",
  },
  tableTdRW: {
    textAlign: "right",
    fontWeight: "bold",
  },
  tablePagination: {},
  tablePaginationCaption: {
    color: "#858585",
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
  tablePaginationSelectIcon: {
    color: "#858585",
    fontWeight: "bold",
  },
  tablePaginationSelect: {
    color: "#858585",
    fontWeight: "bold",
  },
  tablePaginationActions: {
    color: "#858585",
    fontWeight: "bold",
  },
  indicator: {
    backgroundColor: "#858585",
    height: "3px",
  },
}));

const Files = ({ dataFile, existeProcesso, existeFinanceiro }) => {
  const classes = useStyles();
  const [getNameZip, setNameZip] = React.useState("");
  const [value, setValue] = React.useState(0);
  const [isFinished, setFinished] = React.useState(false);
  const [isLoading, setLoadingFile] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const FileDownloadFilesBlob = async (dataFile, qual) => {
    setLoadingFile(true);
    var zip = new jszip();
    dataFile.forEach(async (element) => {
      if (qual === "Todos") {
        if (element["Folder"] === "Processo" || element["Folder"] === null) {
          const blob = getAll(element["iFileSystemID"]);
          var img = zip.folder("processo");
          img.file(element["FileName"], blob);
        }
        if (element["Folder"] === "Financeiro") {
          const blob = getAll(element["iFileSystemID"]);
          var img = zip.folder("financeiro");
          img.file(element["FileName"], blob);
        }
      } else if (qual === "Processo") {
        if (element["Folder"] === "Processo" || element["Folder"] === null) {
          const blob = getAll(element["iFileSystemID"]);
          var img = zip.folder("processo");
          img.file(element["FileName"], blob);
        }
      } else if (qual === "Financeiro") {
        if (element["Folder"] === "Financeiro") {
          const blob = getAll(element["iFileSystemID"]);
          var img = zip.folder("financeiro");
          img.file(element["FileName"], blob);
        }
      }
    });
    setLoadingFile(false);
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, getNameZip + ".zip");
    });
  };

  const getAll = async (id) => {
    const result = await api.get("/api/empresas/file/" + id);
    var blob = newDowload(
      result.data.result[0].File.data,
      "application/" +
        result.data.result[0].FileName.substring(
          result.data.result[0].FileName.indexOf(".") + 1
        ),
      result.data.result[0].FileName
    );
    return blob;
  };

  return (
    <>
      <button
        onClick={(event) => FileDownloadFilesBlob(dataFile, "Todos")}
        className="App__table__btn__grey"
        style={{ cursor: "pointer" }}
      >
        <div style={{ height: "20px" }}>
          <span>Baixar Todos(Processo / Financeiro)</span>&nbsp;
          <CloudDownloadOutlinedIcon
            align="bottom"
            style={{ height: "20px", verticalAlign: "middle" }}
          />
        </div>
      </button>
      <Tabs
        value={value}
        onChange={handleChange}
        classes={{ indicator: classes.indicator }}
        centered
        align="center"
      >
        {existeProcesso ? (
          <Tab
            label="PROCESSO"
            {...a11yProps(1)}
            style={{ color: "#8F929B" }}
          />
        ) : (
          ""
        )}
        {existeFinanceiro ? (
          <Tab
            label="FINANCEIRO"
            {...a11yProps(1)}
            style={{ color: "#8F929B" }}
          />
        ) : (
          ""
        )}
      </Tabs>
      <TabPanel value={value} index={0}>
        <TableContainer>
          <button
            onClick={(event) => FileDownloadFilesBlob(dataFile, "Processo")}
            className="App__table__btn__grey"
            style={{ height: "25px", position: "relative", cursor: "pointer" }}
          >
            <div style={{ height: "20px" }}>
              <span style={{ height: "40px" }}>Baixar Todos(Processo)</span>
              &nbsp;
              <CloudDownloadOutlinedIcon
                align="bottom"
                style={{ height: "20px", verticalAlign: "middle" }}
              />
            </div>
          </button>
          <Table
            size="small"
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell style={{ width: "70%", height: "auto" }}>
                  NOME&nbsp;
                  {isLoading ? (
                    <>
                      <CircularProgress
                        style={{ color: "white" }}
                        size="1rem"
                      />
                      <span style={{ color: "white" }}>Aguarde....</span>
                    </>
                  ) : (
                    <></>
                  )}
                </StyledTableCell>
                <StyledTableCell style={{ width: "20%" }}>
                  TAMANHO
                </StyledTableCell>
                <StyledTableCell style={{ width: "10%" }}>DATA</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {dataFile
                .filter((arqs) => arqs.Folder !== "Financeiro")
                .map((row, index) => {
                  return (
                    <StyledTableRowModal
                      hover
                      className={classes.row}
                      key={row.iFileSystemID}
                    >
                      <StyledTableCell
                        style={{ width: "70%", height: "auto" }}
                        onClick={(event) => FileDownloadBlob(row.iFileSystemID)}
                      >
                        {row.FileName}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "20%" }}>
                        {formatSizeUnits(row.FileSize)}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "10%" }}>
                        {row.data}
                      </StyledTableCell>
                    </StyledTableRowModal>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableContainer>
          <button
            visible="false"
            onClick={(event) => FileDownloadFilesBlob(dataFile, "Financeiro")}
            className="App__table__btn__grey"
            style={{ height: "25px", position: "relative", cursor: "pointer" }}
          >
            <div style={{ height: "20px" }}>
              <span style={{ height: "40px" }}>Baixar Todos(Financeiro)</span>
              &nbsp;
              <CloudDownloadOutlinedIcon
                align="bottom"
                style={{ height: "20px", verticalAlign: "middle" }}
              />
            </div>
          </button>
          <Table
            size="small"
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell style={{ width: "70%", height: "auto" }}>
                  NOME
                </StyledTableCell>
                <StyledTableCell style={{ width: "20%" }}>
                  TAMANHO
                </StyledTableCell>
                <StyledTableCell style={{ width: "10%" }}>DATA</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {dataFile
                .filter((arqs) => arqs.Folder === "Financeiro")
                .map((row, index) => {
                  return (
                    <StyledTableRowModal
                      hover
                      className={classes.row}
                      key={row.iFileSystemID}
                    >
                      <StyledTableCell
                        style={{ width: "70%", height: "auto" }}
                        onClick={(event) => FileDownloadBlob(row.iFileSystemID)}
                      >
                        {row.FileName}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "20%" }}>
                        {formatSizeUnits(row.FileSize)}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "10%" }}>
                        {row.data}
                      </StyledTableCell>
                    </StyledTableRowModal>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </>
  );
};

export default Files;
