import React from "react";
import Container from '../../components/Container'

export default function Privacidade(){
    return (
        <Container>            
          <div style={{width: '50%', margin: '0 auto'}} >
            <div style={{textAlign: 'justify'}}>
                <h3>Política de Privacidade - Aplicativo 3SCorp</h3>
                <br />
                <h4>Prezado Cliente,</h4>
                <br />
                <p>A 3S CORP PARTICIPAÇÕES LTDA. (doravante “3S CORP”), pessoa jurídica de direito privado, inscrita no CNPJ sob nº 19.903.931/0001-28, com sede na Rua Nicolau Becker, nº 762, sala 11, bairro Centro, na cidade de Novo Hamburgo (RS), CEP 93.510-060, preza pela proteção e inviolabilidade de seus dados pessoais, assumindo o compromisso de respeitar sua privacidade, intimidade, honra e imagem, garantindo-lhe, ainda, informações adequadas sobre o tratamento de dados pessoais que realiza, conforme previsto na Lei Geral de Proteção de Dados (Lei nº 13.709/2018).</p>
                <br />
                <p>Por isso, destacamos que as informações coletadas pela 3S CORP têm como principal finalidade possibilitar ao Usuário a análise, controle e acompanhamento de operações de importação e exportação efetuadas pelo próprio Usuário.</p>
                <br />
                <p>Embora o tratamento de dados realizado por meio do aplicativo se valha de informações de caráter público e/ou condizentes com pessoas jurídicas, é de fundamental importância que você leia o presente Termo de Privacidade (doravante ‘Termo’), pois, neste, prestaremos todas as informações necessárias acerca do tratamento de dados pessoais eventualmente tratados pela 3S CORP.</p>
                <br />
                <p>Caso você fique com alguma dúvida ou não concorde com este Termo, pedimos que envie uma mensagem eletrônica ao endereço privacidade@3scorporate.com, de modo que possamos compreender seus motivos, esclarecer o que se fizer necessário e manter constantes melhorias em nossos serviços</p>
                <br />
                <p>Por outro lado, caso você prossiga usufruindo de nossos serviços, entenderemos que está de acordo com este Termo, inclusive em relação às condições de tratamento aqui estabelecidas, servindo o ato, enquanto não houver manifestação em sentido contrário, como concordância com o tratamento de dados pessoais realizado pela 3S CORP.</p>
                <br />
                <p>Por fim, informamos que a 3S CORP poderá rever e atualizar o presente Termo, sempre que tal medida se mostrar necessária. Portanto, recomendamos a leitura periódica dessas condições, observando-se a última data de atualização, que vai expressa no fim deste documento.</p>
                <br />
                <p>Confira abaixo todas as condições deste Termo de Privacidade:</p>
                <br />
                <p>1. QUAIS DADOS COLETAMOS:</p>                
                <p>Ao acessar e utilizar nosso aplicativo disponibilizamos a você a possibilidade de receber diversas informações relacionadas a operações de importação e exportação realizadas através da 3S CORP.</p>
                <br />
                <p>As principais informações coletadas e tratadas no aplicativo dizem respeito à RAZÃO SOCIAL, ORIGEM DA CARGA, DESTINO DA CARGA, DATA DE EMBARQUE, DATA DE CHEGADA, HISTÓRICO DO DESEMBARAÇO, VALORES, MODALIDADE DE TRANSPORTE, TRANSPORTADORA, PESO DA MERCADORIA, dentre outras informações específicas e inerentes a tais operações.</p>
                <br />
                <p>Por se tratar de uma opção oferecida ao usuário, consideramos que a utilização do aplicativo equivale à manifestação de consentimento com as finalidades de tratamento antes referidas.</p>
                <br />
                <p>Para preservar e proteger os dados, a 3S CORP utiliza complexos sistemas de proteção, como antivírus e firewall.</p>
                <br />
                <p>1.1. TRATAMENTO DE DADOS PESSOAIS DE CRIANÇAS E ADOLESCENTES</p>                
                <p>A 3S CORP não realiza qualquer espécie de tratamento de dados pessoais de crianças e adolescentes.</p>
                <br />
                <p>1.2. COOKIES, PIXEL TAGS e BEACONS</p>
                <p>É possível que façamos a utilização de mecanismos e recursos tecnológicos, como “Cookies”, “Pixel Tags” e “Beacons”. Esses recursos são utilizados com o propósito de melhorar sua experiência de navegação, captando informações de modo automático, por meio de identificadores que, ao acessar nosso aplicativo, podem ser transferidos para o seu navegador ou dispositivo. Esses identificadores servem para monitorar atividades, de modo que nos permita verificar como e quando as funcionalidades e recursos de nosso aplicativo são visitadas, quantos acessos foram realizados, além de captar informações sobre o seu dispositivo, como o endereço de IP, o browser e o seu ISP.</p>
                <br/>
                <p>Em alguns casos, é possível desabilitar a utilização de alguns desses recursos, por meio das configurações de seu próprio aparelho ou por meio da instalação de plug-in que realize essa funcionalidade.</p>
                <br />
                <p>No entanto, precisamos alertar que, ao desabilitar algum desses recursos, especialmente “Cookies”, é possível que algumas áreas de nosso aplicativo não funcionem corretamente.</p>
                <br />
                <p>Também alertamos que este Termo não cobre o uso de “Cookies” ou quaisquer outros recursos tecnológicos que não sejam de utilização exclusiva da 3S CORP, sendo que, em nenhuma hipótese, a 3S CORP será responsável pela política e práticas de privacidade adotadas por terceiros.</p>
                <br />
                <p>2. COMO UTILIZAMOS OS DADOS PESSOAIS</p>
                <p>O aplicativo não tem a finalidade de tratar dados pessoais de seus usuários. No entanto, quando a 3S CORP atua na qualidade de agente de tratamento de dados pessoais, sua posição é preponderantemente na condição de controladora dos dados coletados.</p>
                <br />
                <p>Os dados pessoais eventualmente coletadas pela 3S CORP recebem tratamento de acordo com os subitens a seguir.</p>
                <br />
                <p>2.1. DURAÇÃO DO TRATAMENTO</p>
                <p>A duração do tratamento de dados pessoais ocorrerá por tempo indeterminado, garantindo-se o término do tratamento nas seguintes hipóteses:</p>
                <p>
                <ul style={{listStyle:'lower-alpha'}}>
                    <li>Verificação de que a finalidade foi alcançada ou de que os dados pessoais deixaram de ser necessários ou pertinentes ao alcance da finalidade específica almejada;</li>
                    <li>Comunicação do titular, inclusive no exercício de seu direito de revogação do consentimento, resguardado o interesse público; ou</li>
                    <li>Determinação da autoridade nacional, quando houver violação a disposições legais.</li>
                </ul>
                </p>
                <br />
                <p>2.2. ARMAZENAMENTO</p>
                <p>Os dados pessoais de usuários são armazenados em nuvem (Google Drive) e em sistemas destinados ao envio de e-mail marketing (RD Station), podendo, ainda, serem armazenados em servidores locais ou servidor mantido junto à AMAZON.</p>
                <br />
                <p>Os dados pessoais serão eliminados após o término de seu tratamento, no âmbito e nos limites técnicos das atividades. Porém, serão conservados aqueles dados pessoais que se destinem ao cumprimento de obrigação legal ou regulatória por parte da 3S CORP, ou que sejam destinados ao uso exclusivo da 3S CORP, assegurada a anonimização dentro dos limites técnicos possíveis.</p>
                <br />
                <p>2.3. COMPARTILHAMENTO DOS DADOS PESSOAIS</p>
                <p>O compartilhamento de dados pessoais poderá ocorrer (i) para a proteção dos interesses da 3S CORP em qualquer tipo de conflito; (ii) quando exigido por decisão judicial ou requisição de autoridade competente; ou (iii) nos limites das finalidades assumidas neste Termo.</p>
                <br />
                <p>Para alcançar as finalidades assumidas neste Termo, é possível que a 3S CORP se utilize de recursos e infraestrutura tecnológica controladas por terceiros, tal como (i) estrutura de hospedagem do website, (ii) softwares para envio de e-mail marketing, (iii) Google (Google AdSense), (iv) iData, (v) Conexos e (iv) Facebook (Facebook Ads, Instagram, WhatsApp e WhatsApp Business).</p>
                <br />
                <p>A 3S CORP não realiza o compartilhamento de dados pessoais além das hipóteses e finalidades previstas neste Termo.</p>
                <br />
                <p>3. TRANSFERÊNCIA INTERNACIONAL DE DADOS</p>
                <p>A 3S CORP poderá, eventualmente, realizar a transferência internacional de dados pessoais, mas sempre respeitando as regras estabelecidas pela Lei Geral de Proteção de Dados para tal espécie de tratamento.</p>
                <p>Sendo assim, a transferência internacional de dados pessoais poderá ocorrer nos seguintes casos:</p>
                <p>
                  <ul style={{listStyle:'lower-alpha'}}>
                    <li>Para países ou organismos internacionais que proporcionem grau de proteção de dados pessoais adequado ao previsto na LGPD;</li>
                    <li>Quando a 3S CORP puder oferecer e comprovar garantias de cumprimento dos princípios, dos direitos do titular e do regime de proteção de dados previstos na LGPD, na forma de cláusulas contratuais específicas para determinada transferência, cláusulas-padrão contratuais, normas corporativas globais, selos, certificados e códigos de conduta regularmente emitidos;</li>
                    <li>Quando a transferência for necessária para a proteção da vida ou da incolumidade física do titular ou de terceiro;</li>
                    <li>quando a autoridade nacional autorizar a transferência;</li>
                    <li>quando o titular tiver fornecido o seu consentimento específico e em destaque para a transferência, com informação prévia sobre o caráter internacional da operação, distinguindo claramente esta de outras finalidades; ou</li>
                    <li>quando necessário para o cumprimento de obrigação legal ou regulatória, ou para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o Titular, a pedido deste, ou para o exercício regular de direitos em processo judicial, administrativo ou arbitral, nos termos da Lei 9.307/96.</li>
                  </ul>
                </p>
                <br />
                <p>4. SEGURANÇA DOS DADOS</p>
                <p>Os dados pessoais coletados no aplicativo são armazenados em sistema integrado, utilizando-se, também, de medidas técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.</p>
                <br/>
                <p>Dentre outras medidas, cita-se que a 3S CORP faz a adoção de barreiras tecnológicas (criptografias, firewall, antivírus, entre outros) e analógicas (limitação de acesso a usuários, compromisso de confidencialidade e sigilo, e termos de responsabilidade), tudo com o objetivo de manter seguro os dados pessoais obtidos.</p>
                <br/>
                <p>Dessa forma, a 3S CORP adota os melhores esforços para preservar a privacidade dos dados pessoais de usuários, de acordo com a tecnologia disponível atualmente. Entretanto, em razão da técnica, não se pode assegurar que os recursos empregados são totalmente seguros, especialmente aqueles por meio dos quais é possível a violação e acessos não autorizados, realizados através de métodos e artifícios desenvolvidos para obter informações de forma indevida.</p>
                <br />
                <p>Por isso, a 3S CORP orienta que seus usuários também adotem as medidas apropriadas e que lhe são cabíveis para se proteger de violações indevidas e não autorizadas, como a instalação de softwares de segurança, preservação de dados de acesso e senhas.</p>
                <br />
                <p>A 3S CORP comunicará à autoridade nacional e ao titular sobre a ocorrência de incidentes de segurança que possam acarretar risco ou dano relevante aos titulares, de acordo com os prazos e regulamentos legais.</p>
                <br />
                <p>5. DIREITOS DO USUÁRIO</p>
                <p>Nos termos da Lei nº 13.709/2018 (Lei Geral de Proteção de Dados), o usuário tem o direito de obter, a qualquer tempo e mediante requisição expressa:</p>
                <p>
                  <ul style={{listStyle:'lower-alpha'}}>
                    <li>Confirmação da existência de tratamento;</li>
                    <li>Acesso aos dados;</li>
                    <li>Correção de dados incompletos, inexatos ou desatualizados;</li>
                    <li>Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a Lei;</li>
                    <li>Portabilidade dos dados a outro fornecedor de serviço ou produto, quando cabível;</li>
                    <li>Eliminação dos dados pessoais tratados com o consentimento, exceto se destinados à conservação, nos termos do art. 16, da Lei 13.709/2018;</li>
                    <li>Informação das entidades públicas e privadas com as quais a 3S CORP realizou uso compartilhado de dados;</li>
                    <li>Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa; e</li>
                    <li>Revogação do consentimento, nos termos do art. 8º, § 5º, da Lei 13.709/2018.</li>
                    <br />
                    <p>O exercício dos direitos descritos acima ou o envio de reclamações ou outras comunicações deverá ser realizado mediante requerimento expresso do titular ou de representante legalmente constituído, que deverá ser enviado à 3S CORP através dos canais de contato informados neste Termo.</p>
                    <br />
                    <p><strong>6. RESPONSABILIDADE</strong></p>
                    <p>A 3S CORP não será responsabilizada por danos que decorram do tratamento inadequado de dados tornados públicos pelo próprio titular.</p>
                    <p>A 3S CORP também não será responsabilizada, dentre outras hipóteses, <strong>(i)</strong> se não houver realizado o tratamento de dados que lhe é atribuído, (ii) se, embora houver realizado o tratamento de dados pessoais que lhe é atribuído, não violar a legislação de proteção de dados, (iii) se o dano for decorrente de culpa exclusiva do titular ou de terceiros, ou (iv) se o dano for proveniente de casos fortuitos ou de força maior.</p>
                    <br />
                    <p><strong>7. LEGISLAÇÃO APLICÁVEL</strong></p>
                    <p>Esta Política será regida, interpretada e executada de acordo com as Leis vigentes na República Federativa do Brasil, em especial, as disposições da Lei nº 13.709/2018 (LGPD).</p>
                    <br />
                    <p><strong>8. DADOS PARA CONTATO</strong></p>
                    <p><strong>E-MAIL: </strong> privacidade@3scorporate.com</p>
                    <p><strong>E-FONE: </strong> (51) 3581.4761</p>
                    <p><strong>ENDEREÇO: </strong> Av. Nicolau Becker nº 762 – Edifício La Bradbury, Salas 11, 12, 21 e 22, bairro Centro, Novo Hamburgo, CEP 93.520-575</p>
                  </ul>
                </p>
            </div>
          </div>
        </Container>
    );
}

