import styled from "styled-components";

export const Input = styled.input`
    font-family: 'Poppins', sans-serif;
    box-shadow: 0 1px 5px 0 rgb(48 54 77 / 40%);  
    border: 1px solid;
    
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    display: flex;
    margin-bottom: .5rem;
    margin-right: .5rem;
    padding: .375rem .75rem;
    font-size: .7rem;      
    line-height: 1.5;
    height: 2.4rem;
    width: 12rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    ::placeholder {
        color: #495057;
    }
`;

export const Input3 = styled.input`
    font-family: 'Poppins', sans-serif;
    box-shadow: 0 1px 5px 0 rgb(48 54 77 / 40%);  
    border: 1px solid;
    
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    display: flex;
    margin-bottom: .5rem;
    margin-right: .5rem;
    padding: .375rem .75rem;
    font-size: .7rem;      
    line-height: 1.5;
    height: 2.4rem;
    width: 10rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    ::placeholder {
        color: #495057;
    }
`;

export const Input2 = styled.input`
    font-family: 'Poppins', sans-serif;
    box-shadow: 0 1px 5px 0 rgb(48 54 77 / 40%);  
    border: 1px solid;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    display: flex;
    margin-bottom: .5rem;
    margin-right: .5rem;
    padding: .375rem .75rem;
    font-size: .7rem;      
    line-height: 1.5;
    height: 2.4rem;
    width: 8rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    ::placeholder {
        color: #495057;
    }
`;

