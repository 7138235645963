export const TOKEN_KEY = '&app-token';
export const ID_USUARIO = '&id-usuario';
export const NIVEL_USUARIO = '&nivel-usuario';
export const NOME_USUARIO = '&nome-usuario';
export const EMAIL_USUARIO = '&email-usuario';
export const USER_TYPE = '&user-type';
export const ID_EMPRESA = '&id-empresa';
export const CNPJ = '&cnpj';
export const NOME_EMPRESA = '&nome-empresa';
export const ID_ACCOUNT = '&id-account';
export const ID_MODALIDADE = '&id-modalidade';
export const NOME_MODALIDADE = '&nome-modalidade';
export const ID_CONDPAGAMENTO = '&id-condpagamento';
export const NOME_COND_PAGAMENTO = '&nome-condpagamento';
export const ACCOUNT = '&account';
export const NOME_ACCOUNT = '&nome-account';
export const EMAIL_ACCOUNT = '&email-account';
export const TELEFONE_ACCOUNT = '&telefone-account';
export const RAMAL_ACCOUNT = '&ramal-account';
export const UNIDADE_ACCOUNT = '&unidade-account';
export const FOTO_ACCOUNT = '&foto-account';
export const COMERCIAL = '&comercial';
export const NOME_COMERCIAL = '&nome-comercial';
export const EMAIL_COMERCIAL = '&email-comercial';
export const TELEFONE_COMERCIAL = '&telefone-comercial';
export const RAMAL_COMERCIAL = '&ramal-comercial';
export const UNIDADE_COMERCIAL = '&unidade-comercial';
export const FOTO_COMERCIAL = '&foto-comercial';
export const ID_STATUS_PROCESSO = '&id-status-processo';
export const STATUS_PROCESSO = '&status-processo';
export const ID_TIPO_PROCESSO = '&id-tipo-processo';
export const TIPO_PROCESSO = '&tipo-processo';
export const NR_PROCESSO = '&nrpro';
export const ANO_PRO = '&anopro';
export const FILTER = '&filter';


export const login = token => { localStorage.setItem(TOKEN_KEY,token); }
export const logout = () => { localStorage.clear() };

export const setIdUsuario = id => localStorage.setItem(ID_USUARIO,id);
export const getIdUsuario = () => localStorage.getItem(ID_USUARIO);

export const setNomeUsuario = nome => localStorage.setItem(NOME_USUARIO,nome);
export const getNomeUsuario = () => localStorage.getItem(NOME_USUARIO);

export const setNivelUsuario = nivel => localStorage.setItem(NIVEL_USUARIO, nivel);
export const getNivelUsuario = () => localStorage.getItem(NIVEL_USUARIO);

export const setEmailUsuario = email => localStorage.setItem(EMAIL_USUARIO, email);
export const getEmailUsuario = () => localStorage.getItem(EMAIL_USUARIO);

export const setTipoUsuario = tipo => localStorage.setItem(USER_TYPE,tipo);
export const getTipoUsuario = () => localStorage.getItem(USER_TYPE);

export const setIdEmpresa = id => localStorage.setItem(ID_EMPRESA,id);
export const getIdEmpresa = () => localStorage.getItem(ID_EMPRESA);

export const setCNPJ = cnpj => localStorage.setItem(CNPJ, cnpj);
export const getCNPJ = () => localStorage.getItem(CNPJ);

export const setIdAccount = id => localStorage.setItem(ID_ACCOUNT,id);
export const getIdAccount = () => localStorage.getItem(ID_ACCOUNT);

export const setNomeEmpresa = nome => localStorage.setItem(NOME_EMPRESA,nome);
export const getNomeEmpresa = () => localStorage.getItem(NOME_EMPRESA);

export const setAccount = Array => localStorage.setItem(ACCOUNT, Array);
export const getAccount = () => localStorage.getItem(ACCOUNT); 

export const setNomeAccount = nome => localStorage.setItem(NOME_ACCOUNT, nome);
export const getNomeAccount = () => localStorage.getItem(NOME_ACCOUNT); 

export const setEmailAccount = email => localStorage.setItem(EMAIL_ACCOUNT, email);
export const getEmailAccount = () => localStorage.getItem(EMAIL_ACCOUNT); 

export const setTelefoneAccount = telefone => localStorage.setItem(TELEFONE_ACCOUNT, telefone);
export const getTelefoneAccount = () => localStorage.getItem(TELEFONE_ACCOUNT); 

export const setRamalAccount = ramal => localStorage.setItem(RAMAL_ACCOUNT, ramal);
export const getRamalAccount = () => localStorage.getItem(RAMAL_ACCOUNT); 

export const setUnidadeAccount = unidade => localStorage.setItem(UNIDADE_ACCOUNT, unidade);
export const getUnidadeAccount = () => localStorage.getItem(UNIDADE_ACCOUNT); 

export const setFotoAccount = foto => localStorage.setItem(FOTO_ACCOUNT, foto);
export const getFotoAccount = () => localStorage.getItem(FOTO_ACCOUNT); 


export const setComercial = Array => localStorage.setItem(COMERCIAL, Array);
export const getComercial = () => localStorage.getItem(COMERCIAL); 

export const setNomeComercial = nome => localStorage.setItem(NOME_COMERCIAL, nome);
export const getNomeComercial = () => localStorage.getItem(NOME_COMERCIAL); 

export const setEmailComercial = email => localStorage.setItem(EMAIL_COMERCIAL, email);
export const getEmailComercial = () => localStorage.getItem(EMAIL_COMERCIAL); 

export const setTelefoneComercial = telefone => localStorage.setItem(TELEFONE_COMERCIAL, telefone);
export const getTelefoneComercial = () => localStorage.getItem(TELEFONE_COMERCIAL); 

export const setRamalComercial = ramal => localStorage.setItem(RAMAL_COMERCIAL, ramal);
export const getRamalComercial = () => localStorage.getItem(RAMAL_COMERCIAL); 

export const setUnidadeComercial = unidade => localStorage.setItem(UNIDADE_COMERCIAL, unidade);
export const getUnidadeComercial = () => localStorage.getItem(UNIDADE_COMERCIAL); 

export const setFotoComercial = foto => localStorage.setItem(FOTO_COMERCIAL, foto);
export const getFotoComercial = () => localStorage.getItem(FOTO_COMERCIAL); 


export const setIdModalidade = id => localStorage.setItem(ID_MODALIDADE,id);
export const getIdModalidade = () => localStorage.getItem(ID_MODALIDADE);

export const setNomeModalidade = nome => localStorage.setItem(NOME_MODALIDADE,nome);
export const getNomeModalidade = () => localStorage.getItem(NOME_MODALIDADE);

export const setIdCondPagamento = id => localStorage.setItem(ID_CONDPAGAMENTO,id);
export const getIdCondPagamento = () => localStorage.getItem(ID_CONDPAGAMENTO);

export const setNomeCondPagamento = nome => localStorage.setItem(NOME_COND_PAGAMENTO,nome);
export const getNomeCondPagamento = () => localStorage.getItem(NOME_COND_PAGAMENTO);

export const setIdStatusProcesso = id => localStorage.setItem(ID_STATUS_PROCESSO, id);
export const getIdStatusProcesso = () => localStorage.getItem(ID_STATUS_PROCESSO);

export const setStatusProcesso = status => localStorage.setItem(STATUS_PROCESSO, status);
export const getStatusProcesso = () => localStorage.getItem(STATUS_PROCESSO);

export const setIdTipoProcesso = id => localStorage.setItem(ID_TIPO_PROCESSO, id);
export const getIdTipoProcesso = () => localStorage.getItem(ID_TIPO_PROCESSO);

export const setTipoProcesso = status => localStorage.setItem(TIPO_PROCESSO, status);
export const getTipoProcesso = () => localStorage.getItem(TIPO_PROCESSO);

export const setNrProcesso = nrprocesso => localStorage.setItem(NR_PROCESSO, nrprocesso);
export const getNrProcesso = () => localStorage.getItem(NR_PROCESSO);

export const setAnoPro = anopro => localStorage.setItem(ANO_PRO, anopro);
export const getAnoPro = () => localStorage.getItem(ANO_PRO);

export const setFilter = filter => localStorage.setItem(FILTER, filter);
export const getFilter = () => localStorage.getItem(FILTER);

export const getToken = () => localStorage.getItem(TOKEN_KEY)