import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import OutSourcing from "../pages/Outsourcing";
import Agenciamento from "../pages/Agenciamento";
import Importacao from "../pages/Importacao2";
import Exportacao from "../pages/Exportacao2";
// import Financeiro from '../pages/Financeiro';
import Login from "../pages/Login";
import Processos from "../pages/Processos";
import PrivateRoute from "../services/wAuth";
import Relatorios from "../pages/Relatorios";
import Cadastros from "../pages/Cadastros";
import Seguro from "../pages/Seguro";
import Privacidade from "../pages/Privacidade";
import Demonstrativo from "../pages/Demonstrativo";
// import Demonstrativo from '../pages/DemonstrativoFin';
import Demonstrativow from "../pages/DemonstrativoWinning";
import Demonstrativoimp from "../pages/DemonstrativoImp";
import Notas from "../pages/Notas";
import FinPagar from "../pages/FinApagar";
import Embarque from "../pages/Embarque";
import Pedidos from "../pages/Pedidos";
import Trading from "../pages/Trading";
import Tracking from "../pages/Tracking";
import Usuarios from "../pages/Usuarios";
import Etiquetas from "../pages/Etiquetas";
// import Working from '../pages/Working';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/etiquetas" component={Etiquetas} />
      <Route exact path="/tracking/:id" component={Tracking} />
      <PrivateRoute path="/home" exact component={Home} />
      <PrivateRoute path="/privacidade" exact component={Privacidade} />
      <PrivateRoute path="/outsourcing" exact component={OutSourcing} />
      <PrivateRoute path="/embarque" exact component={Agenciamento} />
      <PrivateRoute path="/importacao" exact component={Importacao} />
      <PrivateRoute path="/exportacao" exact component={Exportacao} />
      <PrivateRoute path="/processos" exact component={Trading} />
      <PrivateRoute path="/trading" exact component={Trading} />
      <PrivateRoute path="/financeiro" exact component={Home} />
      <PrivateRoute path="/relatorios" exact component={Relatorios} />
      <PrivateRoute path="/cadastros" exact component={Cadastros} />
      <PrivateRoute path="/seguro" exact component={Seguro} />
      <PrivateRoute path="/demonstrativo" exact component={Demonstrativo} />
      <PrivateRoute
        path="/demonstrativowinning"
        exact
        component={Demonstrativow}
      />
      <PrivateRoute
        path="/demonstrativoimp"
        exact
        component={Demonstrativoimp}
      />
      <PrivateRoute path="/Notas" exact component={Notas} />
      <PrivateRoute path="/CPagar" exact component={FinPagar} />
      <PrivateRoute path="/Embarque" exact component={Embarque} />
      <PrivateRoute path="/pedidos" exact component={Pedidos} />
      <PrivateRoute path="/usuarios" exact component={Usuarios} />
      {/* <PrivateRoute path='/working' exact component={ Working} /> */}
    </Switch>
  </BrowserRouter>
);

export default Routes;
