import React, {useState} from 'react'

const ModalFollow = ({ handleClose, width, show, title, children }) => {
  const showHideClassName = show ? "App__modal display-block" : "App__modal display-none";
  return (
    <div className={showHideClassName} >
      <section className="App__modal__follow" width={width}>
        <button type="button" className="App__modal__btn" onClick={handleClose}>
          X
        </button>

        {children}
        
      </section>
    </div>
  );
};

export default ModalFollow;