import React from 'react'

export default function Vencer({data}){
    return(
        <>
            <table  className="App__ptax__box__table"> 
                    <thead>
                        <tr className="App__ptax__box__table__tr">
                            <td className="App__ptax__box__table__cell">MOEDA</td>  
                            <td className="App__ptax__box__table__cell">DATA</td>
                            <td className="App__ptax__box__table__cell">COMPRA<span>(R$)</span></td>  
                            <td className="App__ptax__box__table__cell">VENDA<span>(R$)</span></td>  
                        </tr>
                    </thead>
                    <tbody>
                    {data.map((row) => {  
                        return (  
                        <tr key={row.nro_lanc_cor} className="App__ptax__box__table__tr">
                            <td className="App__ptax__box__table__celldados">{row.MoedaId}</td> 
                            <td className="App__ptax__box__table__celldados">{row.DataTaxa}</td>  
                            <td className="App__ptax__box__table__celldados">{row.PtaxCompraValor}</td>
                            <td className="App__ptax__box__table__celldados">{row.PtaxVendaValor}</td>
                        </tr>
                            );  
                        })}      
                    </tbody>                  
                </table>
                 
        </>
    );
}