import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import Leaderlog from '../../assets/imgs/leaderlog.png';
import logo3s from '../../assets/imgs/3S.PNG';
import { getNrProcesso, getAnoPro, getFilter } from '../../services/auth';

const Dados = () => {
    const [ data, setData ] = useState([]);
    const [ despesas, setDespesas ] = useState([]);
    const [ nfs, setNfs ] = useState([]);
    const [ getNrNf, setNrNf ] = useState('');
    const [ getTotalGeral, setTotalGeral ] = useState('');
    const [ existe, setExiste ] = useState(0);

    useEffect(() => {
        const getDemonstrativo = async () => {
            const result = await api.post('/api/empresas/demonstrativow/',{"processo":getNrProcesso(),"ano":getAnoPro()});
                setExiste(result.data.cliente.length);
                setData(result.data.cliente[0]);                
                setDespesas(result.data.despesas.filter(despesas => despesas.cod_him != "SPP" && despesas.cod_him != "NF"));
                if(result.data.despesas.length > 0){
                  setTotalGeral(result.data.despesas[0]['TOTGERAL']);
                  setNrNf(result.data.nfs[0]['NFSeNumero']);
                }
                setNfs(result.data.nfs);
        }
      //   const getFicha = async () => {
      //     const result = await api.post('/api/empresas/fichafinw/',{"processo":getNrProcesso(),"ano":getAnoPro()});
      //         setExiste(result.data.demonstrativo.length);
      //         setData(result.data.demonstrativo);
      //         console.log("RE - " + result.data.demonstrativo);
      // }
       
      if ((getFilter() === '1')){        
        getDemonstrativo();
      }else if((getFilter() === '2')){        
        // getFicha();
      }
       
    }, [])
    
    
    return(
        <>
        
        {/* {existe.length > 0 ?  */}
        <div style={{width: '209.55mm',
        height: '200mm', backgroundColor: '#fff'}}>
        {/* // height: '298.45mm', backgroundColor: '#fff'}}> */}
        
          <div style={{marginLeft: '2rem',}}>
            <div style={{width: '100%'}}>
              <div style={{float: 'left', marginLeft: '10px'}}>
              <img className="App__header__logo" width={200} src={Leaderlog} alt="" />
              </div>
              <div style={{marginTop: '1rem', float: 'right', display: 'flex'}}>
              <span style={{fontWeight: 'bold', marginTop: '2.0rem'}}>A member of&nbsp;&nbsp;&nbsp;</span><img className="App__header__logo" width={40} height={50} src={logo3s} alt="" />
              </div>
              
            </div>
            <div style={{display: 'flex', width: '100%'}}>
              <hr style={{display: 'flex', width: '100%', height: '3px', backgroundColor: '#000'}}/>
            </div>
            <div style={{display: 'flex', width: '100%'}}>
              <span style={{fontSize: '1.2rem', color: '#000', fontWeight: 'bold', margin: '0 auto', padding: '.2rem'}}>Demonstrativo Financeiro - {getNrNf}</span>
            </div>
            <div style={{display: 'flex', width: '100%'}}>
              <hr style={{display: 'flex', width: '100%', height: '3px', backgroundColor: '#000'}}/>
            </div>
            
            <table style={{ width: '100%', fontSize: '.8rem', color: '#000',}} cellspacing="0">
              <thed>
                <tr >
                  <td style={{fontWeight: 'bold', padding: '.2rem'}}>Cliente:</td>
                  <td align="left">{data['CLIENTE']}</td>
                  <td>&nbsp;&nbsp;</td>
                  <td>&nbsp;&nbsp;</td>
                  <td>&nbsp;&nbsp;</td>
                  <td style={{fontWeight: 'bold', padding: '.2rem'}}>Ref. do Cliente:</td>
                  <td align="left">{data['ident_cli_pro']}</td>
                </tr>
                <tr>
                  <td style={{fontWeight: 'bold', padding: '.2rem'}}>Exportador:</td>
                  <td align="left">{data['EXPORTADOR']}</td>
                  <td>&nbsp;&nbsp;</td>
                  <td>&nbsp;&nbsp;</td>
                  <td>&nbsp;&nbsp;</td>
                  <td style={{fontWeight: 'bold', padding: '.2rem'}}>Processo:</td>
                  <td align="left">{getNrProcesso()}/{getAnoPro()}</td>
                </tr>
                <tr>
                  <td style={{fontWeight: 'bold', padding: '.2rem'}}>DI:</td>
                  <td align="left">{data['di_pro']}</td>
                  <td>&nbsp;&nbsp;</td>
                  <td>&nbsp;&nbsp;</td>
                  <td>&nbsp;&nbsp;</td>
                  <td style={{fontWeight: 'bold', padding: '.2rem'}}>Conhecimento:</td>
                  <td align="left">{data['CONHECIMENTO']}</td>
                </tr>
              </thed>
            </table>
            
            
            <div style={{width: '100%', marginTop: '.2rem'}}>
            <table style={{ width: '100%'}} cellspacing="0">
              <thead>
                  <tr style={{fontSize: '.8rem', color: '#000', fontWeight: 'bold',  backgroundColor: '#DCDCDC'}} height={22}>
                      <td>Despesas do Processo</td>
                      <td align="left">Benificiário</td>
                      <td align="right">Valor</td>
                  </tr>                
              </thead>
             <tbody>                
              {despesas.map((row, index) => {                  
                  return (
                      <>
                        <tr style={{fontSize: '.8rem', color: '#000'}} height={22}>
                            <td align="left">{row.Descricao === '' ? "ADIANTAMENTO" : row.Descricao}</td>
                            <td align="left" style={{fontSize: '.6rem'}}>{row.razaosocial_emp}</td>
                            <td align="right"> {row.vlr_cr_fif}</td>
                        </tr>        
                      </>
                  );
                })}
                <tr  style={{fontSize: '.8rem', color: '#000', fontWeight: '600',  backgroundColor: '#DCDCDC'}} height={22}>
                    <td colSpan="2">Total</td>
                    <td align="right">-{getTotalGeral}</td>
                </tr>
                </tbody>
              </table>
            </div>

            </div>
            
        </div>
        {/* :
        ""} */}
        </>  
        
    );
}

export default Dados;