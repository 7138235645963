import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select'
import { setIdCondPagamento, setNomeCondPagamento } from '../../services/auth';

    const options = [
      {
        "value":1,
        "label": "À VISTA"
        },
        {
        "value":2,
        "label": "BOLETO - 7"
        },
        {
        "value":3,
        "label": "BOLETO - 7/14/21"
        },
        {
        "value":4,
        "label": "BOLETO - 10/17/24"
        },
        {
        "value":5,
        "label": "BOLETO - 14"
        },
        {
        "value":6,
        "label": "BOLETO - 14/21/28"
        },
        {
        "value":7,
        "label": "BOLETO - 15"
        },
        {
        "value":8,
        "label": "BOLETO - 21"
        },
        {
        "value":9,
        "label": "BOLETO - 21/28/35"
        },
        {
        "value":10,
        "label": "BOLETO - 21/28/35/42"
        },
        {
        "value":11,
        "label": "BOLETO - 21/35"
        },
        {
        "value":12,
        "label": "BOLETO - 28"
        },
        {
        "value":13,
        "label": "BOLETO - 28/35/42"
        },
        {
        "value":14,
        "label": "BOLETO - 28/56"
        },
        {
        "value":15,
        "label": "BOLETO - 29/36/43"
        },
        {
        "value":16,
        "label": "BOLETO - 30/37/44"
        },
        {
        "value":17,
        "label": "BOLETO - 30/45/60"
        },
        {
        "value":18,
        "label": "BOLETO - 35"
        },
        {
        "value":19,
        "label": "BOLETO - 35/42/49"
        },
        {
        "value":20,
        "label": "BOLETO - 35/42/49/56/63"
        },
        {
        "value":21,
        "label": "BOLETO - 56"
        }]

  export default class SimpleSelect extends Component{
    
    constructor(props){
      super(props)
      this.state = {
        selectOptions: [],
        id: "0",
        name: "Cond. Pagamento"
      }
      
      this.setState({selectOptions: options})
    }

    handleSelectStatus = (option)=> {
      this.setState({id:option.value, name:option.label});
      setIdCondPagamento(option.value);
      setNomeCondPagamento(option.label);      
   } 

    useStyles = makeStyles((theme) => ({
      select: {
        
        fontSize: '0.65em',
      }
      
    }));

    render(){      
      return (
        
        <Select options={options} className={"App__pedido__form__select__product"} onChange={this.handleSelectStatus} /> 
    );
  }
}  

