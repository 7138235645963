import React from "react";
import { Link } from "react-router-dom";
import AssignmentSharpIcon from "@material-ui/icons/AssignmentOutlined";

export default function Previsoes({ dataPrevisao, rows }) {
  return (
    <>
      <div className="App__previsao__box__previsao">
        <div>
          <span className="App__previsao__box__span">
            PREVISÕES DE CHEGADA / SAÍDA
          </span>
        </div>
        <div>
          <Link to="/relatorios">
            <AssignmentSharpIcon
              style={{ color: "#858585", marginLeft: "5px" }}
            />
          </Link>
        </div>
      </div>
      <table cellSpacing="0" className="App__previsao__box__table">
        <thead>
          <tr className="App__previsao__box__l__previsao__table__tr">
            <td className="App__previsao__box__table__cell">PROCESSO</td>
            <td className="App__previsao__box__table__cell">DT. CHEGADA</td>
            <td className="App__previsao__box__table__cell">MODALIDADE</td>
            {/* <td className="App__previsao__box__table__cell">NAVIO / VOÔ</td> */}
            <td className="App__previsao__box__table__cell">DESTINO</td>
          </tr>
        </thead>
        <tbody>
          {dataPrevisao.slice(0, rows).map((row) => {
            return (
              <tr
                key={row.processoid}
                className="App__previsao__box__table__tr"
              >
                <td className="App__previsao__box__table__celldados">
                  {row.processo}
                </td>
                <td className="App__previsao__box__table__celldados">
                  {row.data}
                </td>
                <td className="App__previsao__box__table__celldados">
                  {row.modalidade}
                </td>
                {/* <td className="App__previsao__box__table__celldados">{row.nome}</td> */}
                <td className="App__previsao__box__table__celldados">
                  {row.destino}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
