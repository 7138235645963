import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
  root:{
   
  },
  container: {
    display: 'flex',    
    textAlign: 'center',
    flexDirection: 'column',   
  },
  bottomMargin: {
   
  },
  avatar:{
    margin: '0 auto',
    height: 100,
    width: 100,
  },
  dados:{
    textAlign: 'left',
  },
  dadosAcount: {
    flexDirection: 'row',
    position: 'relative',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',    
  },
  infoAccount: {
    textAlign: 'center',
    marginLeft: '10px',
  },
  info2Account:{
    marginLeft: '35px',
  }
});

class BaseDialog extends Component {
  render() {
    const { classes, open, onClose } = this.props;
    return (
      <Dialog className={classes.root}
        open={open}
        onClose={onClose}
        scroll='body'
      >
        <DialogTitle style={{backgroundColor: '#2c2c2c'}}id="alert-dialog-title"></DialogTitle>
        <DialogContent style={{backgroundColor: '#2c2c2c'}} >
          <DialogContentText id="alert-dialog-description" style={{color: '#858585'}}>
            <div className={classes.container}>
              {this.props.children}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(styles)(BaseDialog);