import React from 'react';
import { slideDown, slideUp } from '../../Helpers/anim';
import './style.css';

class CustoItens extends React.Component {
    state = { expanded: false }
  
    toggleExpander = (e) => {
      if (!this.state.expanded) {
        this.setState(
          { expanded: true },
          () => {
            if (this.refs.expanderBody) {
              slideDown(this.refs.expanderBody);
            }
          }
        );
      } else {
        slideUp(this.refs.expanderBody, {
          onComplete: () => { this.setState({ expanded: false }); }
        });
      }
    }
  
    render() {
      const { custos } = this.props;  
      const { subCusto } = this.props;        
          
      return [
        <tr key="main" onClick={this.toggleExpander}>
            <td style={{fontSize: '10px', textAlign: 'center', width:'auto', whiteSpace: 'nowrap'}}>Adição {custos.AdicaoNumero}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}></td>
            <td style={{fontSize: '10px', width:'200px', textAlign: 'center', whiteSpace: 'nowrap'}}></td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.Ncm}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center'}}>{custos.razaosocial_emp}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.PesoLiquido}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.FobValor}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.FreteValor}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.SeguroValor}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.CIFValor}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.iialiquota}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.CIFValor}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.IIValor}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.ipialiquota}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.baseII}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.IPIValor}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.pisaliquota}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.CIFValor}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.PISValor}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.cofinsaliquota}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.CIFValor}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.COFINSValor}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.TaxaSiscomex}</td>
            <td style={{fontSize: '10px', width:'auto', textAlign: 'center', whiteSpace: 'nowrap'}}>{custos.icmsaliquota}</td>
        </tr>,
        subCusto !== "" ?  
        this.state.expanded && (          
          subCusto.filter(custo => custo.AdicaoNumero === custos.AdicaoNumero).map((row, index) => {
                return[                  
                    <tr className="expandable" key="tr-expander">
                        <td style={{fontSize: '10px', textAlign: 'center'}}>{index + 1}</td>
                        <td style={{fontSize: '10px', textAlign: 'center'}}>{row.Codigo}</td>
                        <td style={{fontSize: '10px', textAlign: 'justify'}}>{row.Descricao}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.Ncm}</td>
                        <td style={{fontSize: '10px', textAlign: 'center'}}>{custos.razaosocial_emp}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.PesoLiquido}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.FobMEValor}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.Frete}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.Seguro}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.CIFValor}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.iialiquota}</td>                        
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.IIBase}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.IIValor}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.ipialiquota}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.ipiBase}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.IPI}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.pisaliquota}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.pisbase}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.pis}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.confinsaliquota}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.confinsBase}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.COFINS}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.TaxaSiscomex}</td>
                        <td style={{fontSize: '10px', textAlign: 'center', whiteSpace: 'nowrap'}}>{row.icmsaliquota}</td>
                    </tr>
                ]
                })          
        )
        :
        ""
      ];
    }
  }

  export default CustoItens;