import React from 'react'
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend }  from 'recharts'

export default function Fornecedores({dataFornecedores, colors, width, height, radius, cx, cy, sliceQtde, title}){

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc' }}>
                    <label>{`${payload[0].name} : ${payload[0].value}`}</label>
                </div>
            );
        }
        return null;
    };

    
    return(
        <>
            <div style={{height: 200, width: '100%'}}>
                <div className="previsao">
                    <span className="App__charts__span">{title}</span>  
                </div>
                
                <ResponsiveContainer  height={170}>
                <PieChart className="App__charts__perc"   >
                <Pie data={dataFornecedores} labelLine={false} cx={cx} cy={cy} label={renderCustomizedLabel} dataKey="qtd" nameKey="nome" outerRadius={radius}  >
                    {
                        dataFornecedores.map((entry, index) =><Cell key={`cell-${index}`} fill={colors[index % colors.length]}/>)
                    }
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend  payload={
                    dataFornecedores.slice(0, sliceQtde).map((
                    item, index) => ({
                        id: item.nome,                        
                        value: `${item.nome} - ${item.qtd}`,
                        color: `${colors[index % colors.length]}`,
                    })
                    )
                }
                verticalAlign="top" align="right" layout="vetical"
                />
                </PieChart>
                </ResponsiveContainer>
            </div>
        </>
    );
}