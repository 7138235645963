import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "#232323",
        color: "#858585",
      },
      tableTr:{
        fontSize: '0.8rem',
        fontWeight: 'bold',
        backgroundColor: '#232323', 
        color: "#858585",   
      },
      tableTdL:{
        width: '250px',
        textAlign: 'left',
        fontSize: '0.6rem',
      },
      tableTdR:{
        textAlign: 'right',
        fontSize: '0.6rem',
        height: '1rem'
      },
      tableTdRW:{
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '0.6rem',
      },
    tablePagination: {
      },
    tablePaginationCaption: {
        color:  "#858585",
        fontWeight: 'bold',
        fontSize: '0.9rem'
      },
    tablePaginationSelectIcon: {
        color:  "#858585",
        fontWeight: 'bold',
      },
    tablePaginationSelect: {
        color:  "#858585",
        fontWeight: 'bold',
      },
    tablePaginationActions: {
        color:  "#858585",
        fontWeight: 'bold',
      },
      indicator: {
        backgroundColor: '#232323',
        height: '3px'
      },
    }));

const CustosProcesso = ({dataCustoTotal, dataCusto, totalGeralCustos, totalGeralRecebido}) => {
    const classes = useStyles();    

    return (
        <div style={{margin: '0 30%'}}>
            <table className="App__modal__table" style={{borderCollapse: 'collapse'}}>
                <tbody>
                <tr className={classes.tableTr} style={{fontSize: '.7rem'}}>
                    <td className={classes.tableTdL}>
                    RELAÇÃO DE DESPESAS
                    </td>
                    <td className={classes.tableTdR}>
                    VALOR EM R$
                    </td>
                </tr>

                <tr style={{fontWeight: 'bold', backgroundColor: '#232323'}}>
                    <td className={classes.tableTdL}>
                    <span className={classes.fBold}>TRIBUTOS</span>
                    </td>
                    <td className={classes.tableTdR}>
                    {dataCustoTotal.filter(orders => orders.Grupo === 'Tributos').map((row, index) => {  
                    return(
                    <span className={classes.fBold}>{row.total}</span>
                    )
                    })}
                    </td>
                </tr>
                {dataCusto.filter(orders => orders.Grupo === 'Tributos').map((row, index) => {  
                    return ( 
                    <tr key={row.Id}>
                        <td className={classes.tableTdL}>{row.Descricao}</td>
                        <td className={classes.tableTdR}>{row.DespesaHistoricoValorAtualizado}</td>
                    </tr>
                    );
                    })}     
                    <tr>
                    <td colSpan="2">&nbsp;</td>
                    </tr>
                <tr style={{fontWeight: 'bold', backgroundColor: '#232323'}}>
                    <td className={classes.tableTdL}> 
                    <span className={classes.fBold}>DESPESAS DE EMBARQUE</span>
                    </td>
                    <td className={classes.tableTdR}>
                    {dataCustoTotal.filter(orders => orders.Grupo === 'Despesas de Embarque').map((row, index) => {  
                    return(
                    <span className={classes.fBold}>{row.total}</span>
                    )
                    })}
                    </td>
                </tr>
                {dataCusto.filter(orders => orders.Grupo === 'Despesas de Embarque').map((row, index) => {  
                    return ( 
                    <tr key={row.Id}>
                        <td className={classes.tableTdL}>{row.Descricao}</td>
                        <td className={classes.tableTdR}>{row.DespesaHistoricoValorAtualizado}</td>
                    </tr>
                    );
                    })}    
                    <tr>
                    <td colSpan="2">&nbsp;</td>
                    </tr>
                    <tr style={{fontWeight: 'bold', backgroundColor: '#232323'}}>
                    <td className={classes.tableTdL}> 
                    <span className={classes.fBold}>CUSTOS PORTUÁRIOS</span>
                    </td>
                    <td className={classes.tableTdR}>
                    {dataCustoTotal.filter(orders => orders.Grupo === 'Custos Portuários').map((row, index) => {  
                    return(
                    <span className={classes.fBold}>{row.total}</span>
                    )
                    })}
                    </td>
                </tr>
                {dataCusto.filter(orders => orders.Grupo === 'Custos Portuários').map((row, index) => {  
                    return ( 
                    <tr key={row.Id}>
                        <td className={classes.tableTdL}>{row.Descricao}</td>
                        <td className={classes.tableTdR}>{row.DespesaHistoricoValorAtualizado}</td>
                    </tr>
                    );
                    })}     
                    <tr>
                    <td colSpan="2">&nbsp;</td>
                    </tr>
                    <tr style={{fontWeight: 'bold', backgroundColor: '#232323'}}>
                    <td className={classes.tableTdL}> 
                    <span className={classes.fBold}>SERVIÇOS</span>
                    </td>
                    <td className={classes.tableTdR}>
                    {dataCustoTotal.filter(orders => orders.Grupo === 'Serviços').map((row, index) => {  
                    return(
                    <span className={classes.fBold}>{row.total}</span>
                    )
                    })}
                    </td>
                </tr>
                {dataCusto.filter(orders => orders.Grupo === 'Serviços').map((row, index) => {  
                    return ( 
                    <tr key={row.Id}>
                        <td className={classes.tableTdL}>{row.Descricao}</td>
                        <td className={classes.tableTdR}>{row.DespesaHistoricoValorAtualizado}</td>
                    </tr>
                    );
                    })}     
                    <tr>
                    <td colSpan="2">&nbsp;</td>
                    </tr>
                    <tr style={{fontWeight: 'bold', backgroundColor: '#232323'}}>
                    <td className={classes.tableTdL}> 
                    <span className={classes.fBold}>OUTRAS DESPESAS</span>
                    </td>
                    <td className={classes.tableTdR}>
                    {dataCustoTotal.filter(orders => orders.Grupo === 'Outras Despesas').map((row, index) => {  
                    return(
                    <span className={classes.fBold}>{row.total}</span>
                    )
                    })}
                    </td>
                </tr>
                {dataCusto.filter(orders => orders.Grupo === 'Outras Despesas').map((row, index) => {  
                    return ( 
                    <tr key={row.Id}>
                        <td className={classes.tableTdL}>{row.Descricao}</td>
                        <td className={classes.tableTdR}>{row.DespesaHistoricoValorAtualizado}</td>
                    </tr>
                    );
                    })} 
                    <tr>
                    <td colSpan="2">&nbsp;</td>
                    </tr>    
                    <tr>
                    <td className={classes.tableTdL}> 
                        <span className={classes.fBold}>Total Geral dos Custos</span>
                    </td>
                    <td className={classes.tableTdR}>
                        <span className={classes.fBold}>{totalGeralCustos}</span>                                
                    </td>
                    </tr>
                    <tr>
                    <td className={classes.tableTdL}> 
                        <span className={classes.fBold}>Total do Adiantamento Solicitado</span>
                    </td>
                    <td className={classes.tableTdR}>
                        <span className={classes.fBold}>{totalGeralCustos}</span>                                
                    </td>
                    </tr>
                    <tr>
                    <td className={classes.tableTdL}> 
                        <span className={classes.fBold}>Total do Adiantamento Recebido</span>
                    </td>
                    <td className={classes.tableTdR}>
                        <span className={classes.fBold}>{totalGeralRecebido}</span>                                
                    </td>
                    </tr>
                </tbody>
            </table> 
        </div>
    );
}

export default CustosProcesso;