import React from 'react'
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CustoItens from '../Modal/CustoItens';
import ReactExport  from 'react-data-export'

const StyledTableCell = withStyles(() => ({
    root: {
        borderBottom: "none"
      },
    head: {
        backgroundColor: "#242628",
        color: "#858585",
        fontSize: 12,
        fontWeight: 'bold',
    },
    body: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#8F929B'
    }
}))(TableCell);

const CustosItens = ({dataCustoItens, dataCustoSubItens}) => {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        <>
            <TableContainer>  
            {/* <ExcelFile  name="Detalhes" element={<button type="button">
            Excel
          </button>}
        >
          <ExcelSheet data={dataCustoSubItens} name="Detalhes">
                        
                        <ExcelColumn label="ADICAO" value="data"/>
                        <ExcelColumn label="BENEFICIADA" value="Beneficiada"/>              
                        <ExcelColumn label="CONTA" value="Conta"/>
                        <ExcelColumn label="CODIGO" value="Codigo"/> 
                        <ExcelColumn label="HISTORICO" value="Historico"/>   
                        <ExcelColumn label="COMPLEMENTO" value="Complemento"/>
                        <ExcelColumn label="DEBITO" value="Debito"/>              
                        <ExcelColumn label="CREDITO" value="Credito"/>           
                    </ExcelSheet>
            </ExcelFile> */}
                <Table border='1px solid #000' size="small" style={{tableLayout: 'fixed'}}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'70px'}}>POSIÇÃO</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'100px'}}>CÓDIGO</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'300px'}}>DESCRIÇÃO</StyledTableCell>                              
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'70px', whiteSpace: 'nowrap'}}>NCM</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'200px', whiteSpace: 'nowrap'}}>EXPORTADOR</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'80px'}}>P.LÍQUIDO</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'90px', whiteSpace: 'nowrap'}}>MERCADORIA</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'70px', whiteSpace: 'nowrap'}}>FRETE</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'70px', whiteSpace: 'nowrap'}}>SEGURO</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'150px', whiteSpace: 'nowrap'}}>VALOR ADUANEIRO</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'50px', whiteSpace: 'nowrap'}}>% II</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'70px', whiteSpace: 'nowrap'}}>BASE II</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'70px', whiteSpace: 'nowrap'}}>II</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'70px', whiteSpace: 'nowrap'}}>% IPI</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'70px', whiteSpace: 'nowrap'}}>BASE IPI</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'70px', whiteSpace: 'nowrap'}}>IPI</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'70px', whiteSpace: 'nowrap'}}>% PIS</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'70px', whiteSpace: 'nowrap'}}>BASE PIS</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'70px', whiteSpace: 'nowrap'}}>PIS</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'70px', whiteSpace: 'nowrap'}}>% COFINS</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'90px', whiteSpace: 'nowrap'}}>BASE COFINS</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'70px', whiteSpace: 'nowrap'}}>COFINS</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'110px', whiteSpace: 'nowrap'}}>TAXA SISCOMEX</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'70px', whiteSpace: 'nowrap'}}>% ICMS</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataCustoItens.map((row, index) => {  
                        return ( 
                        <>
                            <CustoItens key={index} index={index + 1} custos={row} subCusto={dataCustoSubItens}/>
                        </>
                            
                        );
                        })}     
                    
                    </TableBody>
                </Table> 
                </TableContainer>  
        </>
    );
}

export default CustosItens;