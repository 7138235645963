import React, { useState, useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core/styles';
import api from '../../services/api';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Modal from '../../components/Modal'
import { common } from '@material-ui/core/colors';

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#3D3F44',
      },
    },
  }))(TableRow);
    
    const StyledTableCell = withStyles((theme) => ({
        root: {
            borderBottom: "none"
          },
        head: {
            backgroundColor: "#242628",
            color: "#22b3A7",
            fontSize: 12,
            fontWeight: 'bold',
        },
        body: {
            fontSize: 12,
            fontWeight: 'bold',
            color: '#8F929B'
        }
    }))(TableCell);


export default function  TabUser ({data, type, filter}) {
    const [dataUsuario, setDataUsuario ] = useState([]);
    const [dataUsuarioEdit, setDataUsuarioEdit ] = useState([]);
    const [passwordShown, setPasswordShown] = useState(false);
    const [getTitle, setTitle] = React.useState('');
    const [getStateEditUser, setStateEditUser] = React.useState(false);
    const [ getNome, setNome ] = useState('');
    const [ getEmail, setEmail ] = useState('');

    
    useEffect(() => { 
        const getUsuarios = async () => {
          const result = await api.post('/api/empresas/usuarios/');    
          setDataUsuario(result.data.result);          
        }
        getUsuarios();
    }, []);

    const handleShowPassword = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const handleClickOpenEditUser = (idUser) => {    
        setTitle("Editar Usuario");    
        setDataUsuarioEdit(dataUsuario.filter(user => user.usu_id === idUser));
        {dataUsuario.filter(user => user.usu_id === idUser).map((row) => {
            return(
                setNome(row.usu_nome),
                setEmail(row.usu_email)
            );
        })};
        setStateEditUser(true);
      };

      const hideModalEditUser = () => {
        setStateEditUser(false);
      }   
    
      const updateInputValue = (e) => {
        e.persist();
        if (e.target.name === 'nome'){
            setNome(e.target.value);
        }
        if (e.target.name === 'email'){
            setEmail(e.target.value);
        }       
        // const { target: {value} } = e;
        // {dataUsuarioEdit.map((row) => {
        //     return(
        //         setNome(value)
        //     );
        // })};
     }
     


    return(
        <>

         
            <Table  size='small'>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell align="center">CÓDIGO</StyledTableCell>
                        <StyledTableCell align="center">NOME</StyledTableCell>
                        <StyledTableCell align="center">E-MAIL</StyledTableCell>
                        <StyledTableCell align="center">SENHA</StyledTableCell>                                    
                        <StyledTableCell align="center">STATUS</StyledTableCell>    
                        <StyledTableCell align="center">AÇÕES</StyledTableCell>          
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {dataUsuario.map((row) => { 
                        return(
                        <StyledTableRow>
                            <StyledTableCell align="center">{row.usu_codExt}</StyledTableCell>
                            <StyledTableCell align="center">{row.usu_nome}</StyledTableCell>
                            <StyledTableCell align="center">{row.usu_email}</StyledTableCell>                                    
                            <StyledTableCell align="center" className={passwordShown ? "App__table__showPassword" : "App__table__hidePassword"}>
                                {row.usu_senha}
                                <button className="App__table__btn__grey" onClick={() => handleShowPassword()}>
                                <VisibilityOutlinedIcon   />
                                </button>
                            </StyledTableCell> 
                            <StyledTableCell align="center">{row.usu_status === 1 ? "Ativo" : "Desativado"}</StyledTableCell>
                            <StyledTableCell align="center">
                                <EditOutlinedIcon onClick={() => handleClickOpenEditUser(row.usu_id)}/>    
                                {/* <EditOutlinedIcon onclick="Modal.open()" /> */}
                                <RemoveCircleOutlineOutlinedIcon style={{marginLeft: '15px'}}/>
                            </StyledTableCell>                                    
                        </StyledTableRow>
                        );
                    })}
                </TableBody>
                <Modal show={getStateEditUser} title={getTitle} handleClose={hideModalEditUser}>
                    {dataUsuarioEdit.map((row) => {
                        return(
                            <>
                                <div className="App_form">
                                    <div className="App__inputGroup">
                                        <input 
                                            type="text" 
                                            id="description" 
                                            name="description"
                                            name="codigo"
                                            className="App__input"
                                            placeholder="Código iLog"
                                            value={row.usu_codExt}
                                        />
                                    </div>
                                    <div className="App__inputGroup">
                                        <input 
                                            type="text" 
                                            id="description" 
                                            name="nome"
                                            className="App__input"
                                            placeholder="Nome"
                                            value={getNome}
                                            onChange={updateInputValue}
                                        />
                                    </div>
                                    <div className="App__inputGroup">
                                        <input 
                                            type="text" 
                                            id="description" 
                                            name="email"
                                            className="App__input"
                                            placeholder="E-mail"
                                            value={getEmail}
                                            onChange={updateInputValue}
                                        />
                                    </div>
                                    <div className="App__inputGroup">
                                        <input 
                                            type="password" 
                                            id="description" 
                                            name="senha"
                                            className="App__input"
                                            placeholder="E-mail"
                                            value={row.usu_senha}
                                        />
                                    </div>
                                    <div className="App__inputGroup">
                                        <button 
                                            type="button" 
                                            onClick={updateInputValue}
                                        />
                                    </div>
                                </div>
                            </>
                        );
                    })}
                    
                    
                </Modal>
            </Table>
            
        </>
        
    );

    

}