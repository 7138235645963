import React, { useState, useEffect, useRef } from "react";
import api from '../../services/api';
import { useReactToPrint } from 'react-to-print';
import { setNrProcesso, getNivelUsuario, setAnoPro, getNrProcesso, getAnoPro } from '../../services/auth';
import { Input, Button } from './styles'
import { Redirect } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header'
import Container from '../../components/Container'
import XLSX from 'xlsx';
import ReactExport from 'react-data-export'

const DemonstrativoImp = () => {
  const currentPath = useLocation();
  const [data, setData] = useState(false);
  const [dataDespesa, setDataDespesa] = useState(false);
  const [getNro, setNro] = useState('')
  const [getAno, setAno] = useState('')
  const [getName, setName] = useState('')
  const [pesquisa, setPesquisa] = useState(false);
  const [newPesquisa, setNewPesquisa] = useState(false);
  const componentRef = useRef();
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  async function handleExcel() {
    //Verificar quantas notas tem.
    const qtdAplicacao = await api.get(`/api/empresas/aplicacaoprocesso/${getNro}&${getAno}`)
    for (let i = 0; i < qtdAplicacao.data.aplicacao.length; i++) {
      criaDespesa(qtdAplicacao.data.aplicacao[i].aplicacao)
      //-- criaDemonstrativo(qtdAplicacao.data.aplicacao[i].aplicacao)
      teste(qtdAplicacao.data.aplicacao[i].aplicacao)
      criaDemonstrativoCliente(qtdAplicacao.data.aplicacao[i].aplicacao)
    }
  };

  const teste = async (aplicacao) => {
    const result = await api.get(`/api/empresas/demonstrativoitem/${getNro}&${getAno}&${aplicacao}`);
    const jsonDat2a = result.data.despesa.map((data) => Object.values(data));
    // Cria um workbook
    const workbook = XLSX.utils.book_new();
    // Cria uma planilha
    const worksheet = XLSX.utils.aoa_to_sheet([
      [`Demonstrativo de Cálculo por Item - ${getNro}/${getAno}`],
      [''],
      ['', ''],
      ['', ''],
      ['', ''],
      ['Importador', result.data.cabecalho[0]['razaosocial_emp'], 'CNPJ:', result.data.cabecalho[0]['cgc_emp']],
      ['Referência do Cliente:', result.data.cabecalho[0]['ident_cli_pro']],
      ['DI:', result.data.cabecalho[0]['di_pro'], 'Data de Registro:', result.data.cabecalho[0]['dtreg']],
      ['Taxa US$', result.data.cabecalho[0]['Valor1']],
      ['Adição', 'Item', 'Código', 'Descrição', 'Quantidade', 'Exportador', 'NCM', 'Unidade', 'Valor Mercadoria', 'Valor Aduaneiro', 'Desp. Aduaneiras', 'Frete', 'THC', 'Seguro', 'P. Líquido Unit.', 'P. Líquido', 'ICMS', 'ICMS ST', 'II Base', 'IPI Base', 'PIS Base', 'COFINS Base', 'II Aliquota', 'IPI Aliquota', 'PIS Aliquota', 'COFINS Aliquota', 'ICMS Aliquota', 'ICMS ST Aliquota', 'II Impostos', 'IPI Impostos', 'PIS Impostos', 'COFINS Impostos', 'ANTIDUMPING', 'Tx. Siscomex', 'Total',	'ICMS', 'ICMS ST']
    ]);

    jsonDat2a.map((row) => {
      return row.filter((_, colIndex) => colIndex !== 2);
    });

    const modifiedData = jsonDat2a.map((row) => {
      return row.filter((_, colIndex) => colIndex !== 2);
    });
    // Adiciona os dados do objeto JSON na linha 7    
    const startRow = 10;
    const startCol = 0;
    // Formata as colunas I a M como decimal
    XLSX.utils.sheet_add_aoa(worksheet, modifiedData, { origin: { r: startRow, c: startCol } });
    worksheet['A6'] = { t: 's', v: 'Adição', s: { alignment: { vertical: 'center', horizontal: 'center' }, font: { bold: true } } };
    const columnsToConvert = ['F', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN'];
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let col = 4; col <= 34; col++) { // Colunas E, I a AC
      for (let row = range.s.r; row <= range.e.r; row++) {
        const cellRef = XLSX.utils.encode_cell({ r: row, c: col });
        const cell = worksheet[cellRef];
        if (cell) {
          cell.t = 'n'; // Define o tipo da célula como número
          cell.z = '#,##0.00'; // Formato de número decimal com duas casas decimais
        }
      }
    }

    // Adicionar totalizadores
    const lastRowIndex = result.data.despesa.length;
    // Adicionar a linha de totalização
    const totalRowNumber = lastRowIndex + 10; // Próxima linha após os dados e o cabeçalho
    const totalRow = {
      A: 'Total', B: '', C: '', D: '',
      E: result.data.total[0]['Quantidade'].replace('.', '-').replace(',', '.').replace('-', ','),
      F: '', G: '', H: '',
      I: result.data.total[0]['ValorMercadoria'].replace('.', '-').replace(',', '.').replace('-', ','),
      J: result.data.total[0]['ValorAduaneiro'].replace('.', '-').replace(',', '.').replace('-', ','),
      K: result.data.total[0]['DespesasAduaneiras'].replace('.', '-').replace(',', '.').replace('-', ','),
      L: result.data.total[0]['Frete'].replace('.', '-').replace(',', '.').replace('-', ','),
      M: result.data.total[0]['THC'].replace('.', '-').replace(',', '.').replace('-', ','),
      N: result.data.total[0]['Seguro'].replace('.', '-').replace(',', '.').replace('-', ','),
      O: result.data.total[0]['PLiquidoUnit'].replace('.', '-').replace(',', '.').replace('-', ','),
      P: '',
      Q: result.data.total[0]['ICMS'].replace('.', '-').replace(',', '.').replace('-', ','),
      R: '',
      S: result.data.total[0]['IIBase'].replace('.', '-').replace(',', '.').replace('-', ','),
      T: result.data.total[0]['IPIBase'].replace('.', '-').replace(',', '.').replace('-', ','),
      U: result.data.total[0]['PISBase'].replace('.', '-').replace(',', '.').replace('-', ','),
      V: result.data.total[0]['COFINSBase'].replace('.', '-').replace(',', '.').replace('-', ','),
      W: '',
      X: '',
      Y: '',
      Z: '',
      AA: '',
      AB: '', 
      AC: result.data.total[0]['IIImpostos'].replace('.', '-').replace(',', '.').replace('-', ','),
      AD: result.data.total[0]['IPIImpostos'].replace('.', '-').replace(',', '.').replace('-', ','),
      AE: result.data.total[0]['PISImpostos'].replace('.', '-').replace(',', '.').replace('-', ','),
      AF: result.data.total[0]['COFINSImpostos'].replace('.', '-').replace(',', '.').replace('-', ','),
      AG: '',
      AH: result.data.total[0]['TXSiscomex'].replace('.', '-').replace(',', '.').replace('-', ','),
      AI: result.data.total[0]['Total'].replace('.', '-').replace(',', '.').replace('-', ','),
      AJ: result.data.total[0]['ICMSImpostos'].replace('.', '-').replace(',', '.').replace('-', ','),
      AK: result.data.total[0]['ICMSTImpostos'].replace('.', '-').replace(',', '.').replace('-', ',')
    };
    const emptyRowNumber = 3;
    XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: emptyRowNumber });
    XLSX.utils.sheet_add_json(worksheet, [totalRow], { origin: totalRowNumber, skipHeader: true });

    // Converte o workbook para um arquivo Excel binário
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    let name = aplicacao === 3 ? 'CONSUMO' : 'REVENDA'
    const fileName = `Demonstrativo de Cálculo por Item - ${getNro}-${getAno} - ${name}.xlsx`;

    if (typeof window !== 'undefined' && window.navigator && window.navigator.msSaveOrOpenBlob) {
      // For IE browser
      window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(dataBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const criaDespesa = async (aplicacao) => {
    //Traz as depesas Aduaneiras com rateio
    const result = await api.get(`/api/empresas/despaduaneira/${getNro}&${getAno}&${aplicacao}`);
    setDataDespesa(result.data.despesa);
    console.log(JSON.stringify(result.data))
    let name = aplicacao === 3 ? 'CONSUMO' : 'REVENDA';
    const worksheet = XLSX.utils.json_to_sheet(result.data.despesa);
    // Convert column B to a decimal format
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
      const cellRef = XLSX.utils.encode_cell({ r: rowNum, c: 1 });
      if (worksheet[cellRef] && worksheet[cellRef].t === 's') {
        const cellValue = parseFloat(worksheet[cellRef].v);
        worksheet[cellRef].t = 'n'; // Change cell type to numeric
        worksheet[cellRef].v = Number.isNaN(cellValue) ? null : cellValue; // Assign the parsed decimal value
        worksheet[cellRef].z = '0.00'; // Set the decimal number format (two decimal places)
      }
    }
    // Adicionar totalizadores
    const lastRowIndex = result.data.despesa.length;
    // Adicionar a linha de totalização
    const totalRowNumber = lastRowIndex + 1; // Próxima linha após os dados e o cabeçalho
    const totalRow = {
      A: 'Total', B: '', C: '', D: '',
      B: result.data.total[0]['valor'].replace('.', '-').replace(',', '.').replace('-', ',')
    };
    XLSX.utils.sheet_add_json(worksheet, [totalRow], { origin: totalRowNumber, skipHeader: true });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const fileName = `Despesas Aduaneiras ${getNro}-${getAno} - ${name}.xlsx`;


    if (typeof window !== 'undefined' && window.navigator && window.navigator.msSaveOrOpenBlob) {
      // For IE browser
      window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(dataBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

  }

  const criaDemonstrativo = async (aplicacao) => {
    const result = await api.get(`/api/empresas/demonstrativoitem/${getNro}&${getAno}&${aplicacao}`);
    // Criação da planilha
    // const worksheet = XLSX.utils.aoa_to_sheet([]);


    // let name = aplicacao === 3 ? 'CONSUMO' : 'REVENDA'
    // const fileName = `Demonstrativo de Cálculo por Item - ${getNro}-${getAno} - ${name}.xlsx`;
    // // Adicionar cabeçalho nas linhas 1 e 2
    // worksheet['A1'] = { t: 's', v: `Demonstrativo de Cálculo por Item - ${getNro}/${getAno}` };
    // // Deixar as linhas 4 e 5 em branco
    // worksheet['A4'] = { t: 's', v: '' };
    // worksheet['A5'] = { t: 's', v: '' };

    // // Adicionar "Importador" na coluna A da linha 6 e "Cliente" na coluna B da linha 7
    // worksheet['A6'] = { t: 's', v: 'Importador:' };
    // worksheet['B6'] = { t: 's', v: 'Importador' };
    // worksheet['C6'] = { t: 's', v: 'CNPJ:' };
    // worksheet['D6'] = { t: 's', v: 'CNPJ' };
    // worksheet['A7'] = { t: 's', v: 'Referência do Cliente:' };
    // worksheet['B7'] = { t: 's', v: 'IR# 123/2023 - KIT 6080 (P2)' };
    // worksheet['A8'] = { t: 's', v: 'DI:' };
    // worksheet['B8'] = { t: 's', v: '23/1244986-1' };
    // worksheet['C8'] = { t: 's', v: 'Data de Registro:' };
    // worksheet['A9'] = { t: 's', v: 'Taxa US$:' };
    // worksheet['B9'] = { t: 's', v: '4,77' };

    // // Adicionar uma linha vazia após o cabeçalho
    // const emptyRowNumber = 9;
    // XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: emptyRowNumber });

    // // Adicionar o JSON a partir da linha 10
    // const jsonDataRows = result.data.despesa.map((data) => Object.values(data));
    // const jsonDataStartRow = 9;
    // XLSX.utils.sheet_add_aoa(worksheet, jsonDataRows, { origin: -1, skipHeader: true });
    // const range = XLSX.utils.decode_range(worksheet['!ref']);
    // // Formatar as colunas F, J e K como números decimais
    // const columnsToConvert = ['F', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN'];
    // for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
    //   const column = XLSX.utils.encode_col(colNum);
    //   if (columnsToConvert.includes(column)) {
    //     for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
    //       const cellRef = XLSX.utils.encode_cell({ r: rowNum, c: colNum });

    //       if (worksheet[cellRef] && worksheet[cellRef].t === 's') {
    //         const cellValue = parseFloat(worksheet[cellRef].v.replace(',', '.'));
    //         worksheet[cellRef].t = 'n'; // Change cell type to numeric
    //         worksheet[cellRef].v = Number.isNaN(cellValue) ? null : cellValue; // Assign the parsed decimal value
    //         worksheet[cellRef].z = '0.00'; // Set the decimal number format (two decimal places)
    //       }
    //     }
    //   }
    // }

    // const columnToRemove = 'C';
    // // Loop through all cells in the worksheet
    // for (const cellAddress in worksheet) {
    //   if (cellAddress[0] === columnToRemove) {
    //     // Remove the cell from the worksheet
    //     delete worksheet[cellAddress];
    //   } else if (cellAddress[0] > columnToRemove) {
    //     // Adjust the column index for cells after the removed column
    //     const updatedAddress =
    //       String.fromCharCode(cellAddress.charCodeAt(0) - 1) +
    //       cellAddress.slice(1);
    //     worksheet[updatedAddress] = worksheet[cellAddress];
    //     delete worksheet[cellAddress];
    //   }
    // }

    // // Adicionar totalizadores das colunas F, J e K na última linha
    // const totalRow = {
    //   A: 'Total', B: '', C: '', D: '',
    //   E: result.data.total[0]['Quantidade'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   F: '', G: '', H: '',
    //   I: result.data.total[0]['ValorMercadoria'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   J: result.data.total[0]['ValorAduaneiro'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   K: result.data.total[0]['DespesasAduaneiras'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   L: result.data.total[0]['Frete'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   M: result.data.total[0]['THC'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   N: result.data.total[0]['Seguro'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   O: result.data.total[0]['PLiquidoUnit'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   P: '',
    //   Q: result.data.total[0]['ICMS'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   R: result.data.total[0]['IIBase'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   S: result.data.total[0]['IPIBase'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   T: result.data.total[0]['PISBase'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   U: result.data.total[0]['COFINSBase'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   V: '',
    //   W: '',
    //   X: '',
    //   Y: '',
    //   Z: '',
    //   AA: '',
    //   AB: '',
    //   AC: result.data.total[0]['IIImpostos'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   AD: result.data.total[0]['IPIImpostos'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   AE: result.data.total[0]['PISImpostos'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   AF: result.data.total[0]['COFINSImpostos'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   AG: result.data.total[0]['TXSiscomex'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   AH: result.data.total[0]['Total'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   AI: result.data.total[0]['ICMSImpostos'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   AJ: result.data.total[0]['ICMSTImpostos'].replace('.', '-').replace(',', '.').replace('-', ',')
    // };
    // const lastRowIndex = result.data.despesa.length
    // const totalRowNumber = lastRowIndex + 3;
    // XLSX.utils.sheet_add_json(worksheet, [totalRow], { origin: totalRowNumber, skipHeader: true });
    // // Criação do livro de trabalho
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

    // // Salvar o arquivo Excel
    // XLSX.writeFile(workbook, fileName);
    // //Traz as depesas Aduaneiras com rateio
    // const result = await api.get(`/api/empresas/demonstrativoitem/${getNro}&${getAno}&${aplicacao}`);
    // // Criação da planilha
    // // const worksheet = XLSX.utils.aoa_to_sheet([]);


    const worksheet = XLSX.utils.json_to_sheet(result.data.despesa);
    // Adicionar cabeçalho nas linhas 1 e 2
    // worksheet['A1'] = { t: 's', v: 'Cabeçalho' };
    // worksheet['A2'] = { t: 's', v: 'Cabeçalho' };

    // // Deixar as linhas 4 e 5 em branco
    // worksheet['A4'] = { t: 's', v: '' };
    // worksheet['A5'] = { t: 's', v: '' };

    // // Adicionar "Importador" na coluna A da linha 6 e "Cliente" na coluna B da linha 7
    // worksheet['A6'] = { t: 's', v: 'Importador' };
    // worksheet['B7'] = { t: 's', v: 'Cliente' };
    // Columns to convert to decimal format: I, J, K, L, M, N, O, P, Q
    const columnsToConvert = ['F', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN'];

    const range = XLSX.utils.decode_range(worksheet['!ref']);

    for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
      const column = XLSX.utils.encode_col(colNum);
      if (columnsToConvert.includes(column)) {
        for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
          const cellRef = XLSX.utils.encode_cell({ r: rowNum, c: colNum });

          if (worksheet[cellRef] && worksheet[cellRef].t === 's') {
            const cellValue = parseFloat(worksheet[cellRef].v.replace(',', '.'));
            worksheet[cellRef].t = 'n'; // Change cell type to numeric
            worksheet[cellRef].v = Number.isNaN(cellValue) ? null : cellValue; // Assign the parsed decimal value
            worksheet[cellRef].z = '0.00'; // Set the decimal number format (two decimal places)
          }
        }
      }
    }
    const columnToRemove = 'C';
    // Loop through all cells in the worksheet
    for (const cellAddress in worksheet) {
      if (cellAddress[0] === columnToRemove) {
        // Remove the cell from the worksheet
        delete worksheet[cellAddress];
      } else if (cellAddress[0] > columnToRemove) {
        // Adjust the column index for cells after the removed column
        const updatedAddress =
          String.fromCharCode(cellAddress.charCodeAt(0) - 1) +
          cellAddress.slice(1);
        worksheet[updatedAddress] = worksheet[cellAddress];
        delete worksheet[cellAddress];
      }
    }

    // Adicionar totalizadores
    const lastRowIndex = result.data.despesa.length;

    // Adicionar a linha de totalização
    const totalRowNumber = lastRowIndex + 1; // Próxima linha após os dados e o cabeçalho
    const totalRow = {
      A: 'Total', B: '', C: '', D: '',
      E: result.data.total[0]['Quantidade'].replace('.', '-').replace(',', '.').replace('-', ','),
      F: '', G: '', H: '',
      I: result.data.total[0]['ValorMercadoria'].replace('.', '-').replace(',', '.').replace('-', ','),
      J: result.data.total[0]['ValorAduaneiro'].replace('.', '-').replace(',', '.').replace('-', ','),
      K: result.data.total[0]['DespesasAduaneiras'].replace('.', '-').replace(',', '.').replace('-', ','),
      L: result.data.total[0]['Frete'].replace('.', '-').replace(',', '.').replace('-', ','),
      M: result.data.total[0]['THC'].replace('.', '-').replace(',', '.').replace('-', ','),
      N: result.data.total[0]['Seguro'].replace('.', '-').replace(',', '.').replace('-', ','),
      O: '',
      P: result.data.total[0]['PLiquidoUnit'].replace('.', '-').replace(',', '.').replace('-', ','),
      Q: result.data.total[0]['ICMS'].replace('.', '-').replace(',', '.').replace('-', ','),
      R: result.data.total[0]['IIBase'].replace('.', '-').replace(',', '.').replace('-', ','),
      S: result.data.total[0]['IPIBase'].replace('.', '-').replace(',', '.').replace('-', ','),
      T: result.data.total[0]['PISBase'].replace('.', '-').replace(',', '.').replace('-', ','),
      U: result.data.total[0]['COFINSBase'].replace('.', '-').replace(',', '.').replace('-', ','),
      V: '',
      W: '',
      X: '',
      Y: '',
      Z: '',
      AA: '',
      AB: '',
      AC: result.data.total[0]['IIImpostos'].replace('.', '-').replace(',', '.').replace('-', ','),
      AD: result.data.total[0]['IPIImpostos'].replace('.', '-').replace(',', '.').replace('-', ','),
      AE: result.data.total[0]['PISImpostos'].replace('.', '-').replace(',', '.').replace('-', ','),
      AF: result.data.total[0]['COFINSImpostos'].replace('.', '-').replace(',', '.').replace('-', ','),
      AG: result.data.total[0]['TXSiscomex'].replace('.', '-').replace(',', '.').replace('-', ','),
      AH: result.data.total[0]['Total'].replace('.', '-').replace(',', '.').replace('-', ','),
      AI: result.data.total[0]['ICMSImpostos'].replace('.', '-').replace(',', '.').replace('-', ','),
      AJ: result.data.total[0]['ICMSTImpostos'].replace('.', '-').replace(',', '.').replace('-', ',')
    };
    const emptyRowNumber = 3;
    XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: emptyRowNumber });

    // Adicionar o JSON a partir da linha 10
    const jsonDataRows = result.data.despesa.map((data) => Object.values(data));
    const jsonDataStartRow = 9;
    // XLSX.utils.sheet_add_aoa(worksheet, jsonDataRows, { origin: -1, skipHeader: true });
    XLSX.utils.sheet_add_json(worksheet, [totalRow], { origin: totalRowNumber, skipHeader: true });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    let name = aplicacao === 3 ? 'CONSUMO' : 'REVENDA'
    const fileName = `Demonstrativo de Cálculo por Item - ${getNro}-${getAno} - ${name}.xlsx`;

    if (typeof window !== 'undefined' && window.navigator && window.navigator.msSaveOrOpenBlob) {
      // For IE browser
      window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(dataBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const criaDemonstrativoCliente = async (aplicacao) => {
    //Traz as depesas Aduaneiras com rateio
    const result = await api.get(`/api/empresas/demonstrativocliente/${getNro}&${getAno}&${aplicacao}`);
    const worksheet = XLSX.utils.json_to_sheet(result.data.despesa);
    // Columns to convert to decimal format: I, J, K, L, M, N, O, P, Q
    const columnsToConvert = ['C', 'D', 'E'];

    const range = XLSX.utils.decode_range(worksheet['!ref']);

    for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
      const column = XLSX.utils.encode_col(colNum);

      if (columnsToConvert.includes(column)) {
        for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
          const cellRef = XLSX.utils.encode_cell({ r: rowNum, c: colNum });
          if (worksheet[cellRef] && worksheet[cellRef].t === 's') {
            const cellValue = parseFloat(worksheet[cellRef].v.replace(',', '.'));
            worksheet[cellRef].t = 'n'; // Change cell type to numeric
            worksheet[cellRef].v = Number.isNaN(cellValue) ? null : cellValue; // Assign the parsed decimal value
            worksheet[cellRef].z = '0.00'; // Set the decimal number format (two decimal places)
          }
        }
      }
    }
    // Adicionar totalizadores
    // const lastRowIndex = result.data.despesa.length;
    // // Adicionar a linha de totalização
    // const totalRowNumber = lastRowIndex + 1; // Próxima linha após os dados e o cabeçalho
    // const totalRow = {
    //   A: 'Total',
    //   B: '',
    //   C: result.data.total[0]['Quantidade'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   D: result.data.total[0]['Unitario'].replace('.', '-').replace(',', '.').replace('-', ','),
    //   E: result.data.total[0]['ValorTotal'].replace('.', '-').replace(',', '.').replace('-', ','),
    // };
    // const emptyRowNumber = 3;
    // XLSX.utils.sheet_add_aoa(worksheet, [['']], { origin: emptyRowNumber });
    // XLSX.utils.sheet_add_json(worksheet, [totalRow], { origin: totalRowNumber, skipHeader: true });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    let name = aplicacao === 3 ? 'CONSUMO' : 'REVENDA'
    const fileName = `Demonstrativo Cliente - ${getNro}-${getAno} - ${name}.xlsx`;

    if (typeof window !== 'undefined' && window.navigator && window.navigator.msSaveOrOpenBlob) {
      // For IE browser
      window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(dataBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }


  if ((getNivelUsuario() === 0)) {
    return <Redirect to='/' />
  }
  if ((getNivelUsuario() === 1)) {
    return <Redirect to='/' />
  }
  if ((getNivelUsuario() === 2)) {
    return <Redirect to='/' />
  }

  if ((getNivelUsuario() === 3)) {
    return <Redirect to='/' />
  }
  if ((getNivelUsuario() === 4)) {
    return <Redirect to='/' />
  }

  return (
    <>
      <Header currentPath={currentPath.pathname} />
      <Container>
        <div>
          <div style={{ display: 'flex', width: '100%', padding: '1rem' }}>
            <Input placeholder="Processo" onChange={e => setNro(e.target.value)} style={{ width: '5rem' }} />
            <Input placeholder="Ano" onChange={e => setAno(e.target.value)} style={{ width: '4rem' }} />
            {/* <Button type="button" onClick={showRel}>
              PESQUISAR
            </Button> */}
            <Button type="button" onClick={handleExcel}>
              Gerar Excel
            </Button>
            {/* <Button type="button" onClick={handleNewSeacrh}>
              NOVA CONSULTA
            </Button> */}
          </div>
          {/* {data ? <ComponentToPrint ref={componentRef} /> : ""}  */}
        </div>
      </Container>
    </>
  );
};

export default DemonstrativoImp;