import React, { useState, useEffect } from 'react'
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ReactExport  from 'react-data-export'
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';

    const StyledTableRow = withStyles(() => ({
        root: {      
        '&:nth-of-type(odd)': {
        backgroundColor: '#2c2c2c',
        },
    },
    }))(TableRow);

    const StyledTableCell = withStyles(() => ({
        root: {
            borderBottom: "none"
        },
        head: {
            backgroundColor: "#2c2c2c",
            color: "#858585",
            fontSize: 12,
            fontWeight: 'bold',
        },
        body: {
            fontSize: 12,
            fontWeight: 'bold',
            color: '#8F929B'
        }
    }))(TableCell);

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }
    
      function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const useStyles = makeStyles(() => ({
        root: {
            backgroundColor: "#2c2c2c",
            color: "#22b3A7",
            
          },
          tableTr:{
            fontSize: '0.8rem',
            fontWeight: 'bold',
            backgroundColor: '#232323', 
            color: "#858585",   
          },
          tableTdL:{
            width: '250px',
            textAlign: 'left',
          },
          tableTdR:{
            textAlign: 'right'
          },
          tableTdRW:{
            textAlign: 'right',
            fontWeight: 'bold',
          },
        tablePagination: {
          },
        tablePaginationCaption: {
            color:  "#858585",
            fontWeight: 'bold',
            fontSize: '0.9rem'
          },
        tablePaginationSelectIcon: {
            color:  "#858585",
            fontWeight: 'bold',
          },
        tablePaginationSelect: {
            color:  "#858585",
            fontWeight: 'bold',
          },
        tablePaginationActions: {
            color:  "#858585",
            fontWeight: 'bold',
          },
          indicator: {
            backgroundColor: '#858585',
            height: '3px'
          },
        }));


export default function TableSeguro ({data}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');    
    const [selected, setSelected] = React.useState([]);
    const isSelected = (name) => selected.indexOf(name) !== -1;    
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const handleChangePage = (event, newPage) => {  
        setPage(newPage);  
      };  
    
    const handleChangeRowsPerPage = event => {  
        setRowsPerPage(+event.target.value);  
        setPage(0);  
    };  

    return (            
        <>
            <TableContainer>
            <div>

                <ExcelFile  name="Notas" element={<><button className="App__table__btn__green" style={{height:'25px', position:'relative', cursor: 'pointer'}}>
                <div style={{height: '20px'}}> 
                  <span style={{height:'40px' }}>Exportar Excel</span>&nbsp;<CloudDownloadOutlinedIcon align="bottom" style={{height:'20px', verticalAlign:'middle',}} />                  
                </div>
              </button></>}>
                    <ExcelSheet data={data} name="Notas">
                        <ExcelColumn label="PROCESSO" value="processo"/>
                        <ExcelColumn label="CLIENTE" value="Cliente"/>              
                        <ExcelColumn label="IMPORTADOR" value="Importador"/>
                        <ExcelColumn label="EXPORTADOR" value="Exportador"/> 
                        <ExcelColumn label="REFERÊNCIA" value="Referencia"/>
                        <ExcelColumn label="LIBERADO" value="Lib_Faturar"/>  
                        <ExcelColumn label="FATURADO" value="Faturado"/> 
                    </ExcelSheet>
                </ExcelFile>
            </div>
                <Table aria-labelledby="tableTitle" size='small' aria-label="enhanced table">  
                    <TableHead> 
                        <TableRow>
                            <StyledTableCell align="center" style={{whiteSpace: 'nowrap'}}>PROCESSO</StyledTableCell>
                            <StyledTableCell align="center" style={{whiteSpace: 'nowrap'}}>CLIENTE</StyledTableCell>
                            <StyledTableCell align="center" style={{whiteSpace: 'nowrap'}}>IMPORTADOR</StyledTableCell>
                            <StyledTableCell align="center" style={{whiteSpace: 'nowrap'}}>EXPORTADOR</StyledTableCell>
                            <StyledTableCell align="center" style={{whiteSpace: 'nowrap'}}>REFERÊNCIA DO CLIENTE</StyledTableCell>
                            <StyledTableCell align="center" style={{whiteSpace: 'nowrap'}}>LIBERADO</StyledTableCell>
                            <StyledTableCell align="center" style={{whiteSpace: 'nowrap'}}>FATURADO</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stableSort(data, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {  
                            const isItemSelected = isSelected(row.nro_pro);
                            const labelId = `enhanced-table-checkbox-${index}`;                    
                            return (  
                                <StyledTableRow   
                                hover
                                role="checkbox"                                
                                tabIndex={-1}
                                key={row.processo}                                
                                >  
                                <StyledTableCell align="center" id={labelId} style={{width: '2%'}}>{row.processo}</StyledTableCell>  
                                <StyledTableCell align="center" style={{whiteSpace: 'nowrap', width: '20%'}}>{row.Cliente}</StyledTableCell>  
                                <StyledTableCell align="center" style={{whiteSpace: 'nowrap', width: '20%'}}>{row.Importador}</StyledTableCell>  
                                <StyledTableCell align="center" style={{whiteSpace: 'nowrap', width: '20%'}}>{row.Exportador}</StyledTableCell>  
                                <StyledTableCell align="center" style={{whiteSpace: 'normal', width: '1px'}}>{row.Referencia}</StyledTableCell>               
                                <StyledTableCell align="center" style={{whiteSpace: 'nowrap', width: '2%'}}>{row.Lib_Faturar}</StyledTableCell>     
                                <StyledTableCell align="center" style={{width: '2%'}}>{row.Faturado}</StyledTableCell>                                              
                            </StyledTableRow>  
                                );  
                            })}  
                    </TableBody>                  
                </Table>
            </TableContainer>
            <TablePagination  
                rowsPerPageOptions={[10, 20, 30]}  
                component="div"  
                count={data.length}  
                rowsPerPage={rowsPerPage}  
                page={page} 
                labelRowsPerPage={"REGISTROS POR PÁGINA"}
                classes={{
                    root: classes.tablePagination,
                    caption: classes.tablePaginationCaption,
                    selectIcon: classes.tablePaginationSelectIcon,
                    select: classes.tablePaginationSelect,
                    actions: classes.tablePaginationActions,
                  }}
                onChangePage={handleChangePage}  
                onChangeRowsPerPage={handleChangeRowsPerPage}  
            />  
        </>
    );
}