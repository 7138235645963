import React, { useState, useEffect } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import FusionCharts from "fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Maps from "fusioncharts/fusioncharts.maps";
import Charts from "fusioncharts/fusioncharts.charts";
import overlapp from "fusioncharts/fusioncharts.overlappedcolumn2d";
import widgets from "fusioncharts/fusioncharts.widgets";
import World from "fusioncharts/maps/fusioncharts.world";
import power from "fusioncharts/fusioncharts.powercharts";
import ReactFC from "react-fusioncharts";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import api from "../../services/api";
import {
  getIdEmpresa,
  getNomeUsuario,
  getNivelUsuario,
} from "../../services/auth";
import Container from "../../components/Container";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Fornecedores from "../../components/Charts/Fornecedores";
import { Redirect } from "react-router-dom";
import useWindowDimensions from "../../Helpers/useWindowDimensions";
import { Download, newDowload } from "../../Helpers/format";

ReactFC.fcRoot(
  FusionCharts,
  Charts,
  overlapp,
  widgets,
  power,
  Maps,
  World,
  FusionTheme
);

const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#2c2c2c",
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#232323",
    fontFamily: "GHOTIC",
    color: "#858585",
    alignItems: "center",
    verticalAlign: "middle",
    border: "none",
    fontWeight: "bold",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  container: {
    display: "flex",
    marginLeft: "10px",
    marginTop: "10px",
    height: "35px",
    backgroundColor: "#232323",
  },
  tablePagination: {},
  tablePaginationCaption: {
    color: "#8F929B",
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
  tablePaginationSelectIcon: {
    color: "#8F929B",
    fontWeight: "bold",
  },
  tablePaginationSelect: {
    color: "#8F929B",
    fontWeight: "bold",
  },
  tablePaginationActions: {
    color: "#8F929B",
    fontWeight: "bold",
  },
}));

const StyledTableCell = withStyles(() => ({
  root: {
    borderBottom: "none",
  },
  head: {
    backgroundColor: "#2c2c2c",
    color: "#858585",
    fontSize: 12,
    fontWeight: "bold",
  },
  body: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#8F929B",
  },
}))(TableCell);

export default function Etiquetas() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [getData, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [page, setPage] = React.useState(0);

  useEffect(() => {
    const GetData = async () => {
      if (getIdEmpresa() > 0) {
        const result = await api.get("/api/etiquetas");
        setData(result.data.result);
      }
    };

    GetData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const FileDownloadBlob = async (etiqueta) => {
    const result = await api.get("/api/etiquetat/" + etiqueta);
    // Obter o array de bytes da resposta da API
    var blob = newDowload(result.data, "application/txt", etiqueta);
    // Criar uma URL para o Blob
    const url = URL.createObjectURL(blob);

    // Criar um link para download
    const link = document.createElement("a");
    link.href = url;
    link.download = etiqueta + ".txt";

    // Simular um clique no link
    document.body.appendChild(link);
    link.click();

    // Remover o link e revogar a URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <header className="App__header" />
      <Container></Container>
      <div className="App__imp__paper2">
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
            border="0"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="center"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Nota Fiscal
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Cliente
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Download
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.IDARQ}
                    >
                      <StyledTableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "20%" }}
                      >
                        {row.NUMERO_NF}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "20%" }}
                      >
                        {row.CLIENTE}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "2%" }}
                      >
                        <CloudDownloadOutlinedIcon
                          align="bottom"
                          style={{ height: "20px", verticalAlign: "middle" }}
                          onClick={(event) => FileDownloadBlob(row.IDARQ)}
                        />
                        <button className="App__table__btn__grey ">
                          Detalhes
                        </button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 35]}
          component="div"
          count={getData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={"REGISTROS POR PÁGINA"}
          classes={{
            root: classes.tablePagination,
            caption: classes.tablePaginationCaption,
            selectIcon: classes.tablePaginationSelectIcon,
            select: classes.tablePaginationSelect,
            actions: classes.tablePaginationActions,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}
