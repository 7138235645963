import React, { useState, useEffect, useRef } from "react";
import api from '../../services/api';
import { useReactToPrint } from 'react-to-print';
import { setNrProcesso, getNivelUsuario, setAnoPro, getNrProcesso, getAnoPro, setFilter, getFilter } from '../../services/auth';
import { ComponentToPrint } from './ComponentToPrint';
import { Input, Button, ButtonExcel, ExportCsv } from './styles'
import { Redirect } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header'
import Container from '../../components/Container'
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import ReactExport  from 'react-data-export'

const DemonstrativoWinning = () => {
    const currentPath = useLocation();
    const [ data, setData ] = useState(false);
    const [ pesquisa, setPesquisa ] = useState(false);
    const [ newPesquisa, setNewPesquisa ] = useState(false);
    const componentRef = useRef();
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
  });
    if ((getNivelUsuario() === '0')){
      return <Redirect to='/'  />
    }else if ((getNivelUsuario() === '1')){
      return <Redirect to='/'  />
    }else if ((getNivelUsuario() === '2')){
      return <Redirect to='/'  />
    }else if ((getNivelUsuario() === '3')){
      return <Redirect to='/'  />
    }
    

    const showRel = async ()  =>{
      if(getNrProcesso() > 0 && getAnoPro() > 0){
        pesquisa ? setPesquisa(false) : setPesquisa(true);      
        setData(true);      
        setNewPesquisa(true);
        if(newPesquisa){        
          window.location.reload();
          setNewPesquisa(true);
        }
      }
      if((getFilter() === '2')){
        const getFicha = async () => {
          const result = await api.post('/api/empresas/fichafinw/',{"processo":getNrProcesso(),"ano":getAnoPro()});
              setData(result.data.demonstrativo);
        }
        getFicha();
      }
      
    }

    const handleNewSeacrh = () => {
      setNrProcesso('');
      setAnoPro('');
      setData(false); 
      window.location.reload();
    }    

  return (
    <>
    {/* <Header currentPath={currentPath.pathname}/>  */}
    <Container>
      <div>
        <div style={{display: 'flex', width: '100%', padding: '1rem'}}>
        <Input placeholder="Processo" onChange={e => setNrProcesso(e.target.value)} style={{width:'5rem'}}/>
        <Input placeholder="Ano" onChange={e => setAnoPro(e.target.value)} style={{width:'4rem'}}/>
        <div onChange={e => setFilter(e.target.value)} style={{marginRight:'0.5rem'}}>
          <input type="radio" value="1" name="model"/> Demon. Financeiro<br/>
          <input type="radio" value="2" name="model"/> Ficha Financeira
        </div>
        <Button type="button" onClick={showRel}>
          PESQUISAR
        </Button>
        <Button type="button" onClick={handlePrint}>
        Gerar PDF
        </Button>

            <ExcelFile  name="Detalhes" element={<Button type="button">
            Excel
          </Button>}
        >
          <ExcelSheet data={data} name="Detalhes">
                        
                        <ExcelColumn label="DATA" value="data"/>
                        <ExcelColumn label="BENEFICIADA" value="Beneficiada"/>              
                        <ExcelColumn label="CONTA" value="Conta"/>
                        <ExcelColumn label="CODIGO" value="Codigo"/> 
                        <ExcelColumn label="HISTORICO" value="Historico"/>   
                        <ExcelColumn label="COMPLEMENTO" value="Complemento"/>
                        <ExcelColumn label="DEBITO" value="Debito"/>              
                        <ExcelColumn label="CREDITO" value="Credito"/>           
                    </ExcelSheet>
            </ExcelFile>
        <Button type="button" onClick={handleNewSeacrh}>
        NOVA CONSULTA
        </Button>
        </div>
        {data ? <ComponentToPrint ref={componentRef} /> : ""} 
      </div>
    </Container>
    </>
  );
};

export default DemonstrativoWinning;