import React, { useEffect, useState, makeStyles } from "react";
import Select from '../../components/Select/CodnPagamento'
import SelectProduct from '../../components/Select/Product'
import ListItem from '../../components/ListItem'
import NewTaskInput from '../../components/NewTaskInput'
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

export default function Pedidos({ onSubmit }){
  const [showNewOrder, setShowNewOrder] = useState(false);
  const [getCliente, setCliente] = useState('');
  const [status, setStatusBase] = React.useState("");
  const [getIdPagamento, setIdPagamento] = React.useState(0);
  const [pagamento, setPagamento] = React.useState("Condi. Pagamento");
  const [open, setOpen] = React.useState(false);
  const [newItem, setNewItem] = useState('');
  const [tasks, setTasks] = useState([]);

  const handleClickToOpen = () => {
    setOpen(true);
  };
  
  const handleToClose = () => {
    setOpen(false);
  };

  const handleSelectStatus = (option)=> {
    this.setState({id:option.value, name:option.label});
    setIdPagamento(option.value);
    setPagamento(option.label);      
 } 
  
  const handleClickNewOrder = (event) => {
    setShowNewOrder(true);
  }
  
  const hideModalNewOrder = () => {
    setShowNewOrder(false);
  }

  function setNewTask({target}) {
    setNewItem(target.value);
  }

  function submit(e) {
    e.preventDefault();
    onSubmit(newItem)    
  }

  function addNewTask(task) {
    const itensCopy = Array.from(tasks);    
    itensCopy.push({id: tasks.length, value: task});
    setTasks(itensCopy);
  }

  function updateTask({target}, index) {
    const itensCopy = Array.from(tasks);
    itensCopy.splice(index, 1, { id: index, value: target.value });
    setTasks(itensCopy);
  }

  function deleteTask(index) {
    const itensCopy = Array.from(tasks);
    itensCopy.splice(index, 1);
    setTasks(itensCopy);
  }

  return (
    <>
        <button className="App__table__btn__grey " onClick={(event) => handleClickNewOrder()}>
          Novo Pedido
        </button>
        {showNewOrder ?
        <>
        
        <div className="App__pedido">
                    <div className="App__pedido__container">         
                        <form className="App__pedido__form">
                            <div className="form-group">
                                    <input type="email" 
                                        className="App__pedido__form__input" 
                                        required 
                                        placeholder="CLIENTE" 
                                        id="cliente" 
                                        value={getCliente}
                                        onChange={e => setCliente(e.target.value)}/>
                            </div>  

                                <div className="form-group">
                                    <input type="password" 
                                            className="App__pedido__form__input1" 
                                            placeholder="Valor ICMS" 
                                            required
                                            value={getCliente}
                                            onChange={e => setCliente(e.target.value)}
                                            />
                                      <input type="email" 
                                        className="App__pedido__form__input1" 
                                        required 
                                        placeholder="Alíquota ICMS1" 
                                        id="cliente" 
                                        value={getCliente}
                                        onChange={e => setCliente(e.target.value)}/>       
                                </div>
                                <div className="form-group">
                                  <input type="date" 
                                          className="App__pedido__form__input1" 
                                          placeholder="Data de Faturamento" 
                                          required
                                          value={getCliente}
                                          onChange={e => setCliente(e.target.value)}
                                          />
                              </div>
                                <div className="form-group">
                                    <input type="text" 
                                            className="App__pedido__form__input1" 
                                            placeholder="Valor Total" 
                                            required
                                            value={getCliente}
                                            onChange={e => setCliente(e.target.value)}
                                            />
                                </div>
                                <div>
                                    <input type="text" 
                                            className="App__pedido__form__input1" 
                                            placeholder="IPI" 
                                            required
                                            value={getCliente}
                                            onChange={e => setCliente(e.target.value)}
                                            />
                                </div>
                                <div className="form-group">
                                  <Select className={"App__pedido__form__select"} 

                                  /> 
                              </div>  

                              
                                <div className="form-group">
                                    <input type="email" 
                                        className="App__pedido__form__input" 
                                        required 
                                        placeholder="Preposto" 
                                        id="cliente" 
                                        value={getCliente}
                                        onChange={e => setCliente(e.target.value)}/>
                            </div>  
                            
                            <Button variant="outlined" color="primary" 
                                    onClick={handleClickToOpen} onSubmit={addNewTask}>
                              SELECIONAR PRODUTO
                            </Button>
                            
                            <Dialog open={open} onClose={handleToClose} >
                              <DialogContent style={{width: '90%', height: '90%', padding: '1rem'}}>
                                <DialogContentText>
                                  <div>
                                    <SelectProduct  />
                                  </div>
                                  <div className="form-group">
                                    <input type="email" 
                                        className="App__pedido__form__input2" 
                                        required 
                                        placeholder="Quantidade" 
                                        id="qtd" 
                                        onChange={setNewTask}/>
                                  </div>  
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleToClose}>CANCELAR</Button>
                                <Button type="submit">ADICIONAR</Button>
                              </DialogActions>
                            </Dialog>
                        </form>                          
                    </div>            
                </div>
         
              
               {/* <input type="date" placeholder="Data do Pedido" name="Data" /><br/>
               <input type="text" placeholder="Cidade de Faturamento" name="cidade" /><br />
               <input type="text" name="produto" placeholder="Produto"/><br />
               <input type="text" name="valicms" placeholder="Valor ICMS"/> < br/>
               <input type="text" name="aliqcims" placeholder="Alíquota ICMS"/><br />
               <input type="text" name="ipi" placeholder="IPI"/><br />
               <input type="text" name="qtdve" placeholder="Quantidade"/><br />
               <input type="text" name="cdpaga" placeholder="Condição de pagamento"/><br />
               <input type="text" name="dtfat" placeholder="Data Faturamento" /><br />
               <input type="text" name="vltot" placeholder="Valor total nota"/> <br />
               <input type="text" name="Preposto" placeholder="Preposto"/> */}
              {/* <input type="date" id="datei"
                  defaultValue={""}                      
                  onChange={e => setCliente(e.target.value)}/> */}
            
         </>
         :
         null 
         }
                          <NewTaskInput onSubmit={addNewTask} />
                            {tasks.map(({id, value}, index) => (
                              <ListItem
                                key={id}
                                value={value}
                                onChange={(event) => updateTask(event, index)}
                                onDelete={() => deleteTask(index)}
                              />
                            ))}
    </>
  )
  
}
