import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Container from "../../components/Container";
import { Redirect, useLocation } from "react-router-dom";
import { getNivelUsuario } from "../../services/auth";
import { Input, Input2, Input3 } from "./styles";
import api from '../../services/api'

const Usuarios = () => {
  const currentPath = useLocation();
  const [getUsuarios, setUsuarios] = useState("");
  const token = ''

  if (getNivelUsuario() === "0") {
    return <Redirect to="/" />;
  } else if (getNivelUsuario() === "1") {
    return <Redirect to="/" />;
  } else if (getNivelUsuario() === "2") {
    return <Redirect to="/" />;
  } else if (getNivelUsuario() === "3") {
    return <Redirect to="/" />;
  } else if (getNivelUsuario() === "4") {
    return <Redirect to="/" />;
  } else if (getNivelUsuario() === "5") {
    return <Redirect to="/" />;
  }

  useEffect(() => {
    const getData = async () => {    
      await api.post('/api/empresas/processos/',{token})
          .then(res => {            
            setUsuarios(res.data.result);
      });    			    
  }  
  }, [])

  return (
    <>
      <Header currentPath={currentPath.pathname} />
      <Container>
        <div></div>
        <div className="App__processos__filtro__1">
          <div className="App__processos__fitro">
            <span className="App__processos__filtro__span">Usuário</span>
            <br />
            <Input2
              id="Input1Processo"
              onChange={(e) => setUsuarios(e.target.value)}
            />
          </div>         
        </div>
        <div className="App_processos__resultado">
          {/* <TableProcesso data={dataProcesso} />  */}
        </div>
      </Container>
    </>
  );
};

export default Usuarios;
