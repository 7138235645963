var jszip = require('jszip');
var saveAs = require('file-saver');

function Download(arrayBuffer, type, fileName) {
    let buff =  Buffer.from(arrayBuffer, 'binary');//
    let text = buff;    
    let a = document.createElement("a");
    var blob = new Blob([text], { type: type });   
    var url = URL.createObjectURL(blob);
    
    a.href = url;
    a.download = fileName; // gives it a name via an a tag    
    a.click();
    window.URL.revokeObjectURL(url);        
};

function arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    let a = document.createElement("a");
    let text = binary;   
    var blob = new Blob([text], { type: Buffer });   
    var url = URL.createObjectURL(blob);
    
    a.href = url;
    a.download = "Teste"; // gives it a name via an a tag    
    a.click();
    window.URL.revokeObjectURL(url);       
}

function DownloadHS(url, fileName) {
    let a = document.createElement("a");
    
    a.href = url;
    a.download = "TESTE.pdf"; // gives it a name via an a tag    
    a.click();
    // window.URL.revokeObjectURL(url);           
};

function createGzip(blob, type){

    var zip = new jszip();
    var img = zip.folder("processos");
    img.file(type, blob);
    zip.generateAsync({type:"blob"})
    .then(function(content) {
        saveAs(content, "processo.zip");
    });
}

function newDowload(arrayBuffer, type, fileName) {
    let buff =  Buffer.from(arrayBuffer, 'binary');//
    let text = buff;    
    var blob = new Blob([text], { type: type });   
   return blob;
};

module.exports = {Download, createGzip, newDowload, DownloadHS, arrayBufferToBase64};
