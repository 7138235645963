import styled from "styled-components";

export const Input = styled.input`
    font-family: 'Poppins', sans-serif;
    box-shadow: 0 1px 5px 0 rgb(48 54 77 / 40%);  
    border: 1px solid;
    
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    display: flex;
    /* margin-bottom: .5em; */
    margin-right: .5rem;
    padding: .375rem .75rem;
    font-size: .7rem;      
    line-height: 1.5;
    height: 2.2rem;
    /* width: 6rem; */
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    ::placeholder {
        color: #495057;
    }
`;

export const Button = styled.button`
    font-family: 'Poppins', sans-serif;
    margin-right: .5rem;
    font-weight: 500;
    font-size: .8rem;
    background: #666868;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    width: 6rem;
    border-radius: 5px;
    height: 2.2rem;
    border: none;
    outline: none;
    transition: 0.15s;
    text-align: center;
    
    &:hover {
      opacity: .7;
    }
  `

export const ButtonExcel = styled.button`
    font-family: 'Poppins', sans-serif;
    margin-right: .5rem;
    line-height: 2rem;
    font-weight: 500;
    font-size: .8rem;
    background: #666868;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    width: 6rem;
    border-radius: 5px;
    height: 2.2rem;
    border: none;
    /* display: flex; */
    outline: none;
    transition: 0.15s;
    text-align: center;
    
    &:hover {
      opacity: .7;
    }
  `

export const ExportCsv = styled.div`
font-family: 'Poppins', sans-serif;
margin-left: 1.5rem;
font-weight: 500;
font-size: 1rem;
margin-bottom: .6rem;
color: #666868;
opacity: .7;
cursor: pointer;
display: flex;
justify-content: center;
width: 4rem;
border-radius: 5px;
height: 1.2rem;
border: none;
outline: none;
transition: 0.15s;
text-align: center;
`