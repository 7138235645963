import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select'
import { setStatusProcesso, setIdStatusProcesso } from '../../services/auth';

    const options = [
        {
            "value" : 1,
            "label" : "TODOS"
        },
        { 
            "value" : 2,
            "label" : "ATIVO",
        },
        {
            "value" : 3,
            "label" : "CANCELADOS"
        }]

  export default class SimpleSelect extends Component{
    
    constructor(props){
      super(props)
      this.state = {
        selectOptions: [],
        id: "1",
        name: "TODOS"
      }
      
      this.setState({selectOptions: options})
    }

    handleSelectStatus = (option)=> {
      this.setState({id:option.value, name:option.label});
      setIdStatusProcesso(option.value);
      setStatusProcesso(option.label);      
   } 

    useStyles = makeStyles((theme) => ({
      select: {
        width: "50px",
        margin: 'auto 0',    
        fontSize: '0.65em',
      }
      
    }));

    render(){      
      return (
        
        <Select options={options} className={"App__select__modalidade"} onChange={this.handleSelectStatus} /> 
    );
  }
}  

