import React, { useState, useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar'
import api from '../../services/api';


const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#3D3F44',
      },
    },
  }))(TableRow);
    
    const StyledTableCell = withStyles((theme) => ({
        root: {
            borderBottom: "none"
          },
        head: {
            backgroundColor: "#242628",
            color: "#22b3A7",
            fontSize: 12,
            fontWeight: 'bold',
        },
        body: {
            fontSize: 12,
            fontWeight: 'bold',
            color: '#8F929B'
        }
    }))(TableCell);


export default function  TabAccount ({data, type, filter}) {
    const [datAccount, setDataAccount ] = useState([]);

    useEffect(() => { 
        const getAccounts = async () => {
          const result = await api.post('/api/empresas/accounts/');    
          setDataAccount(result.data.result);          
        }
        getAccounts();
    }, []);

    return(
        <>
            <Table  size='small'>
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell align="center">CODIGO</StyledTableCell>
                        <StyledTableCell align="center">NOME</StyledTableCell>
                        <StyledTableCell align="center">E-MAIL</StyledTableCell>
                        <StyledTableCell align="center">FOTO</StyledTableCell>
                        <StyledTableCell align="center">TELEFONE</StyledTableCell>                                    
                        <StyledTableCell align="center">RAMAL</StyledTableCell>          
                        <StyledTableCell align="center">UNIDADE</StyledTableCell>          
                        <StyledTableCell align="center">AÇÕES</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {datAccount.map((row) => {
                        return (
                            <StyledTableRow>
                                <StyledTableCell align="center">{row.acc_codExt}</StyledTableCell>
                                <StyledTableCell align="center">{row.acc_nome}</StyledTableCell>
                                <StyledTableCell align="center">{row.acc_email}</StyledTableCell>
                                <StyledTableCell align="center">
                                <Avatar src={row.acc_url_foto}/>
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.acc_telefone}</StyledTableCell>      
                                <StyledTableCell align="center">{row.acc_ramal}</StyledTableCell>      
                                <StyledTableCell align="center">{row.acc_unidade}</StyledTableCell>      
                                <StyledTableCell align="center">
                                    <EditOutlinedIcon />    
                                    <RemoveCircleOutlineOutlinedIcon style={{marginLeft: '15px'}}/>
                                </StyledTableCell>                                    
                            </StyledTableRow>    
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );

}