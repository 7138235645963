import React, { Component } from 'react';
import Select from 'react-select'
import api from '../../services/api';
import { setIdModalidade, setNomeModalidade, getNomeModalidade } from '../../services/auth';
// import { Select } from './styles'


  const options = [
  {
      "value" : 0,
      "label" : "TODOS"
  },
  { 
      "value" : 1,
      "label" : "OCEANFREIGHT / FCL",
  },
  {
      "value" : 2,
      "label" : "AIRFREIGHT"
  },
  {
    "value" : 3,
    "label" : "RODOVIÁRIO"
  },
  {
    "value" : 4,
    "label" : "SEA / AIR"
  },
  {
    "value" : 6,
    "label" : "MARÍTIMO / RODOVIÁRIO"
  },
  {
    "value" : 10,
    "label" : "OCEANFREIGHT / LCL"
  }
]

  export default class SimpleSelect extends Component{
    
    constructor(props){
      super(props)
      this.state = {
        selectOptions: [],        
      }
      this.setState({selectOptions: options})
    }
    // async getOptions(){
    //   const res = await api.get('/api/empresas/modalidades/0')
    //   const data = res.data.result
    //   const options = data.map(d => ({
    //     "value" : d.cod_fretemodo,
    //     "label" : d.nome_fretemodo
    //   }))
    //   this.setState({selectOptions: options})
    // }

    

    handleSelectModalidade = (option)=> {
      this.setState({id:option.value, name:option.label});
      setIdModalidade(option.value);
      setNomeModalidade(option.label);
   }

    // componentDidMount(){
    //   this.getOptions()
    // }    

    render(){
      // const {selectOptions} = this.state;      
      return (
        
        <Select options={options} className={"App__select__modalidade"} placeholder={getNomeModalidade()} onChange={this.handleSelectModalidade} /> 
    );
  }
}  

