import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/Container'
import api from '../../services/api';
import styles from './styles.scss';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header'
import TableSeguro  from '../../components/Table/TableSeguro';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import logo from "../../assets/imgs/logo.png";
import { getNomeUsuario, getNivelUsuario } from '../../services/auth';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Redirect } from "react-router-dom";

var jszip = require('jszip');
var saveAs = require('file-saver');

const useStyles = makeStyles((theme) => ({
    root: {  
      background: "#242628",
      fontFamily: 'GHOTIC',
      color: '#696C72',
      alignItems: 'center',
      verticalAlign: 'middle',
      border: 'none',      
      fontWeight: 'bold',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    container: {
      display: 'flex',
      marginLeft: '10px',
      marginTop: '10px',
      height: '35px',
      backgroundColor: "#242628",
    },
    indicator: {
      backgroundColor: '#858585',
      height: '3px'
    },

  }));

  function TabPanel(props) {
    
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }



function dataInicialFormatada() {
    var dia, diaF, mes, mesF, dataFinal;
    var data = new Date(new Date().setDate(new Date().getDate() - 30));
    dia  = data.getDate().toString();
    diaF = (dia.length === 1) ? '0'+dia : dia;
    mes  = (data.getMonth()+1).toString(); //+1 pois no getMonth Janeiro começa com zero.
    mesF = (mes.length === 1) ? '0'+mes : mes;
    dataFinal =data.getFullYear()+"-"+mesF+"-"+dia;
    return dataFinal;   
  }

  function dataAtualFormatada(){
    var data = new Date(),
        dia  = data.getDate().toString(),
        diaF = (dia.length === 1) ? '0'+dia : dia,
        mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length === 1) ? '0'+mes : mes,
        anoF = data.getFullYear();
    return anoF+"-"+mesF+"-"+diaF;   
  }
  const valueDataI = dataInicialFormatada();
  const valueDataF = dataAtualFormatada();

export default function Seguro(){
    const currentPath = useLocation();
    const  classes = useStyles();
    const [qualSeguro, setQualSeguro] = React.useState(0);
    const [value, setValue] = React.useState(0);
    const [valueDataIni, setValueDataIni] = useState('');
    const [valueDataFim, setValueDataFim] = useState('');   
    const [getData, setData] = React.useState([]);
    const [getNameZip, setNameZip] = React.useState('');
    const [getFileName, setFileName ] = useState('');

    const handleChangeSeguro = (event, newValue) => {
      setQualSeguro(newValue);
    };


    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    if (!getNomeUsuario()){
        return <Redirect to='/'  />
    }
    if (getNivelUsuario() === 0){
      return <Redirect to='/'  />
    }

    if ((getNivelUsuario() === 3)){
      return <Redirect to='/'  />
    }

    const GetSeguros = async () => {
        
        let dI = '';
        let dF = '';
        valueDataIni === '' ? dI = dataInicialFormatada() : dI = valueDataIni; 
        valueDataFim === '' ? dF = dataAtualFormatada() : dF = valueDataFim;
        let filtro = ""
        filtro = ` where exists (select e3.EmpresaId from empresarequisito e3 where e3.Requisito = 'Contrata Seguro Internacional Aplicável' and e3.EmpresaId = p.FaturarEmpresaId) and p.tipo_pro ='Importação' and  p2.ordem = 1 and (p.dt_reg_di_pro between '${dI}' and '${dF}')`;           
        const getData = async () => {    
            await api.post('/api/empresas/seguro/',{filtro})
                .then(res => {            
                    setData(res.data.result);
            });    	
            setValue(0);		    
        }  
        getData();
        
      }

    function atualizar(){
        GetSeguros();
    }

    return (
        <>            
        <Header currentPath={currentPath.pathname}/> 
        <Container>
           <div className="App__homepage">
                {/* <div > */}
                {/* <img className="App__header__logo" width={150} src={logo} alt="" />
                <div className="App__header">
                    
                </div> */}
                {/* </div> */}
                <div className="App__conteudo">
                    <div className="App__conteudo__filtro">
                    
                    <span className="App__container__box__c__filtro__marcador__span">PERÍODO</span>
                    <form className={classes.container} noValidate>
                    <input type="date" id="datei" className={classes.root}
                        defaultValue={valueDataI}                      
                        onChange={e => setValueDataIni(e.target.value)}/>
                    </form>
                    <span className="App__container__box__c__filtro__marcador__span">A</span>
                    <form className={classes.container} noValidate>
                    <input type="date" id="datef" className={classes.root}
                        defaultValue={valueDataF}                      
                        onChange={e => setValueDataFim(e.target.value)}/>
                    </form>
                    <button type="submit" 
                            className="App__container__box__c__filtro__btn"
                            onClick={atualizar}                      
                            >CONSULTAR</button>  
                    </div>
                    
                </div>
                <div>
                <Tabs value={qualSeguro} onChange={handleChangeSeguro} classes={{indicator: classes.indicator}} centered>
                  <Tab label={"Seguro Bregalda - " + getData.filter(seguros => seguros.SEGURADORA === 'BREGALDA').length} {...a11yProps(0)} inkBarStyle={{background: 'red'}}  style={{color: '#8F929B'}}/>
                  <Tab label={"Seguro Gescorp - " + getData.filter(seguros => seguros.SEGURADORA === 'GESCORP').length} {...a11yProps(1)} inkBarStyle={{background: 'red'}} style={{color: '#8F929B'}}/>                  
                </Tabs>  
                {/*Seguro Bregalda*/}
                <TabPanel value={qualSeguro} index={0}> 
                  <Tabs value={value} onChange={handleChange} classes={{indicator: classes.indicator}} centered>
                    <Tab label="Seguro Aplicável" {...a11yProps(0)} inkBarStyle={{background: 'red'}}  style={{color: '#8F929B'}}/>
                    <Tab label="Seguro Não Aplicável" {...a11yProps(1)} inkBarStyle={{background: 'red'}} style={{color: '#8F929B'}}/>
                    <Tab label="Verificar Processo" {...a11yProps(2)} inkBarStyle={{background: 'red'}} style={{color: '#8F929B'}}/>
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <div className="App__imp__paper">Seguro Aplicável</div>
                    <TableSeguro data={getData.filter(seguros => seguros.SEGURADORA === 'BREGALDA')} type={0}/>
                  </TabPanel>
                  <TabPanel className={classes.appThemePanel} value={value} index={1}>
                      <div className="App__imp__paper">Seguro Não Aplicável</div>
                      <TableSeguro data={getData.filter(seguros => seguros.SEGURADORA === 'BREGALDA')} type={1}/>
                  </TabPanel>
                  <TabPanel className={classes.appThemePanel} value={value} index={2}>
                      <div className="App__imp__paper">Verificar Processo</div>
                      <TableSeguro data={getData.filter(seguros => seguros.SEGURADORA === 'BREGALDA')} type={2}/>
                  </TabPanel>
                </TabPanel>
                 {/*Seguro Gescorp*/}
                <TabPanel value={qualSeguro} index={1}> 
                  <Tabs value={value} onChange={handleChange} classes={{indicator: classes.indicator}} centered>
                    <Tab label="Seguro Aplicável" {...a11yProps(0)} inkBarStyle={{background: 'red'}}  style={{color: '#8F929B'}}/>
                    <Tab label="Seguro Não Aplicável" {...a11yProps(1)} inkBarStyle={{background: 'red'}} style={{color: '#8F929B'}}/>
                    <Tab label="Verificar Processo" {...a11yProps(2)} inkBarStyle={{background: 'red'}} style={{color: '#8F929B'}}/>
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <div className="App__imp__paper">Seguro Aplicável</div>
                    <TableSeguro data={getData.filter(seguros => seguros.SEGURADORA === 'GESCORP')} type={0}/>
                  </TabPanel>
                  <TabPanel className={classes.appThemePanel} value={value} index={1}>
                      <div className="App__imp__paper">Seguro Não Aplicável</div>
                      <TableSeguro data={getData.filter(seguros => seguros.SEGURADORA === 'GESCORP')} type={1}/>
                  </TabPanel>
                  <TabPanel className={classes.appThemePanel} value={value} index={2}>
                      <div className="App__imp__paper">Verificar Processo</div>
                      <TableSeguro data={getData.filter(seguros => seguros.SEGURADORA === 'GESCORP')} type={2}/>
                  </TabPanel>
                </TabPanel>
                </div>
            </div>
          </Container>     
        </>
    );
}

