import React, { useEffect, useState } from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles} from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList'
import Modal from '../../components/Modal/ModalFollow'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import api from '../../services/api'
import Container from '../../components/Container'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Kanban from '../../components/Kanban'
import { getIdEmpresa, getNomeUsuario, getNivelUsuario } from '../../services/auth';
import Follow from '../../components/FollowOut'
import { Redirect } from "react-router-dom";
import CustosProcesso from '../../components/CustosProcesso2'
import CustosItens from '../../components/CustosItens'
import Files from '../../components/Files'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

  function TabPanel(props) {
    
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  const useStyles = makeStyles((theme) => ({
    indicator: {
      backgroundColor: '#858585',
      height: '3px'
    },
    tablePagination: {
    },
  tablePaginationCaption: {
      color:  "#858585",
      fontWeight: 'bold',
      fontSize: '0.9rem'
    },
  tablePaginationSelectIcon: {
      color:  "#858585",
      fontWeight: 'bold',
    },
  tablePaginationSelect: {
      color:  "#858585",
      fontWeight: 'bold',
    },
  tablePaginationActions: {
      color:  "#858585",
      fontWeight: 'bold',
    },
    indicator: {
      backgroundColor: '#858585',
      height: '3px'
    },
    radio: {
      '&$checked': {
        color: '#8F929B'
      }
    },
    checked: {}
  }));

  const headCells =[
    {
      id: 'processo',
      numeric: true,
      disablePadding: false,
      label: 'Processo',
    },
    {
      id: 'fatura',
      numeric: true,
      disablePadding: false,
      label: 'Fatura',
    },
    {
      id: 'operacao',
      numeric: true,
      disablePadding: false,
      label: 'Operacao',
    },
    {
      id: 'referencia',
      numeric: true,
      disablePadding: false,
      label: 'Referência Cliente',
    }
  ]

  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
     
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" >
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Nutrition
          </Typography>
        )}
  
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  };
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const StyledTableRow = withStyles(() => ({
    root: {      
     '&:nth-of-type(odd)': {
       backgroundColor: '#2c2c2c',
     },
   },
 }))(TableRow);

 const CssRadio = withStyles({
  colorSecondary: {
      color: '#FFFFFF',
      '&$checked': {
          color: 'hotpink',
        },
  },
  checked: {}
})(RadioGroup)

 const StyledTableCell = withStyles(() => ({
  root: {
      borderBottom: "none"
    },
  head: {
      backgroundColor: "#2c2c2c",
      color: "#858585",
      fontSize: 12,
      fontWeight: 'bold',
  },
  body: {
      fontSize: 12,
      fontWeight: 'bold',
      color: '#8F929B'
  }
}))(TableCell);

const Outsourcing = () =>  {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [getData, setData] = useState([]);
  const [ showLista, setShowLista ] = useState(true);
  const [ showKanban, setShowKanban ] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [dataProcesso, setDataProcesso] = useState([]);
  const [dataTracking, setDataTracking] = useState([]);
  const [dataFollowUp, setDataFollowUp] = useState([]);
  const [getStateAnexo, setStateAnexo] = React.useState(false);  
  const [loadingFile, setLoadingFile ] = useState(false);
  const [loadingFollowUp, setLoadingFollowUp ] = useState(false);
  const [loadingCustos, setLoadingCustos ] = useState(false);
  const [dataFile, setDataFile] = useState([]);
  const [dataCusto, setDataCusto] = useState([]);
  const [dataCustoTotal, setDataCustoTotal] = useState([]);
  const [dataCustoItens, setDataCustoItens] = useState([]);  
  const [dataCustoSubItens, setDataCustoSubItens] = useState([]);   
  const [totalGeralCustos, setTotalGeralCustos] = React.useState('');
  const [totalGeralRecebido, setTotalGeralRecebido] = React.useState(''); 
  const [existeProcesso, setExisteProcesso ] = React.useState(false);    
  const [existeFinanceiro, setExisteFinanceiro ] = React.useState(false);    
  const [getNameZip, setNameZip] = React.useState('');
  const [getStatus, setStatus] = React.useState('2');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {    
    const GetData = async () => {    
      if(getIdEmpresa() > 0 ){
        const result = await api.get('/api/empresas/o/'+getIdEmpresa());            
        setData(result.data.result);         
        }  
      }
    
    GetData();    
  }, []);   

  if (!getNomeUsuario()){
    return <Redirect to='/'  />
  }

  if (getNivelUsuario() > 0){
    return <Redirect to='/seguro'  />
  }

  const handleShowLista = () => {
    setShowLista(true);    
    setShowKanban(false);
  }

  const handleShowKanban = () => {
    setShowLista(false);
    setShowKanban(true);
  }

  const hideModalDetails = () => {
    setShowDetails(false);
  }

  const handleClickOpenDetails = (event, idProcesso, processo, ano) => {
    const GetData = async () => {    
      setLoadingFollowUp(true);      
      const result = await api.get('/api/empresas/f/'+processo+"&"+ano);  
      setDataProcesso(result.data.cabecalho);
      setDataTracking(result.data.tracking);
      setDataFollowUp(result.data.followup);          
      setLoadingFollowUp(false);
    }  

    const GetDataCusto = async () => {    
      api.get('/api/empresas/custosp/'+processo+"&"+ano)
        .then(response => {      
        setDataCusto(response.data.result);
        setDataCustoTotal(response.data.totais)
        if(response.data.totais.length > 0){
          setTotalGeralCustos(response.data.totalCusto[0].total);
          setTotalGeralRecebido(response.data.totalRecebido[0].total);
        }
        });    			    
    }  

    const GetDataCustoItens = async () => {    
      api.get('/api/empresas/custosi/'+processo+"&"+ano)
        .then(response => {      
        
        setDataCustoItens(response.data.result);          
        setDataCustoSubItens(response.data.sub);      
        });    			    
    }  

      setNameZip("Processo_"+processo+ano);
      setLoadingFile(true);
      const GetDataFiles = async () => {            
      const result = await api.get('/api/empresas/arquivos/'+idProcesso);    
      setDataFile(result.data.result);          
      setLoadingFile(false);
      
      result.data.result.forEach(element => {
        if (element['Folder'] !== "Financeiro"){
          setExisteProcesso(true);
        }
        if (element['Folder'] === "Financeiro"){
          setExisteFinanceiro(true);
        }
      });

      }  
      
    
    GetData();   
    GetDataCusto();
    GetDataCustoItens();
    GetDataFiles();
    setShowDetails(true);
  }

  const setFilter = (event) => {
    setStatus(event.target.value);
  }

  const emptyRows =
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getData.length) : 0;
  return (
    <>    
      <Header />
      <br /><br />
      {/* <div>
          <button type="button" className="App__btn" onClick={handleShowLista} >Visualização: Lista</button>
          <button type="button" className="App__btn" onClick={handleShowKanban}>Visualização: Kanban</button>
        </div> */}        
        {showLista ? 
        <>
        <div className="App__imp__paper2" >
          
            <span>DESENVOLVIMENTO DE PRODUTO</span>
          <div style={{float: 'right', marginTop:'-25px'}}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label" style={{color: "#858585",
      fontSize: 14,
      fontWeight: 'bold'}}>Status</FormLabel>
              <CssRadio
                row
                name="row-radio-buttons-group"
                value={getStatus}
                onChange={setFilter}               
              >
                <FormControlLabel value="1" control={<Radio />} label="Todos" style={{color: "#858585",
      fontSize: 14,
      fontWeight: 'bold'}}/>
                <FormControlLabel value="2" control={<Radio />} label="Aberto" style={{color: "#858585",
      fontSize: 14,
      fontWeight: 'bold',}}/>                
                <FormControlLabel value="3" control={<Radio />} label="Encerrado" style={{color: "#858585",
      fontSize: 14,
      fontWeight: 'bold'}}/>                
              </CssRadio>
            </FormControl>
            
          </div>
        </div>
        
          <TableContainer style={{width: '90%', margin: '0 auto'}}>
                <Table aria-labelledby="tableTitle" size='small' aria-label="enhanced table" border='0'>
                    <TableHead> 
                        <TableRow>
                            <StyledTableCell align="center" style={{whiteSpace: 'nowrap'}}>PROCESSO</StyledTableCell>
                            <StyledTableCell align="center" style={{whiteSpace: 'nowrap'}}>FATURA</StyledTableCell>
                            <StyledTableCell align="center" style={{whiteSpace: 'nowrap'}}>REFERÊNCIA DO CLIENTE</StyledTableCell>
                            <StyledTableCell align="center" style={{whiteSpace: 'nowrap'}}>STATUS</StyledTableCell>
                            <StyledTableCell align="center" style={{whiteSpace: 'nowrap'}}></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stableSort(getData, getComparator(order, orderBy)) 
                        .filter(getStatus === '2' ? orders => orders.dt_enc_pro === 'aberto' :
                                getStatus === '3' ? orders => orders.dt_enc_pro !== 'aberto' :
                                orders => orders.dt_enc_pro == 'aberto' || orders.dt_enc_pro !== 'aberto')                       
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {                              
                            const labelId = `enhanced-table-checkbox-${index}`;                    
                            return (  
                                <StyledTableRow   
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.processoid}
                                >  
                                <StyledTableCell align="center" id={labelId} style={{width: '2%'}}>
                                    {row.nro_pro}/{row.ano_pro}
                                </StyledTableCell>  
                                <StyledTableCell align="center" style={{whiteSpace: 'nowrap', width: '20%'}}>{row.fatura}</StyledTableCell>  
                                <StyledTableCell align="center" style={{whiteSpace: 'normal', width: '1px'}}>{row.ident_cli_pro}</StyledTableCell>     
                                <StyledTableCell align="center" style={{whiteSpace: 'normal', width: '1px'}}>{row.dt_enc_pro === 'aberto' ? 'Aberto' : 'Encerrado'}</StyledTableCell>                                     
                                <StyledTableCell align="center" style={{whiteSpace: 'nowrap', width: '2%'}}>
                                    <button className="App__table__btn__grey " onClick={(event) => handleClickOpenDetails(event, row.processoid, row.nro_pro, row.ano_pro)}>
                                      Detalhes
                                    </button>
                                </StyledTableCell>                                              
                            </StyledTableRow>  
                                );  
                            })}  
                    </TableBody>             
                    <Modal show={showDetails} title="Detalhes" handleClose={hideModalDetails}>
                      <div style={{margin: '0 auto'}}>
                      <Tabs value={value} onChange={handleChange} classes={{indicator: classes.indicator}} centered>
                        <Tab label="Follow-up" {...a11yProps(1)} inkBarStyle={{background: 'red'}}  style={{color: '#8F929B'}}/>
                        <Tab label="Arquivos" {...a11yProps(1)} inkBarStyle={{background: 'red'}} style={{color: '#8F929B'}}/>                      
                      </Tabs>
                      <TabPanel  value={value} index={0}>
                      <div style={{margin: '0 auto'}}>
                        {loadingFollowUp?<CircularProgress style={{'color': 'white'}}/> : ""}
                        <Follow dataProcesso={dataProcesso} dataTracking={dataTracking} dataFollowUp={dataFollowUp}/> 
                        </div>  
                      </TabPanel>
                      <TabPanel value={value} index={3}>
                        <Files dataFile={dataFile} existeProcesso={existeProcesso} existeFinanceiro={existeFinanceiro} />
                      </TabPanel>
                      </div>
                    </Modal>
                 </Table>
            </TableContainer>
            <TablePagination  
                rowsPerPageOptions={[15, 25, 35]}  
                component="div"  
                count={getData.filter(getStatus === '2' ? orders => orders.dt_enc_pro === 'aberto' :
                getStatus === '3' ? orders => orders.dt_enc_pro !== 'aberto' :
                orders => orders.dt_enc_pro == 'aberto' || orders.dt_enc_pro !== 'aberto').length}  
                rowsPerPage={rowsPerPage}  
                page={page} 
                labelRowsPerPage={"REGISTROS POR PÁGINA"}
                classes={{
                    root: classes.tablePagination,
                    caption: classes.tablePaginationCaption,
                    selectIcon: classes.tablePaginationSelectIcon,
                    select: classes.tablePaginationSelect,
                    actions: classes.tablePaginationActions,
                  }}
                onChangePage={handleChangePage}  
                onChangeRowsPerPage={handleChangeRowsPerPage}  
            />
           </>
            : ""}
          {showKanban ? 
          <>
          <br />
          <Container>
            <Kanban data={getData}/>
          </Container>
          </>
          : ""
        } 
    </>
  )
}

export default Outsourcing;