import React, { useState } from "react";
import logo from "../../assets/imgs/logo.png";
import logo2 from "../../assets/imgs/3SCORP_LOGO TAG LINE 2_1.png";
import apple from "../../assets/imgs/apple.svg";
import google from "../../assets/imgs/google.png";
import api from "../../services/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  setIdEmpresa,
  setNomeEmpresa,
  setNomeUsuario,
  login,
  setIdUsuario,
  setEmailUsuario,
  setNivelUsuario,
} from "../../services/auth";
import Alert from "@material-ui/lab/Alert";

export default function Login() {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatusBase] = React.useState("");

  async function handleSubmit() {
    try {
      try {
        await api
          .post("/api/empresas/newLogin", { email, senha })
          .then((res) => {
            // console.log("statis - " + res.status)
            if (res.status === 200) {
              if (res.data.status === 1) {
                if (res.data.repres === 0) {
                  login(res.data.token);
                  setIdUsuario(res.data.id);
                  setNomeUsuario(res.data.nome);
                  setEmailUsuario(res.data.email);
                  setIdEmpresa(0);
                  setNomeEmpresa("");
                  setLoading(false);
                  setNivelUsuario(res.data.nivel);
                  // console.log("NIVEL - " + res.data.nivel)
                  if (res.data.nivel === 0) {
                    window.location.href = "/home";
                  } else if (res.data.nivel === 2) {
                    window.location.href = "/seguro";
                  } else if (res.data.nivel === 1 || res.data.nivel === 3) {
                    window.location.href = "/CPagar";
                  } else if (res.data.nivel === 4) {
                    window.location.href = "/demonstrativowinning";
                  } else if (res.data.nivel === 5) {
                    window.location.href = "/demonstrativoimp";
                  } else if (res.data.nivel === 6) {
                    window.location.href = "/usuarios";
                  }
                } else if (res.data.repres === 1) {
                  window.location.href = "/pedidos";
                }
              } else {
                setStatusBase({ msg: res.data.msg });
                setLoading(false);
                setTimeout(() => {
                  window.location.href = "/";
                }, 3000);
              }
            } else {
              setStatusBase({ msg: res.data.msg });
              setLoading(false);
              setTimeout(() => {
                window.location.href = "/";
              }, 3000);
            }
          });
      } catch (error) {
        setStatusBase({ msg: error });
        setLoading(false);
        setTimeout(() => {
          window.location.href = "/";
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
      setTimeout(() => {
        window.location.href = "/";
      }, 3000);
    }
  }
  async function loadSubmit() {
    setIdUsuario(0);
    setNomeUsuario("");
    setEmailUsuario("");
    setIdEmpresa(0);
    setNomeEmpresa("");

    if (email !== "" && senha !== "") {
      setLoading(true);
      setTimeout(() => handleSubmit(), 1000);
    }
  }

  const handleClick = () => {
    window.open(
      "https://leader.web.headcargo.com.br/",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <>
      <div className="App__login">
        <div className="App__login__container">
          <form className="App__login__form">
            <div className="form-group">
              <img src={logo2} alt="Logo" className="App__login__logo" />
              <input
                type="email"
                className="App__login__form__input"
                required
                placeholder="E-MAIL"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {status ? (
                <Alert severity="error" key={status.key}>
                  {status.msg}
                </Alert>
              ) : null}
            </div>

            <div className="form-group">
              <input
                type="password"
                className="App__login__form__input"
                placeholder="SENHA"
                required
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
              />
            </div>

            <button
              type="button"
              className="App__login__btn"
              onClick={loadSubmit}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress style={{ color: "white" }} />
              ) : (
                "ENTRAR"
              )}
            </button>
          </form>
          <br />
          {/* <div style={{ width: "100%", height: "80", margin: "1rem 5rem" }}> */}
          <button
            type="button"
            className="App__login__btn"
            onClick={handleClick}
            style={{ cursor: "pointer" }}
          >
            ACESSAR AGENCIAMENTO
          </button>
          {/* <a
              href="https://apps.apple.com/br/app/3s-corp/id1575904036?itsct=apps_box_badge&amp;itscg=30200"
              target="_blank"
              rel="noreferrer"
            >
              <img src={apple} alt="Download on the App Store" height="50" />
            </a> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
