import React, { useState, useEffect } from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AttachFile from '@material-ui/icons/AttachFile';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../services/api';
import Modal from '../Modal'
import ModalFollow from '../Modal/ModalFollow'
import { Download, newDowload } from '../../Helpers/format';
import CustoItens from '../Modal/CustoItens';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import exportFromJSON from 'export-from-json'  
import { setNrProcesso, setAnoPro } from '../../services/auth';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ReactExport  from 'react-data-export'
var jszip = require('jszip');
var saveAs = require('file-saver');

  const StyledTableRowModal = withStyles(() => ({
    root: {
      border: 'none',
      
      '&:nth-of-type(odd)': {
        backgroundColor: '#242628',
        border: 'none',
      },
    },
  }))(TableRow);
  
  const StyledTableRow = withStyles(() => ({
    root: {      
    '&:nth-of-type(odd)': {
    backgroundColor: '#2c2c2c',
    },
},
}))(TableRow);

const StyledTableCell = withStyles(() => ({
    root: {
        borderBottom: "none"
    },
    head: {
        backgroundColor: "#2c2c2c",
        color: "#858585",
        fontSize: 12,
        fontWeight: 'bold',
    },
    body: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#8F929B'
    }
}))(TableCell);
   

    const useStyles = makeStyles(() => ({
      root: {
        backgroundColor: "#2c2c2c",
        color: "#22b3A7",
        
      },
      tableTr:{
        fontSize: '0.8rem',
        fontWeight: 'bold',
        backgroundColor: '#232323', 
        color: "#858585",   
      },
      tableTdL:{
        width: '250px',
        textAlign: 'left',
      },
      tableTdR:{
        textAlign: 'right'
      },
      tableTdRW:{
        textAlign: 'right',
        fontWeight: 'bold',
      },
    tablePagination: {
      },
    tablePaginationCaption: {
        color:  "#858585",
        fontWeight: 'bold',
        fontSize: '0.9rem'
      },
    tablePaginationSelectIcon: {
        color:  "#858585",
        fontWeight: 'bold',
      },
    tablePaginationSelect: {
        color:  "#858585",
        fontWeight: 'bold',
      },
    tablePaginationActions: {
        color:  "#858585",
        fontWeight: 'bold',
      },
      indicator: {
        backgroundColor: '#858585',
        height: '3px'
      },
    }));

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }
    
      function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const FileDownloadBlob = async(processo) => {   
      const result = await api.get('/api/empresas/fileSeguro/'+processo,);      
      Download(result.data.result[0].File.data, 'application/'+
               result.data.result[0].FileName.substring(result.data.result[0].FileName.indexOf(".") + 1),
               result.data.result[0].FileName);
    }

    function EnhancedTableHead(props) {
      const { classes, order, orderBy, onRequestSort } = props;
      const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
      };
    
      return (
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <StyledTableCell
                key={headCell.id}
                align={headCell.headerAlign}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
                style={{ maxWidth: 250, minWidth: headCell.minWidth, whiteSpace: 'nowrap'}}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? '' : ''}
                    </span>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
      );
    }
    
  
    function formatSizeUnits(bytes){
        if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
        else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
        else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
        else if (bytes > 1)           { bytes = bytes + " bytes"; }
        else if (bytes === 1)          { bytes = bytes + " byte"; }
        else                          { bytes = "0 bytes"; }
        return bytes;
    }

    function TabPanel(props) {
    
      const { children, value, index, ...other } = props;
  
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <Typography component={'span'}>{children}</Typography>
            </Box>
          )}
        </div>
      );
    }
  
    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    };

    const headCells = [
      { id: 'Número', numeric: false, disablePadding: false, minWidth:150, label: 'CLIENTE',headerClassName: 'super-app-theme--header', headerAlign: 'center'  },
      { id: 'processo', numeric: false, disablePadding: false,minWidth: 80, label: 'PROCESSO',headerClassName: 'super-app-theme--header', headerAlign: 'center'  },      
    ];

export default function TableEmbarque ({data, type}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [getTitle, setTitle] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [loadingFile, setLoadingFile ] = useState(false);
  const [getStateAnexo, setStateAnexo] = React.useState(false);  
  const [dataFile, setDataFile] = useState([]);
  const [dataProcesso, setDataProcesso] = useState([]);
  const [dataDownload, setDataDownload] = useState([]);
  const [existeProcesso, setExisteProcesso ] = React.useState(false);    
  const [existeFinanceiro, setExisteFinanceiro ] = React.useState(false);    
  const [getNameZip, setNameZip] = React.useState('');
  const [getDataFiltro, setDataFiltro] =  useState([]);
  const [getDataFiltro2, setDataFiltro2] =  useState([]);
  const [getFileName, setFileName ] = useState('');
  const [ isVisible, setIsVisible] = useState(false);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const fileName = 'seguro'
  const exportType = 'xls'  
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  setNrProcesso('');
  setAnoPro('');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  useEffect(() => {    
    setDataFiltro(data.filter(seguros => seguros.Aplicavel === 1 && seguros.NaoAplicavel === 0));
    if (type === 0){
      setDataFiltro(data.filter(seguros => seguros.Aplicavel === 1 && seguros.NaoAplicavel === 0));
    }
    if (type === 1){
      setDataFiltro(data.filter(seguros => seguros.NaoAplicavel === 1 && seguros.Aplicavel === 0));
    }
    if (type === 2){
      setDataFiltro(data.filter(seguros => seguros.Aplicavel === 0 && seguros.NaoAplicavel === 0));    
    }
  }, []);   

  const FileDownloadFilesBlob = async(dataFile, qual) => {
    setIsVisible(true);
    var zip = new jszip();      
        dataFile.forEach(async (element) => {            
          if(element['di_pro'] != null) {         
            const blob = getAll(element['processoid']);
                var img = zip.folder();       
                img.file('DI - ' + element['DI']+'.xml', blob);
          }
        });
        zip.generateAsync({type:"blob"})
                .then(function(content) {
                    saveAs(content, 'DI'+".zip");
                    setIsVisible(false);
                });
                
  }

  const getAll = async (id) => {
    const result  = await api.get('/api/empresas/fileSeguro/'+id);    
    var blob = newDowload(result.data.result[0].File.data, 'application/'+
    result.data.result[0].FileName.substring(result.data.result[0].FileName.indexOf(".") + 1),
    result.data.result[0].FileName);
    
    setFileName(result.data.result[0].FileName);
    
    return blob;
  }

    const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      setSelected(newSelected);
    };
  
    const handleClickOpenFile = (event, idProcesso, processo, ano) => { 
      setNameZip("Processo_"+processo+ano);
      setLoadingFile(true);
      const GetDataFiles = async () => {    
      const result = await api.get('/api/empresas/arquivos/'+idProcesso);    
      setDataFile(result.data.result);          
      setLoadingFile(false);
      
      result.data.result.forEach(element => {
        if (element['Folder'] !== "Financeiro"){
          setExisteProcesso(true);
        }
        if (element['Folder'] === "Financeiro"){
          setExisteFinanceiro(true);
        }
      });

      }  
      GetDataFiles();
      setTitle("Processo: " + processo + "/" + ano);
      setStateAnexo(true);
    };

 
      const handleChangePage = (event, newPage) => {  
        setPage(newPage);  
      };  
    
      const handleChangeRowsPerPage = event => {  
        setRowsPerPage(+event.target.value);  
        setPage(0);  
      };  
    
      const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };

  
    const hideModalAnexo = () => {
      setStateAnexo(false);
    }   

    const handleExportCSV = () =>{         
      exportFromJSON({ data, fileName, exportType })  
    }


      return (            
        <>
             <TableContainer>            
                <Table aria-labelledby="tableTitle" size='small' aria-label="enhanced table">  
                <EnhancedTableHead
                    classes={classes}                    
                    order={order}
                    orderBy={orderBy}
                    // onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={data.length}
                   />
                    <TableBody>
                        {stableSort(getDataFiltro, getComparator(order, orderBy))                                                                    
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {  
                            const isItemSelected = isSelected(row.nro_pro);
                            const labelId = `enhanced-table-checkbox-${index}`;                    
                            return (  
                                <StyledTableRow   
                                hover
                                onClick={(event) => handleClick(event, row.nro_pro)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.Numero} 
                                selected={isItemSelected}
                                >   
                                <StyledTableCell align="center" style={{}}>{row.processo}</StyledTableCell>  
                            </StyledTableRow>  
                                );  
                            })}  
                    </TableBody>

                
                {/*Arquivos*/}
                <Modal show={getStateAnexo} title={getTitle} handleClose={hideModalAnexo}>
                  <div style={{margin: '0 auto'}}>
                    {loadingFile?<CircularProgress style={{'color': 'white'}}/> : ""}
                   </div>
                    <button onClick={(event) => FileDownloadFilesBlob(dataFile, "Todos",)} className="App__table__btn__green" style={{cursor: 'pointer'}} >
                    <div style={{height: '20px'}}> 
                      <span>Baixar Todos(Processo / Financeiro)</span>&nbsp;<CloudDownloadOutlinedIcon align="bottom" style={{height:'20px', verticalAlign:'middle'}}/>
                    </div>
                    </button>
                    <Tabs value={value} onChange={handleChange} classes={{indicator: classes.indicator}} centered align="center">
                      {existeProcesso ? <Tab label="PROCESSO" {...a11yProps(1)}   style={{color: '#8F929B'}}/> : ""}
                      {existeFinanceiro ? <Tab label="FINANCEIRO" {...a11yProps(1)}  style={{color: '#8F929B'}}/> :""}                    
                    </Tabs>
                    <TabPanel  value={value} index={0}>
                      <TableContainer >
                      <button onClick={(event) => FileDownloadFilesBlob(dataFile, "Processo")} className="App__table__btn__green" style={{height:'25px', position:'relative', cursor: 'pointer'}}>
                        <div style={{height: '20px'}}> 
                          <span style={{height:'40px' }}>Baixar Todos(Processo)</span>&nbsp;<CloudDownloadOutlinedIcon align="bottom" style={{height:'20px', verticalAlign:'middle',}} />
                        </div>
                      </button>
                              <Table  size="small" aria-labelledby="tableTitle" aria-label="enhanced table">
                                  <TableHead order={order}
                            orderBy={orderBy}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}>
                                      <StyledTableRow >
                                          <StyledTableCell style={{width: '70%', height: 'auto'}}>NOME</StyledTableCell>  
                                          <StyledTableCell style={{width: '20%'}}>TAMANHO</StyledTableCell>
                                          <StyledTableCell style={{width: '10%'}}>DATA</StyledTableCell>
                                      </StyledTableRow>
                                  </TableHead>
                                  <TableBody>
                                  {dataFile.filter(arqs => arqs.Folder !== "Financeiro").map((row, index) => {  
                                      return (  
                                        <StyledTableRowModal   
                                        hover
                                        className={classes.row}   
                                        key={row.iFileSystemID}                                 
                                        >  
                                          <StyledTableCell style={{width: '70%', height: 'auto'}} onClick={(event) => FileDownloadBlob(row.iFileSystemID)}>{row.FileName}</StyledTableCell>  
                                          <StyledTableCell style={{width: '20%'}}>{formatSizeUnits(row.FileSize)}</StyledTableCell>  
                                          <StyledTableCell style={{width: '10%'}}>{row.data}</StyledTableCell>  
                                      </StyledTableRowModal>  
                                      );  
                                    })}                    
                                  </TableBody>                  
                              </Table>
                      </TableContainer>  
                    </TabPanel>
                    <TabPanel  value={value} index={1}>
                      <TableContainer >
                        <button visible='false' onClick={(event) => FileDownloadFilesBlob(dataFile, "Financeiro")} className="App__table__btn__green" style={{height:'25px', position:'relative', cursor: 'pointer'}}>
                          <div style={{height: '20px'}}>
                            <span style={{height:'40px' }}>Baixar Todos(Financeiro)</span>&nbsp;<CloudDownloadOutlinedIcon align="bottom" style={{height:'20px', verticalAlign:'middle', }} />
                          </div>
                        </button>
                              <Table  size="small" aria-labelledby="tableTitle" aria-label="enhanced table">
                                  <TableHead order={order}
                            orderBy={orderBy}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}>
                                      <StyledTableRow >
                                          <StyledTableCell style={{width: '70%', height: 'auto'}}>NOME</StyledTableCell>  
                                          <StyledTableCell style={{width: '20%'}}>TAMANHO</StyledTableCell>
                                          <StyledTableCell style={{width: '10%'}}>DATA</StyledTableCell>
                                      </StyledTableRow>
                                  </TableHead>
                                  <TableBody>
                                  {dataFile.filter(arqs => arqs.Folder === "Financeiro").map((row, index) => {  
                                      return (  
                                        <StyledTableRowModal   
                                        hover
                                        className={classes.row}   
                                        key={row.iFileSystemID}                                 
                                        >  
                                          <StyledTableCell style={{width: '70%', height: 'auto'}} onClick={(event) => FileDownloadBlob(row.iFileSystemID)}>{row.FileName}</StyledTableCell>  
                                          <StyledTableCell style={{width: '20%'}}>{formatSizeUnits(row.FileSize)}</StyledTableCell>  
                                          <StyledTableCell style={{width: '10%'}}>{row.data}</StyledTableCell>  
                                      </StyledTableRowModal>  
                                      );  
                                    })}                    
                                  </TableBody>                  
                              </Table>
                      </TableContainer>  
                    </TabPanel>
                </Modal>               
                </Table>
            </TableContainer>
            <TablePagination  
                rowsPerPageOptions={[5, 25, 50, 100, 200]}  
                component="div"  
                count={getDataFiltro.filter(type === 'Aberto' ? orders => orders.SituacaoFiltro === 'Aberto' : orders => orders.SituacaoFiltro !== 'Aberto').length}  
                rowsPerPage={rowsPerPage}  
                page={page} 
                labelRowsPerPage={"REGISTROS POR PÁGINA"}
                classes={{
                    root: classes.tablePagination,
                    caption: classes.tablePaginationCaption,
                    selectIcon: classes.tablePaginationSelectIcon,
                    select: classes.tablePaginationSelect,
                    actions: classes.tablePaginationActions,
                  }}
                onChangePage={handleChangePage}  
                onChangeRowsPerPage={handleChangeRowsPerPage}  
            />  
          
      </>
    );        
}

