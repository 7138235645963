import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles, makeStyles } from '@material-ui/core/styles';


const StyledTableRow = withStyles(() => ({
    root: {
      
      '&:nth-of-type(odd)': {
        // backgroundColor: '#242628',
      },
    },
  }))(TableRow);
  
  const StyledTableRowModal = withStyles(() => ({
    root: {
      border: 'none',
      
      '&:nth-of-type(odd)': {
        backgroundColor: '#8F929B',
        border: 'none',
      },
    },
  }))(TableRow);

  const StyledTableCell = withStyles(() => ({
    root: {
        borderBottom: "none"
      },
    head: {
        backgroundColor: "#2c2c2c",
        color: "#858585",
        fontSize: 12,
        fontWeight: 'bold',
    },
    body: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#8F929B'
    }
    }))(TableCell);

    const useStyles = makeStyles(() => ({
        root: {
            backgroundColor: "#8F929B",
            color: "#858585",
            
          },
          tableTr:{
            fontSize: '0.8rem',
            fontWeight: 'bold',
            backgroundColor: '#8F929B', 
            color: "#858585",   
          },
          tableTdL:{
            width: '250px',
            textAlign: 'left',
          },
          tableTdR:{
            textAlign: 'right'
          },
          tableTdRW:{
            textAlign: 'right',
            fontWeight: 'bold',
          },
        tablePagination: {
          },
        tablePaginationCaption: {
            color:  "#8F929B",
            fontWeight: 'bold',
            fontSize: '0.9rem'
          },
        tablePaginationSelectIcon: {
            color:  "#8F929B",
            fontWeight: 'bold',
          },
        tablePaginationSelect: {
            color:  "#8F929B",
            fontWeight: 'bold',
          },
        tablePaginationActions: {
            color:  "#8F929B",
            fontWeight: 'bold',
          },
          indicator: {
            backgroundColor: '#22b3A7',
            height: '3px'
          },
        }));

        const StyledTableRowFollow = withStyles(() => ({
            root: {
        
              '&:nth-of-type(odd)': {
                // backgroundColor: '#8F929B',
              },
              body:{
                
              }
            },
          }))(TableRow);
          
        
          const StyledTableRowTracking = withStyles(() => ({
            root: {
              backgroundColor: '#8F929B',
              '&:nth-of-type(odd)': {
                backgroundColor: '#8F929B',
                border: 'none',
              },
            },
          }))(TableRow);
        
          const StyledTableCellTracking = withStyles(() => ({
            root: {
              height: '30px',
                borderBottom: "none"
              },
            head: {
                backgroundColor: "#242628",
                color: "#858585",
                fontSize: 12,
                fontWeight: 'bold',
            },
            body: {
                fontSize: 12,
                fontWeight: 'bold',
                color: '#8F929B'
            }
        }))(TableCell);

        const StyledTableCellFollow = withStyles(() => ({
            root: {
                borderBottom: "none",
               
              },
            head: {
                backgroundColor: '#8F929B',
                color: "#858585",
                fontSize: 10,
                fontWeight: 'bold',
            },
            body: {
                fontSize: 12,
                
                color: '#8F929B',
            }
        }))(TableCell);

const Follow = ({dataProcesso, dataTracking, dataFollowUp}) => {
    const classes = useStyles();

    return (
        <>
            <TableContainer > 
                <Table aria-labelledby="tableTitle" aria-label="enhanced table" size="small">
                    <TableBody>
                        {dataProcesso.map((row) => {
                        return(
                            <div className="App__table__followup">
                            <div className="App__table__followup__div"><span>Exportador: </span>{row.fornecedor}</div>
                            <div className="App__table__followup__div"> 
                                <div className="App__table__followup__div__left"><span>Ref. Cliente: </span>{row.ident_cli_pro}</div>
                                <div className="App__table__followup__div__midle"><span>Fatura: </span>{row.fatura}</div>
                                <div className="App__table__followup__div__right"><span>Processo: </span>{row.nro_pro}/{row.ano_pro}</div>
                            </div>                            
                            </div>
                        );
                        })}
                    </TableBody>
            </Table>
            <br />
            
            
            <Table aria-labelledby="tableTitle" aria-label="enhanced table" size="small" className="App__table">
                <TableHead>
                <StyledTableRowTracking> 
                    <StyledTableCellTracking colSpan={2}>Observações</StyledTableCellTracking>
                </StyledTableRowTracking>
                </TableHead>
                <TableBody>
                {dataFollowUp.map((row) => {  
                    return (  
                    <StyledTableRowFollow   
                    hover
                    className={classes.row} 
                    key={row.procinformeid}                                      
                    >  
                    <StyledTableCellFollow style={{width: '10%', height: 'auto', whiteSpace: 'nowrap'}}>{row.data_proinf} ({row.hora})</StyledTableCellFollow>  
                    <StyledTableCellFollow>{row.desc_proinf}</StyledTableCellFollow>  
                    </StyledTableRowFollow>  
                    );  
                })}                    
                </TableBody>                  
            </Table>			
            </TableContainer>
        </>
    );
}

export default Follow;