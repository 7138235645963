import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import Container from '../../components/Container'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Select from '../../components/Select/Modalidade'
import SelectStatus from '../../components/Select/Status'
import { getIdEmpresa, getIdModalidade, getIdStatusProcesso, getIdTipoProcesso, getNomeUsuario, getNivelUsuario } from '../../services/auth';
import TableProcesso from '../../components/Table/Processos'
import api from '../../services/api'
import { Redirect } from "react-router-dom";
import { Input, Input2, Input3 } from './styles'

const Processos = () =>  {
  const currentPath = useLocation();
  const [ processo, setProcesso ] = useState("");
  const [ ano, setAno ] = useState("");
  const [ refCliente, setRefCliente ] = useState("");
  const [ fatura, setFatura ] = useState("");
  const [ exportador, setExportador ] = useState("");
  const [ origem, setOrigem ] = useState("");
  const [ di, setDI ] = useState("");
  const [ dataProcesso, getDataProcesso] = useState([]);

  if (!getNomeUsuario()){
    return <Redirect to='/'  />
  }

  if (getNivelUsuario() > 0){
    return <Redirect to='/'  />
  }

    function submitOrder(){
        let filtro = ""
        

        if(getIdModalidade() > 0){
            if(filtro === ""){
                filtro = " WHERE p.cod_fretemodo = " + getIdModalidade();
            }else{
                filtro = filtro + " and p.cod_fretemodo = " + getIdModalidade();
            }
            
        }
        if(processo > 0){
            if(filtro === ""){
                filtro = " WHERE p.nro_pro = " + processo;
            }else{
                filtro = filtro + " and p.nro_pro = " + processo;
            }
        }

        if(ano !== ""){
            if(filtro === ""){
                filtro = " WHERE p.ano_pro = " + ano;
            }else{
                filtro = filtro + " and p.ano_pro = " + ano;
            }
        }

        if (filtro === ""){
            filtro = ' WHERE (cod_importador = ' + getIdEmpresa() + ' or Cod_Consignee = ' + getIdEmpresa()+ ')';
        }else{
            filtro = filtro + ' and (Cod_Consignee = ' + getIdEmpresa() + ' or p.cod_importador = ' + getIdEmpresa() + ')';
        }        

        if(refCliente !== ""){
            if(filtro === ""){
                filtro = " p.ident_cli_pro LIKE '%" + refCliente+"%'";
            }else{
                filtro = filtro + " and p.ident_cli_pro LIKE '%" + refCliente+"%'"
            }
        }

        if(fatura !== ""){
            if(filtro === ""){
                filtro = " p2.Numero LIKE '% " + fatura + "%'" ;
            }else{
                filtro = filtro + " and p2.Numero LIKE '%" + fatura + "%'";
            }
        }

        if(exportador !== ""){
            if(filtro === ""){
                filtro = " E.razaosocial_emp LIKE '%" + exportador + "%'";
            }else{
                filtro = filtro + " and E.razaosocial_emp LIKE '%" + exportador + "%'";
            }
        }

        if(origem !== ""){
            if(filtro === ""){
                filtro = " p4.nome_portoaeroporto '%" + origem + "%'";
            }else{
                filtro = filtro + " and p4.nome_portoaeroporto LIKE '%" + origem + "%'";
            }
        }

        if(di !== ""){
            if(filtro === ""){
                filtro = " p.di_pro LIKE '%" + di + "%'";
            }else{
                filtro = filtro + " and p.di_pro LIKE '%" + di + "%'";
            }
        }
        
        if(getIdStatusProcesso() === "2") {
            if(filtro === ""){
                filtro = " p.CanceladoData is null";
            }else{
                filtro = filtro + " and p.CanceladoData is null";
            }
        }

        if(getIdStatusProcesso() === "3") {
            if(filtro === ""){
                filtro = " p.CanceladoData is not null";
            }else{
                filtro = filtro + " and p.CanceladoData is not null";
            }
        }

        if(getIdTipoProcesso() === "1") {
            if(filtro === ""){
                filtro = " p.tipo_pro = 'Importação'";
            }else{
                filtro = filtro + " and p.tipo_pro = 'Importação'";
            }
        }

        if(getIdTipoProcesso() === "2") {
            if(filtro === ""){
                filtro = " p.tipo_pro = 'Exportação'";
            }else{
                filtro = filtro + " and p.tipo_pro = 'Exportação'";
            }
        }
        
        if (getIdEmpresa() > 0){
        const getData = async () => {    
            await api.post('/api/empresas/processos/',{filtro})
                .then(res => {            
                getDataProcesso(res.data.result);
            });    			    
        }  
        getData();
        }
    }

  return (
    <>    
      <Header currentPath={currentPath.pathname}/>      
      <Container>
            <div  className="App__processos">
                <div className="App__processos__title">RELATÓRIO CONSOLIDADO DE PROCESSOS</div>
                <div className="App__processos__filtro__1">
                    <div className="App__processos__fitro">
                        <span className='App__processos__filtro__span'>PROCESSO</span><br/>
                        <Input2 id="Input2Processo" onChange={e => setProcesso(e.target.value)}/>
                    </div>
                    <div className="App__processos__fitro">
                        <span className='App__processos__filtro__span'>ANO</span><br/>
                        <Input2 id="inputAno" onChange={e => setAno(e.target.value)}/>
                    </div>
                    <div className="App__processos__fitro">
                        <span className='App__processos__filtro__span'>REF. CLIENTE</span><br/>
                        <Input id="inputRefCliente" onChange={e => setRefCliente(e.target.value)}/>
                    </div>
                    <div className="App__processos__fitro">
                        <span className='App__processos__filtro__span'>FATURA</span><br/>
                        <Input2 id="inputFatura" onChange={e => setFatura(e.target.value)}/>
                    </div>
                    <div className="App__processos__fitro">
                        <span className='App__processos__filtro__span'>MODALIDADE</span><br/>
                        <Select/>
                    </div>
                </div>
                <div className="App__processos__filtro__1">
                    <div className="App__processos__fitro">
                        <span className='App__processos__filtro__span'>EXPORTADOR</span><br/>
                        <Input3 id="inputExportador" onChange={e => setExportador(e.target.value)}/>
                    </div>
                    <div className="App__processos__fitro">
                        <span className='App__processos__filtro__span'>ORIGEM</span><br/>
                        <Input id="inputOrigem" onChange={e => setOrigem(e.target.value)}/>
                    </div>
                    <div className="App__processos__fitro">
                        <span className='App__processos__filtro__span'>DI/DSI/DTA</span><br/>
                        <Input2 id="inputSpan" onChange={e => setDI(e.target.value)}/>
                    </div>
                    <div className="App__processos__fitro">
                        <span className='App__processos__filtro__span'>ATIVO/CANCELADO</span><br/>
                        <SelectStatus />
                    </div>
                    <div className="App__processos__fitro">
                        <button type="button" className="App__processos__filtro__btn" onClick={submitOrder}>PESQUISAR</button>
                    </div>                                
                </div>
            </div>
            <div className="App_processos__resultado">
                <TableProcesso data={dataProcesso} />
            </div>
      </Container>
      <Footer />
  
    </>
  )
}

export default Processos;