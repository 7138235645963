import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/Container'
import api from '../../services/api';
import Header from '../../components/Header'
import { Redirect, useLocation } from "react-router-dom";
import { getNomeUsuario, getNivelUsuario } from '../../services/auth';
import TableNotas from '../../components/Table/TableNotas'

    function dataInicialFormatada() {
        var dia, diaF, mes, mesF, dataFinal;
        var data = new Date(new Date().setDate(new Date().getDate() - 30));
        dia  = data.getDate().toString();
        diaF = (dia.length === 1) ? '0'+dia : dia;
        mes  = (data.getMonth()+1).toString(); //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length === 1) ? '0'+mes : mes;
        dataFinal =data.getFullYear()+"-"+mesF+"-"+dia;
        return dataFinal;   
    }

    function dataAtualFormatada(){
        var data = new Date(),
        dia  = data.getDate().toString(),
        diaF = (dia.length === 1) ? '0'+dia : dia,
        mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length === 1) ? '0'+mes : mes,
        anoF = data.getFullYear();
        return anoF+"-"+mesF+"-"+diaF;   
    }

    const valueDataI = dataInicialFormatada();
    const valueDataF = dataAtualFormatada();    

    const useStyles = makeStyles((theme) => ({
        root: {
            background: "#242628",
            fontFamily: 'GHOTIC',
            color: '#696C72',
            alignItems: 'center',
            verticalAlign: 'middle',
            border: 'none',      
            fontWeight: 'bold',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),                        
          },
          container: {
            display: 'flex',
            marginLeft: '10px',
            marginTop: '10px',
            height: '35px',
            backgroundColor: "#242628",
          },
          tableTr:{
            fontSize: '0.8rem',
            fontWeight: 'bold',
            backgroundColor: '#232323', 
            color: "#858585",   
          },
          tableTdL:{
            width: '250px',
            textAlign: 'left',
          },
          tableTdR:{
            textAlign: 'right'
          },
          tableTdRW:{
            textAlign: 'right',
            fontWeight: 'bold',
          },
        tablePagination: {
          },
        tablePaginationCaption: {
            color:  "#858585",
            fontWeight: 'bold',
            fontSize: '0.9rem'
          },
        tablePaginationSelectIcon: {
            color:  "#858585",
            fontWeight: 'bold',
          },
        tablePaginationSelect: {
            color:  "#858585",
            fontWeight: 'bold',
          },
        tablePaginationActions: {
            color:  "#858585",
            fontWeight: 'bold',
          },
          indicator: {
            backgroundColor: '#858585',
            height: '3px'
          },
    }));

export default function Notas(){
    const currentPath = useLocation();
    const  classes = useStyles();
    const [valueDataIni, setValueDataIni] = useState('');
    const [valueDataFim, setValueDataFim] = useState('');   
    const [getData, setData] = React.useState([]);    

    if (!getNomeUsuario()){
        return <Redirect to='/'  />
    }

    if ((getNivelUsuario() === 0)){
      return <Redirect to='/'  />
    }

    if ((getNivelUsuario() === 3)){
      return <Redirect to='/'  />
    }

    const GetNotas = async () => {
        
        let dI = '';
        let dF = '';
        valueDataIni === '' ? dI = dataInicialFormatada() : dI = valueDataIni; 
        valueDataFim === '' ? dF = dataAtualFormatada() : dF = valueDataFim;
        let filtro = ""
        filtro = ` and p.FaturarEmpresaId = 6770 and (p.dt_fat between '${dI}' and '${dF}')`;   
        const getData = async () => {    
            await api.post('/api/empresas/notasclientes/',{filtro})
                .then(res => {            
                    setData(res.data.result);                    
            });    	            	    
        }  
        getData();        
      }

    function atualizar(){
        GetNotas();
    }

    return(
        <>
            <Header currentPath={currentPath.pathname}/> 
            <Container>
                <div className="App__homepage">
                    <div className="App__conteudo">
                        <div className="App__conteudo__filtro">
                        
                            <span className="App__container__box__c__filtro__marcador__span">PERÍODO</span>
                            <form className={classes.container} noValidate>
                            <input type="date" id="datei" className={classes.root}
                                defaultValue={valueDataI}                      
                                onChange={e => setValueDataIni(e.target.value)}/>
                            </form>
                            <span className="App__container__box__c__filtro__marcador__span">A</span>
                            <form className={classes.container} noValidate>
                            <input type="date" id="datef" className={classes.root}
                                defaultValue={valueDataF}                      
                                onChange={e => setValueDataFim(e.target.value)}/>
                            </form>
                            <button type="submit" 
                                    className="App__container__box__c__filtro__btn"
                                    onClick={atualizar}                      
                                    >CONSULTAR</button>                                                         
                        </div>
                    </div>
                    <TableNotas data={getData} />
                </div>
            </Container>
        </>
    );

}