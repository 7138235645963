import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/Container'
import api from '../../services/api';
import styles from './styles.scss';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header'
import TableEmbarque  from '../../components/Table/TableEmbarque';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import logo from "../../assets/imgs/logo.png";
import { getNomeUsuario, getNivelUsuario } from '../../services/auth';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Redirect } from "react-router-dom";

var jszip = require('jszip');
var saveAs = require('file-saver');

const useStyles = makeStyles((theme) => ({
    root: {  
      background: "#242628",
      fontFamily: 'GHOTIC',
      color: '#696C72',
      alignItems: 'center',
      verticalAlign: 'middle',
      border: 'none',      
      fontWeight: 'bold',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    container: {
      display: 'flex',
      marginLeft: '10px',
      marginTop: '10px',
      height: '35px',
      backgroundColor: "#242628",
    },
    indicator: {
      backgroundColor: '#858585',
      height: '3px'
    },

  }));

  function TabPanel(props) {
    
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }



function dataInicialFormatada() {
    var dia, diaF, mes, mesF, dataFinal;
    var data = new Date(new Date().setDate(new Date().getDate() - 30));
    dia  = data.getDate().toString();
    diaF = (dia.length === 1) ? '0'+dia : dia;
    mes  = (data.getMonth()+1).toString(); //+1 pois no getMonth Janeiro começa com zero.
    mesF = (mes.length === 1) ? '0'+mes : mes;
    dataFinal =data.getFullYear()+"-"+mesF+"-"+dia;
    return dataFinal;   
  }

  function dataAtualFormatada(){
    var data = new Date(),
        dia  = data.getDate().toString(),
        diaF = (dia.length === 1) ? '0'+dia : dia,
        mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length === 1) ? '0'+mes : mes,
        anoF = data.getFullYear();
    return anoF+"-"+mesF+"-"+diaF;   
  }
  const valueDataI = dataInicialFormatada();
  const valueDataF = dataAtualFormatada();

export default function Embarque(){
    const currentPath = useLocation();
    const  classes = useStyles();
    const [qualSeguro, setQualSeguro] = React.useState(0);
    const [value, setValue] = React.useState(0);
    const [valueDataIni, setValueDataIni] = useState('');
    const [valueDataFim, setValueDataFim] = useState('');   
    const [getData, setData] = React.useState([]);
    const [getNameZip, setNameZip] = React.useState('');
    const [getFileName, setFileName ] = useState('');

    const handleChangeSeguro = (event, newValue) => {
      setQualSeguro(newValue);
    };


    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    // if (!getNomeUsuario()){
    //     return <Redirect to='/'  />
    // }
    
    // if (getNivelUsuario() === 0){
    //   return <Redirect to='/'  />
    // }

    // if ((getNivelUsuario() === 3)){
    //   return <Redirect to='/'  />
    // }

    const GetSeguros = async () => {
        const getData = async () => {    
            await api.post('/api/NumerarioFrete')
                .then(res => {            
                    setData(res.data.result);
            });    	
            setValue(0);		    
        }  
        getData();
      }

    function atualizar(){
        GetSeguros();
    }

    return (
        <>            
        {/* <Header currentPath={currentPath.pathname}/>  */}
        <Container>
           <div className="App__homepage">
                <div>
                  {/* <Tabs value={value} onChange={handleChange} classes={{indicator: classes.indicator}} centered>
                    <Tab label="Numerário Solicitado" {...a11yProps(0)} inkBarStyle={{background: 'red'}}  style={{color: '#8F929B'}}/>
                    <Tab label="Numerário Recebido" {...a11yProps(1)} inkBarStyle={{background: 'red'}} style={{color: '#8F929B'}}/>
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <div className="App__imp__paper">Numerário Solicitado</div>
                    <TableEmbarque data={getData.filter(numerarios => numerarios.Pagamento === '')} type={0}/>
                  </TabPanel>
                  <TabPanel className={classes.appThemePanel} value={value} index={1}>
                      <div className="App__imp__paper">Numerário Recebido</div>
                      <TableEmbarque data={getData.filter(numerarios => numerarios.Pagamento !== '')} type={1}/>
                  </TabPanel> */}
                </div>
            </div>
          </Container>     
        </>
    );
}

