import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Container from '../../components/Container'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import TabUser from '../../components/Table/TabUser';
import TabAccount from '../../components/Table/TabAccount';
import { Redirect } from "react-router-dom";
import { getNomeUsuario, getNivelUsuario } from '../../services/auth';

function TabPanel(props) {
    
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  
    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: "#8F929B",
            
          },
          indicator: {
            backgroundColor: '#22b3A7',
            height: '3px'
          },
        tablePagination: {
          },
        tablePaginationCaption: {
            color:  "#8F929B",
            fontWeight: 'bold',
            fontSize: '0.9rem'
          },
        tablePaginationSelectIcon: {
            color:  "#8F929B",
            fontWeight: 'bold',
          },
        tablePaginationSelect: {
            color:  "#8F929B",
            fontWeight: 'bold',
          },
        tablePaginationActions: {
            color:  "#8F929B",
            fontWeight: 'bold',
          },
        }));

        function a11yProps(index) {
            return {
              id: `simple-tab-${index}`,
              'aria-controls': `simple-tabpanel-${index}`,
            };
          }

export default function Cadastros(){
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    if (!getNomeUsuario()){
      return <Redirect to='/'  />
  }

  if (getNivelUsuario() > 0){
    return <Redirect to='/'  />
  }

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return(
        <>
            <Header/>
            <Container>
            <Paper square className="App__cad__tab">
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                    classes={{indicator: classes.indicator}} 
                    className="App__cad__tabs"
                >
                    <Tab label="USUÁRIOS" {...a11yProps(1)} inkBarStyle={{background: 'red'}} style={{color: '#8F929B'}}/>
                    <Tab label="ACCOUNTS" {...a11yProps(1)} inkBarStyle={{background: 'red'}} style={{color: '#8F929B'}}/>
                </Tabs>
                <TabPanel value={value} index={0} className="App__cad__tabPanel">
                    <div className="App__cad__paper">
                        <div className="App__cad__div__span">
                            <span className="App__cad__span">CADASTRO DE USUÁRIOS</span>
                        </div>
                        <div className="App__cad__div__bnt">
                            <button type="submit" 
                            className="App__cad__btn"
                            >NOVO USUÁRIO</button> 
                        </div>
                    </div>
                    <div className="App__cad__dados">
                        <TabUser />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1} className="App__cad__tabPanel">
                    <div className="App__cad__paper">
                        <div className="App__cad__div__span">
                            <span className="App__cad__span">CADASTRO DE ACCOUNT</span>
                        </div>
                        <div className="App__cad__div__bnt">
                            <button type="submit" 
                            className="App__cad__btn"
                            >NOVO ACCOUNT</button> 
                        </div>
                    </div>
                    <div className="App__cad__dados">
                        <TabAccount />
                    </div>
                    
                </TabPanel>
                </Paper>
            </Container>
            <Footer/>
        </>
    );
}