import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import api from '../../services/api';
import FusionCharts from 'fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import Maps from 'fusioncharts/fusioncharts.maps';
import Charts from "fusioncharts/fusioncharts.charts";
import overlapp from "fusioncharts/fusioncharts.overlappedcolumn2d";
import widgets from "fusioncharts/fusioncharts.widgets";
import World from 'fusioncharts/maps/fusioncharts.world';
import power from "fusioncharts/fusioncharts.powercharts";
import ReactFC from 'react-fusioncharts';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '../../components/Container'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Fornecedores from '../../components/Charts/Fornecedores';
import { getNomeUsuario, getIdEmpresa, getNivelUsuario } from '../../services/auth';
import Previsoes from "../../components/Previsoes/Previsoes";
import ContasReceber from "../../components/ContasReceber";
import '../../assets/fonts/GOTHIC.TTF'; 
import Facebook from '../../assets/imgs/facebook.png';
import YouTube from '../../assets/imgs/youtube.png';
import LinkedIn from '../../assets/imgs/linkedin.png';
import InstaGram from '../../assets/imgs/instagram.png';
import Spotify from '../../assets/imgs/spotify.png';
import Telegram from '../../assets/imgs/telegram.png';
import Dribble from '../../assets/imgs/dribble.png';
import Blog from '../../assets/imgs/blog.png';
import { Redirect } from "react-router-dom";
import useWindowDimensions from "../../Helpers/useWindowDimensions";

import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts';
import PTax from "../../components/PTax";
ReactFC.fcRoot(FusionCharts, Charts, overlapp, widgets, power,  Maps, World, FusionTheme);


  const useStyles = makeStyles((theme) => ({
    root: {  
      background: "#232323",
      fontFamily: 'GHOTIC',
      color: '#858585',
      alignItems: 'center',
      verticalAlign: 'middle',
      border: 'none',      
      fontWeight: 'bold',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    container: {
      display: 'flex',
      marginLeft: '10px',
      marginTop: '10px',
      height: '35px',
      backgroundColor: "#232323",
    },

  }));

  function dataInicialFormatada(){
    var data = new Date(),
        mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length === 1) ? '0'+mes : mes,
        anoF = data.getFullYear();
    return anoF+"-"+mesF+"-01";   
  }

  function dataAtualFormatada(){
    var data = new Date(),
        dia  = data.getDate().toString(),
        diaF = (dia.length === 1) ? '0'+dia : dia,
        mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length === 1) ? '0'+mes : mes,
        anoF = data.getFullYear();
    return anoF+"-"+mesF+"-"+diaF;   
  }
  const valueDataI = dataInicialFormatada();
  const valueDataF = dataAtualFormatada();



const Home = () =>  {
  const  classes = useStyles();
  const [valueDataIni, setValueDataIni] = useState('');
  const [valueDataFim, setValueDataFim] = useState('');   
  const [dataPrevisao, setDataPrevisao] = useState([]);
  const [dataEmbarqueCont2, setEmbarqueCont2] = useState([]);
  const [dataEmbarqueeAereo, setEmbarqueAereo] = useState([]); 
  const [dataTopFornecedor, setDataTopFornecedor] = useState([]);  
  const [dataCanais, setDataCanais] = useState([]);      
  const [dataContasReceber, setDataContasReceber] = useState([]);   
  const [dataCotacaDolar, setCotacaoDolar] = useState([]);
  const [dataCotacaoEuro, setCotacaoEuro] = useState([]);
  const [dataMoedas, setDataMoedas] = useState([]);
  const [dataDolar, setDataDolarAtual] = useState(0);
  const [dataFaturamento, setDataFaturamento] = useState([]);
  const [dataBlog, setDataBlog] = useState([]);
  const [dataEuro2, setDataEuro] = useState(0);  
  const matches = useMediaQuery('(max-width:1366px)');
  const COLORS = ['#01b8aa', '#f2c80f',  '#fd625e', '#5f6b6d', '#8ac8bb', '#fdcb6e', '#ff7675', '#3498db'];
  const { height, width } = useWindowDimensions();

  useEffect(() => { 
    const GetDataFaturamento = async () => {
      const result = await api.get('/api/empresas/faturamento/'+getIdEmpresa());    
      setDataFaturamento(result.data.result);          
    }

    const GetDataReceber = async () => {    
      const result = await api.get('/api/empresas/bdia/'+getIdEmpresa());    
      setDataContasReceber(result.data.result);               
    }  

    const GetDataPrevisao = async () => {
      const result = await api.get('/api/empresas/prevchegada/'+getIdEmpresa());    
      setDataPrevisao(result.data.result);          
    }

    const GetEmbarqueContinente = async () => {
      let dI = '';
      let dF = '';
      valueDataIni === '' ? dI = dataInicialFormatada() : dI = valueDataIni; 
      valueDataFim === '' ? dF = dataAtualFormatada() : dF = valueDataFim;
      let filtro = ""
      filtro = `where (p.Cod_Consignee = ${getIdEmpresa()}  or p.cod_importador = ${getIdEmpresa()} ) and p3.confirmacaodata between ' ${dI} ' and ' ${dF} ' and p.CanceladoData is null`;
      const result = await api.post('/api/empresas/ec2/',{filtro});    
      setEmbarqueCont2(result.data);                  
    }

    const GetEmbarqueAeroporto = async () => {
      let dI = '';
      let dF = '';
      valueDataIni === '' ? dI = dataInicialFormatada() : dI = valueDataIni; 
      valueDataFim === '' ? dF = dataAtualFormatada() : dF = valueDataFim;
      let filtro = ""
      filtro = ` where (p.Cod_Consignee =  ${getIdEmpresa()}  or p.cod_importador =  ${getIdEmpresa()} ) and p3.confirmacaodata between '${dI} ' and '${dF}' and p.CanceladoData is null`;          
      const result = await api.post('api/empresas/ap/',{filtro});    
      setEmbarqueAereo(result.data.result);                  
    }
    const GetTopFornecedores = async () => {
      let dI = '';
      let dF = '';
      valueDataIni === '' ? dI = dataInicialFormatada() : dI = valueDataIni; 
      valueDataFim === '' ? dF = dataAtualFormatada() : dF = valueDataFim;
      let filtro = ""
      filtro = ` where (p.Cod_Consignee = ${getIdEmpresa()} or p.cod_importador = ${getIdEmpresa()}) and p.dt_ab_pro between ' ${dI} ' and ' ${dF}' and p.CanceladoData is null`;          
      const result = await api.post('/api/empresas/topfornecedores/',{filtro});    
      setDataTopFornecedor(result.data.result);                  
    }
    const GetCanais = async () => {
      let dI = '';
      let dF = '';
      valueDataIni === '' ? dI = dataInicialFormatada() : dI = valueDataIni; 
      valueDataFim === '' ? dF = dataAtualFormatada() : dF = valueDataFim;
      let filtro = ""
      filtro = ` where (p.Cod_Consignee = ${getIdEmpresa()} or p.cod_importador = ${getIdEmpresa()}) and p.dt_lib_pro between ' ${dI} ' and ' ${dF} ' and p.CanceladoData is null`;          
      const result = await api.post('/api/empresas/canais/',{filtro});    
      setDataCanais(result.data.result);                  
    }

    const GetMoedas = async () => {
      let filtro = ""
      
      filtro = " where m.datataxa BETWEEN CURDATE() - INTERVAL 9 DAY AND CURDATE() and m.MoedaId IN('US$')";                
      const resultCotacaoDolar = await api.post('/api/empresas/moedas',{filtro});          
      setCotacaoDolar(resultCotacaoDolar.data.result);

      filtro = "";
      filtro = " where m.datataxa BETWEEN CURDATE() - INTERVAL 9 DAY AND CURDATE() and m.MoedaId IN('EUR')";                
      const resultCotacaoEuro = await api.post('/api/empresas/moedas',{filtro});         
      
      setCotacaoEuro(resultCotacaoEuro.data.result);
      
      filtro = "";
      filtro = " where m.datataxa  = CURDATE() and m.MoedaId IN('EUR', 'US$')";          
      const result = await api.post('/api/empresas/moedas',{filtro});    
      
      if (result.data.result[0]['PtaxCompraValor'] == null){
        filtro = "";
        filtro = " where m.datataxa  = CURDATE() - INTERVAL 1 DAY and m.MoedaId IN('EUR', 'US$')";          
        const result = await api.post('/api/empresas/moedas/',{filtro});    
        setDataMoedas(result.data.result); 
        if (result.data.result[0]['PtaxCompraValor'] == null){
          filtro = "";
          filtro = " where m.datataxa  = CURDATE() - INTERVAL 2 DAY and m.MoedaId IN('EUR', 'US$')";          
          const result = await api.post('/api/empresas/moedas/',{filtro});    
          setDataMoedas(result.data.result); 
          if (result.data.result[0]['PtaxCompraValor'] == null){
            filtro = "";
            filtro = " where m.datataxa  = CURDATE() - INTERVAL 3 DAY and m.MoedaId IN('EUR', 'US$')";          
            const result = await api.post('/api/empresas/moedas/',{filtro});    
            setDataMoedas(result.data.result); 
            if (result.data.result[0]['PtaxCompraValor'] == null){
              filtro = "";
              filtro = " where m.datataxa  = CURDATE() - INTERVAL 4 DAY and m.MoedaId IN('EUR', 'US$')";          
              const result = await api.post('/api/empresas/moedas/',{filtro});    
              setDataMoedas(result.data.result); 
              if (result.data.result[0]['PtaxCompraValor'] == null){
                filtro = "";
                filtro = " where m.datataxa  = CURDATE() - INTERVAL 5 DAY and m.MoedaId IN('EUR', 'US$')";          
                const result = await api.post('/api/empresas/moedas/',{filtro});    
                setDataMoedas(result.data.result);                 
              }
            }
          }
        }  
      }else{
        setDataMoedas(result.data.result);                  
      }

      filtro = "";
      filtro = " where m.datataxa = CURDATE() and m.MoedaId IN('US$')";          
      const resultDolar = await api.post('/api/empresas/moedas',{filtro});          
      setDataDolarAtual(resultDolar.data.result[0].Valor1);      

      filtro = "";
      filtro = " where m.datataxa = CURDATE() and m.MoedaId IN('EUR')";          
      const resultEuro = await api.post('/api/empresas/moedas',{filtro});          
      setDataEuro(resultEuro.data.result[0].Valor1);      
    }

    const GetBlog = async () => {
      const result = await api.post('/api/empresas/blog/');    
      setDataBlog(result.data.result);         
    }

    if(getIdEmpresa() > 0){
      GetDataFaturamento();
      GetDataReceber();
      GetDataPrevisao();       
      GetEmbarqueContinente();  
      GetEmbarqueAeroporto();  
      GetTopFornecedores()
      GetCanais();        

      }
      GetMoedas();  
      GetBlog();
  }, []);

  // if (getNivelUsuario() > 0){
  //   return <Redirect to='/seguro'  />
  // }

  // if (getNivelUsuario() > 0){
  //   return <Redirect to='/'  />
  // }

  const chartConfigs = {
    type: 'world',
    width: width <=  1366 ? 380 : 580,
    height: width <=  1366 ? 180 : 200,    
    dataFormat: 'json',
    dataSource: dataEmbarqueCont2,    
  };

  const GetEmbarqueContinente2 = async () => {
    let dI = '';
    let dF = '';
    valueDataIni === '' ? dI = dataInicialFormatada() : dI = valueDataIni; 
    valueDataFim === '' ? dF = dataAtualFormatada() : dF = valueDataFim;
    let filtro = ""
    filtro = ` where (p.Cod_Consignee =  ${getIdEmpresa()}  or p.cod_importador = ${getIdEmpresa()}) and p3.confirmacaodata between ' ${dI} '  and '  ${dF}' and p.CanceladoData is null`;
    const result = await api.post('/api/empresas/ec2/',{filtro});    
    setEmbarqueCont2(result.data);                  
  }

  const GetEmbarqueAeroporto2 = async () => {
    let dI = '';
    let dF = '';
    valueDataIni === '' ? dI = dataInicialFormatada() : dI = valueDataIni; 
    valueDataFim === '' ? dF = dataAtualFormatada() : dF = valueDataFim;
    let filtro = ""
    filtro = ` where (p.Cod_Consignee = ${getIdEmpresa()} or p.cod_importador = ${getIdEmpresa()}) and p3.confirmacaodata between '${dI}' and '${dF}' and p.CanceladoData is null`;          
    const result = await api.post('/api/empresas/ap/',{filtro});    
    setEmbarqueAereo(result.data.result);                  
  }
  const GetTopFornecedores2 = async () => {
    let dI = '';
    let dF = '';
    valueDataIni === '' ? dI = dataInicialFormatada() : dI = valueDataIni; 
    valueDataFim === '' ? dF = dataAtualFormatada() : dF = valueDataFim;
    let filtro = ""
    filtro = ` where (p.Cod_Consignee =  ${getIdEmpresa()} or p.cod_importador = ${getIdEmpresa()}) and p.dt_ab_pro between '${dI} ' and ' ${dF}' and p.CanceladoData is null`;          
    const result = await api.post('/api/empresas/topfornecedores/',{filtro});   
    setDataTopFornecedor(result.data.result);                  
  }
  const GetCanais2 = async () => {
    let dI = '';
    let dF = '';
    valueDataIni === '' ? dI = dataInicialFormatada() : dI = valueDataIni; 
    valueDataFim === '' ? dF = dataAtualFormatada() : dF = valueDataFim;
    let filtro = ""
    filtro = ` where (p.Cod_Consignee = ${getIdEmpresa()} or p.cod_importador = ${getIdEmpresa()}) and p.dt_lib_pro between '${dI}' and '${dF}' and p.CanceladoData is null`;          
    const result = await api.post('/api/empresas/canais/',{filtro});    
    setDataCanais(result.data.result);                  
  }

  function atualizar(){
    if (getIdEmpresa() > 0){
      GetEmbarqueContinente2();  
      GetEmbarqueAeroporto2();  
      GetTopFornecedores2()
      GetCanais2();
    }
  }

  if (getNivelUsuario() ===  1){
    return <Redirect to='/seguro'  />
  }
  
  return (
    <>
      <Header />
      <Container className="App__container">
          <span className="App__container__span">Seja bem vindo {getNomeUsuario()} </span>
          <div className="App__container__div__box">
            <div className="App__container__box">
              <div className="App__container__box__dolar">
                <div className="App__container__box__dolar__marcador"/>
                <span className="App__container__box__dolar__span">$</span>
                <span className="App__container__box__dolar__span__sigla">R$</span>
                 <span className="App__container__box__dolar__span__valor">{dataDolar}</span>
                 <ResponsiveContainer width="99%" height={100}>
                  <AreaChart className="App__chart" labelLine={false} data={dataCotacaDolar} >
                    <XAxis tick={{fontSize: '.6rem', fontWeight: 'bold'}} dataKey="data" />
                    <YAxis tick={{fontSize: '.6rem'}} dataKey="cotacao" domain={['auto', 'auto']} />
                    <Tooltip  />
                    <Area type="monotone" yAxis="yAxis" dataKey="cotacao" stroke="#42EAB2" fill="#42EAB2" />
                  </AreaChart>
                  </ResponsiveContainer>
              </div>
            </div>
            <div className="App__container__box">
              <div className="App__container__box__euro">
                <div className="App__container__box__euro__marcador"/>
                <span className="App__container__box__euro__span">€</span>
                <span className="App__container__box__euro__span__sigla">R$</span>
                <span className="App__container__box__euro__span__valor">{dataEuro2}</span>
                <ResponsiveContainer width="95%" height={100}>
                  <AreaChart className="App__chart" data={dataCotacaoEuro} >
                    <XAxis tick={{fontSize: 10, fontWeight: 'bold'}} dataKey="data"/>
                    <YAxis tick={{fontSize: 10}} dataKey="cotacao" domain={['auto', 'auto']} />
                    <Tooltip />
                    <Area type="monotone" dataKey="cotacao" stroke="#EC6262" fill="#EC6262" />
                  </AreaChart>
                  </ResponsiveContainer>
              </div>                
            </div>
            <div className="App__container__box">
              <div className="App__container__box__ptax">
                <div className="App__container__box__ptax__marcador"/>
                <span className="App__container__box__ptax__span">PTAX</span>  
                <div className="App__container__box__dados">
                  <PTax data={dataMoedas}/> 
                </div>
              </div>   
                          
            </div>
            <div className="App__container__box">
              <div className="App__container__box__blog">
                <div className="App__container__box__blog__marcador"/>
                <span className="App__container__box__blog__span">BLOG</span>  
                <div  className="App__container__box__blog__lista">
                  <table className="App__container__box__blog__lista__table">
                    {dataBlog.map((row) => {
                      return(
                      <tr key={row.post_title} className="App__container__box__blog__lista__table__tr">
                        <td>{row.data} - </td>
                        <td>{row.post_title}</td>                      
                      </tr>
                      );
                    })}
                  </table>
                </div>
              </div>                
            </div>
            {/* <div className="App__container__box__ult">
              <div className="App__container__box__ult">
                <div className="App__container__box__ult__marcador"/>
                <div className="App__container__box__ult__1">
                  <a href="https://www.3scorporate.com/" target="_blank" rel="noreferrer">
                    <img className="App__container__box__ult__1__img" src={Dribble}  alt="Site 3SCorp" style={{color: '#fff'}}/>
                  </a>
                  <a href="https://www.linkedin.com/company/10653755/admin/" target="_blank" rel="noreferrer">
                    <img className="App__container__box__ult__1__img" src={LinkedIn}  alt="Linkedin" style={{color: '#fff'}}/>
                  </a>
                  <a href="https://www.facebook.com/3SCorporate/" target="_blank" rel="noreferrer">
                    <img className="App__container__box__ult__1__img" src={Facebook}  alt="Facebook" style={{color: '#fff'}}/>
                  </a>
                  <a href="https://www.instagram.com/3s_corp/" target="_blank" rel="noreferrer">
                    <img className="App__container__box__ult__1__img" src={InstaGram}  alt="Instagram" style={{color: '#fff'}}/>
                  </a>
                </div>
                <div className="App__container__box__ult__2">
                  <a href="https://open.spotify.com/show/5POaIi0KJm2euX7TEHDSCY" target="_blank" rel="noreferrer">
                    <img className="App__container__box__ult__2__img" src={Spotify}  alt="Power BI" style={{color: '#fff'}}/>                  
                  </a>
                  <a href="https://www.youtube.com/channel/UC3-691wFb8CHwRcjk3uXhxg" target="_blank" rel="noreferrer">
                    <img className="App__container__box__ult__2__img" src={YouTube}  alt="Youtube" style={{color: '#fff'}}/>
                  </a>
                  <a href="https://t.me/Comex3SCORP" target="_blank" rel="noreferrer">
                    <img className="App__container__box__ult__2__img" src={Telegram}  alt="Telegram" style={{color: '#fff'}}/>
                  </a>
                  <a href="https://www.3scorporate.com/blog" target="_blank" rel="noreferrer">
                    <img className="App__container__box__ult__2__img" src={Blog}  alt="Blog" style={{color: '#fff'}}/>
                  </a>
                </div>
              </div>                
            </div> */}
          </div>          
          <div className="App__container__div__box__center">
            <div className="App__container__box__l">
              <div className="App__container__box__l__aviso">
                <div className="App__container__box__l__aviso__marcador"/>
                <span className="App__container__box__l__aviso__marcador__span">AVISOS</span>
                <span className="App__container__box__l__aviso__marcador__span__aviso">{dataFaturamento.length > 0 ? "VOCÊ POSSUI NOVOS FATURAMENTOS DISPONÍVEIS" : ""}</span>
              </div> 
              <div className="App__container__box__l__previsao">
                <div className="App__container__box__l__previsao__marcador"/>
                <div className="App__container__box__dados">
                  <Previsoes dataPrevisao={dataPrevisao} rows={matches ? 14 : 12}/>
                </div>
              </div>             
            </div>
            <div className="App__container__box__c">
              <div className="App__container__box__c__filtro">
                <div className="App__container__box__c__filtro__marcador"/>
                <span className="App__container__box__c__filtro__marcador__span">PERÍODO</span>
                <form className={classes.container} noValidate>
                  <input type="date" id="datei" className={classes.root}
                    defaultValue={valueDataI}                      
                    onChange={e => setValueDataIni(e.target.value)}/>
                </form>
                <span className="App__container__box__c__filtro__marcador__span">A</span>
                <form className={classes.container} noValidate>
                  <input type="date" id="datef" className={classes.root}
                    defaultValue={valueDataF}                      
                    onChange={e => setValueDataFim(e.target.value)}/>
                </form>
                <button type="submit" 
                        className="App__container__box__c__filtro__btn"
                        onClick={atualizar}                      
                        >ATUALIZAR</button>  
              </div>    
              <div className="App__container__box__c__charts">
                <div className="App__container__box__c__charts__top">
                  <div className="App__container__box__c__charts__top__tl">
                    <div className="App__container__box__c__charts__top__tl__marcador"/>
                    <span className="App__container__box__c__charts__top__tl__span">EMBARQUE POR CONTINENTE</span>
                    <div className="App__container__box__c__charts__top__tl__grafico">
                      <ReactFC {...chartConfigs}
                      fcEvent-initialized={this}
                      ref={React.createRef()}/>
                      </div>
                  </div>
                  <div className="App__container__box__c__charts__top__tr">
                    <div className="App__container__box__c__charts__top__tr__marcador"/>                    
                    <div className="App__container__box__c__charts__top__tr__grafico">
                      <Fornecedores dataFornecedores={dataTopFornecedor} colors={COLORS} width={width <=  1366 ? 300 : 500} height={200} radius={width <=  1366 ? 50 : 80} cx={width <=  1366 ? 50 : 80} cy={width <=  1366 ? 60 : 80} sliceQtd={8} title={"PRINCIPAIS FORNECEDORES"}/>
                    </div>      
                  </div>
                </div>
                <div className="App__container__box__c__charts__bottom">
                  <div className="App__container__box__c__charts__bottom__bl">
                    <div className="App__container__box__c__charts__bottom__bl__marcador"/>
                    <div className="App__container__box__c__charts__bottom__bl__grafico">
                      <Fornecedores dataFornecedores={dataEmbarqueeAereo}colors={COLORS} width={width <=  1366 ? 300 : 500} height={200} radius={width <=  1366 ? 50 : 80} cx={width <=  1366 ? 50 : 80} cy={width <=  1366 ? 60 : 80} sliceQtd={8}title={"PRINCIPAIS AEROPORTOS"} />
                    </div>
                  </div>
                  <div className="App__container__box__c__charts__bottom__br">
                    <div className="App__container__box__c__charts__bottom__br__marcador"/>
                      <div className="App__container__box__c__charts__bottom__br__grafico">
                        <Fornecedores dataFornecedores={dataCanais} colors={COLORS} width={width <=  1366 ? 300 : 500} height={200} radius={width <=  1366 ? 50 : 80} cx={width <=  1366 ? 50 : 80} cy={width <=  1366 ? 60 : 80} sliceQtd={8}title={"CANAIS DE PARAMETRIZAÇÃO"}/>
                      </div>
                  </div>
                </div>
              </div>                    
            </div>
            {/* <div className="App__container__box__r">
              <div className="App__container__box__r__financeiro">
                <div className="App__container__box__r__financeiro__marcador"/>
                
              </div>
              <div className="App__container__box__dados">
                <ContasReceber dataVencer={dataContasReceber} rows={matches ? 16 : 14}/>

              </div>
            </div> */}
            
          </div>
      </Container>
      <Footer />
  
    </>
  )
}

export default Home;