import React, { useState, useEffect } from "react";
import api from "../../services/api";
import Leaderlog from "../../assets/imgs/leaderlog.png";
import logo3s from "../../assets/imgs/3S.PNG";
import { getNrProcesso, getAnoPro } from "../../services/auth";

const Dados = () => {
  const [data, setData] = useState([]);
  const [despesas, setDespesas] = useState([]);
  const [nfs, setNfs] = useState([]);
  const [itens, setItens] = useState([]);
  const [adiantamento, setAdiantamento] = useState([]);
  const [saldo, setSaldo] = useState("");
  const [getNrNf, setNrNf] = useState("");
  const [getTotalGeral, setTotalGeral] = useState("");
  const [existe, setExiste] = useState(0);

  useEffect(() => {
    const getDemonstrativo = async () => {
      const result = await api.post("/api/empresas/demonstrativoStra", {
        processo: getNrProcesso(),
        ano: getAnoPro(),
      });
      setExiste(result.data.cliente.length);
      setData(result.data.cliente[0]);
      setDespesas(result.data.despesas);
      setItens(result.data.itens);
      setAdiantamento(result.data.adiantamento);
      setSaldo(result.data.saldo[0]["Saldo"]);
      if (result.data.despesas.length > 0) {
        setTotalGeral(result.data.despesas[0]["TOTGERAL"]);
        setNrNf(result.data.nfs[0]["NFSeNumero"]);
      }

      setNfs(result.data.nfs);
    };

    getDemonstrativo();
  }, []);

  return (
    <>
      {/* {existe.length > 0 ?  */}
      <div
        style={{ width: "209.55mm", height: "auto", backgroundColor: "#fff" }}
      >
        {/* // height: '298.45mm', backgroundColor: '#fff'}}> */}

        <div style={{ marginLeft: "2rem" }}>
          <div style={{ width: "100%" }}>
            <div style={{ float: "left", marginLeft: "10px" }}>
              <img
                className="App__header__logo"
                width={200}
                src={Leaderlog}
                alt=""
              />
            </div>
            <div style={{ marginTop: "1rem", float: "right", display: "flex" }}>
              <span style={{ fontWeight: "bold", marginTop: "2.0rem" }}>
                A member of&nbsp;&nbsp;&nbsp;
              </span>
              <img
                className="App__header__logo"
                width={40}
                height={50}
                src={logo3s}
                alt=""
              />
            </div>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <hr
              style={{
                display: "flex",
                width: "100%",
                height: "3px",
                backgroundColor: "#000",
              }}
            />
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <span
              style={{
                fontSize: "1.2rem",
                color: "#000",
                fontWeight: "bold",
                margin: "0 auto",
                padding: ".2rem",
              }}
            >
              Fatura - {getNrProcesso()}/{getAnoPro()}{" "}
            </span>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <hr
              style={{
                display: "flex",
                width: "100%",
                height: "3px",
                backgroundColor: "#000",
              }}
            />
          </div>

          <table
            style={{ width: "100%", fontSize: ".8rem", color: "#000" }}
            cellspacing="0"
          >
            <thed>
              <tr>
                <td style={{ fontWeight: "bold", padding: ".2rem" }}>
                  Cliente:
                </td>
                <td align="left">{data["CLIENTE"]}</td>
                <td>&nbsp;&nbsp;</td>
                <td>&nbsp;&nbsp;</td>
                <td>&nbsp;&nbsp;</td>
                <td style={{ fontWeight: "bold", padding: ".2rem" }}>
                  Ref. do Cliente:
                </td>
                <td align="left">{data["ident_cli_pro"]}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", padding: ".2rem" }}>
                  Exportador:
                </td>
                <td align="left">{data["EXPORTADOR"]}</td>
                <td>&nbsp;&nbsp;</td>
                <td>&nbsp;&nbsp;</td>
                <td>&nbsp;&nbsp;</td>
                <td style={{ fontWeight: "bold", padding: ".2rem" }}>
                  Processo:
                </td>
                <td align="left">
                  {getNrProcesso()}/{getAnoPro()}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", padding: ".2rem" }}>DI</td>
                <td align="left">{data["di_pro"]}</td>
                <td>&nbsp;&nbsp;</td>
                <td>&nbsp;&nbsp;</td>
                <td>&nbsp;&nbsp;</td>
                <td style={{ fontWeight: "bold", padding: ".2rem" }}>
                  Conhecimento:
                </td>
                <td align="left">{data["CONHECIMENTO"]}</td>
              </tr>
            </thed>
          </table>

          <div style={{ width: "100%", marginTop: ".2rem" }}>
            <table style={{ width: "100%" }} cellspacing="0">
              <thead>
                <tr
                  style={{
                    fontSize: ".8rem",
                    color: "#000",
                    fontWeight: "bold",
                    backgroundColor: "#DCDCDC",
                  }}
                  height={22}
                >
                  <td>Despesas do Processo</td>
                  <td colSpan={2} align="right">
                    Valor R$
                  </td>
                </tr>
              </thead>
              <tbody>
                {despesas.map((row, index) => {
                  return (
                    <>
                      <tr
                        style={{ fontSize: ".8rem", color: "#000" }}
                        height={22}
                      >
                        <td align="left">{row.Descricao}</td>
                        <td colSpan={2} align="right">
                          {" "}
                          {row.total}
                        </td>
                      </tr>
                    </>
                  );
                })}
                <tr
                  style={{
                    fontSize: ".8rem",
                    color: "#000",
                    fontWeight: "600",
                    backgroundColor: "#DCDCDC",
                  }}
                  height={22}
                >
                  <td>Total</td>
                  <td colSpan={2} align="right">
                    -{getTotalGeral}
                  </td>
                </tr>

                <tr
                  style={{
                    fontSize: ".8rem",
                    color: "#000",
                    fontWeight: "bold",
                    backgroundColor: "#DCDCDC",
                  }}
                  height={22}
                >
                  <td>Nota Fiscal de Serviços: {getNrNf}</td>
                  <td colSpan={2} align="right">
                    Valor
                  </td>
                </tr>
                {nfs.map((row, index) => {
                  return (
                    <>
                      <tr
                        style={{ fontSize: ".8rem", color: "#000" }}
                        height={22}
                      >
                        <td align="left">DESCONSOLIDAÇÃO</td>
                        <td colSpan={2} align="right">
                          {row.DESCONSOLIDACAO}
                        </td>
                      </tr>
                      <tr
                        style={{ fontSize: ".8rem", color: "#000" }}
                        height={22}
                      >
                        <td align="left">IRRF</td>
                        <td colSpan={2} align="right">
                          {row.IRRF}
                        </td>
                      </tr>
                      <tr
                        style={{ fontSize: ".8rem", color: "#000" }}
                        height={22}
                      >
                        <td align="left">PIS</td>
                        <td colSpan={2} align="right">
                          -{row.PIS}
                        </td>
                      </tr>
                      <tr
                        style={{ fontSize: ".8rem", color: "#000" }}
                        height={22}
                      >
                        <td align="left">COFINS</td>
                        <td colSpan={2} align="right">
                          -{row.COFINS}
                        </td>
                      </tr>
                      <tr
                        style={{ fontSize: ".8rem", color: "#000" }}
                        height={22}
                      >
                        <td align="left">CSLL</td>
                        <td colSpan={2} align="right">
                          -{row.CSLL}
                        </td>
                      </tr>
                      <tr
                        style={{ fontSize: ".8rem", color: "#000" }}
                        height={22}
                      >
                        <td align="left">Total</td>
                        <td colSpan={2} align="right">
                          -{row.TOTAL}
                        </td>
                      </tr>
                      <tr
                        style={{ fontSize: ".8rem", color: "#000" }}
                        height={22}
                      >
                        <td align="left">Total Geral</td>
                        <td colSpan={2} align="right">
                          -{row.GERAL}
                        </td>
                      </tr>
                      <br />
                    </>
                  );
                })}
                <tr
                  style={{
                    fontSize: ".8rem",
                    color: "#000",
                    fontWeight: "bold",
                    backgroundColor: "#DCDCDC",
                  }}
                  height={22}
                >
                  <td>Data do Adiantamento</td>
                  <td align="left">Histórico</td>
                  <td align="right">Valor</td>
                </tr>
                {adiantamento.map((row, index) => {
                  return (
                    <>
                      <tr
                        style={{ fontSize: ".8rem", color: "#000" }}
                        height={22}
                      >
                        <td align="left">{row.Data}</td>
                        <td align="left">{row.Adiantamento}</td>
                        <td align="right">{row.Valor}</td>
                      </tr>
                    </>
                  );
                })}
                <br />
                <tr
                  style={{
                    fontSize: ".8rem",
                    color: "#000",
                    fontWeight: "bold",
                    backgroundColor: "#DCDCDC",
                  }}
                  height={22}
                >
                  <td>Saldo do Processo a Favor do Cliente</td>
                  <td colSpan={2} align="right">
                    {saldo}
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <b>DESEMBOLSO UNITÁRIO</b>
            <table style={{ width: "auto", padding: "10px" }} cellspacing="0" border="1">
              <thead>
                <tr>
                  <td align="center">ITEM</td>
                  <td align="center">QUANTIDADE</td>
                  <td align="center">VALOR FOB UNITÁRIO</td>
                  <td align="center">TOTAL</td>
                </tr>
              </thead>
              <tbody>
                {itens.map((row, index) => {
                  return (
                    <>
                      <tr>
                        <td align="center">{row.Codigo}</td>
                        <td align="center">{row.Quantidade}</td>
                        <td align="center">{row.Unitario}</td>
                        <td align="center">{row.total}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* :
        ""} */}
    </>
  );
};

export default Dados;
