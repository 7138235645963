import React, { useState, useEffect } from "react";
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import FusionCharts from 'fusioncharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import Maps from 'fusioncharts/fusioncharts.maps';
import Charts from "fusioncharts/fusioncharts.charts";
import overlapp from "fusioncharts/fusioncharts.overlappedcolumn2d";
import widgets from "fusioncharts/fusioncharts.widgets";
import World from 'fusioncharts/maps/fusioncharts.world';
import power from "fusioncharts/fusioncharts.powercharts";
import ReactFC from 'react-fusioncharts';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import api from '../../services/api'
import { getIdEmpresa, getNomeUsuario, getNivelUsuario } from '../../services/auth';
import Container from '../../components/Container'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Fornecedores from '../../components/Charts/Fornecedores';
import { Redirect } from "react-router-dom";
import useWindowDimensions from "../../Helpers/useWindowDimensions";

ReactFC.fcRoot(FusionCharts, Charts, overlapp, widgets, power,  Maps, World, FusionTheme);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#2c2c2c',
    },
  },
}))(TableRow);


  const useStyles = makeStyles((theme) => ({
    root: {  
      background: "#232323",
      fontFamily: 'GHOTIC',
      color: '#858585',
      alignItems: 'center',
      verticalAlign: 'middle',
      border: 'none',      
      fontWeight: 'bold',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    container: {
      display: 'flex',
      marginLeft: '10px',
      marginTop: '10px',
      height: '35px',
      backgroundColor: "#232323",
    },
      tablePagination: {
        },
      tablePaginationCaption: {
          color:  "#8F929B",
          fontWeight: 'bold',
          fontSize: '0.9rem'
        },
      tablePaginationSelectIcon: {
          color:  "#8F929B",
          fontWeight: 'bold',
        },
      tablePaginationSelect: {
          color:  "#8F929B",
          fontWeight: 'bold',
        },
      tablePaginationActions: {
          color:  "#8F929B",
          fontWeight: 'bold',
        },
      }));

      function dataInicialFormatada(){
        var data = new Date(),
            mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = (mes.length === 1) ? '0'+mes : mes,
            anoF = data.getFullYear();
        return anoF+"-"+mesF+"-01";   
      }
    
      function dataAtualFormatada(){
        var data = new Date(),
            dia  = data.getDate().toString(),
            diaF = (dia.length === 1) ? '0'+dia : dia,
            mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = (mes.length === 1) ? '0'+mes : mes,
            anoF = data.getFullYear();
        return anoF+"-"+mesF+"-"+diaF;   
      }
      const valueDataI = dataInicialFormatada();
      const valueDataF = dataAtualFormatada();
      


export default function Relatorios(){
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dataPrevisao, setDataPrevisao] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [dataEmbarque, setEmbarqueCont] = useState([]);
  const [selected, setSelected] = React.useState([]);
  const [dataEmbarqueeAereo, setEmbarqueAereo] = useState([]); 
  const [dataTopFornecedor, setDataTopFornecedor] = useState([]);  
  const [dataCanais, setDataCanais] = useState([]);      
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const matches = useMediaQuery('(max-width:1366px)');
  const COLORS = ['#01b8aa', '#f2c80f',  '#fd625e', '#5f6b6d', '#8ac8bb', '#fdcb6e', '#ff7675', '#3498db'];
  const [valueDataIni, setValueDataIni] = useState('');
  const [valueDataFim, setValueDataFim] = useState('');  
  const { height, width } = useWindowDimensions(); 


  

  const handleChangePage = (event, newPage) => {  
    setPage(newPage);  
  };  

  const handleChangeRowsPerPage = event => {  
    setRowsPerPage(+event.target.value);  
    setPage(0);  
  };  

  useEffect(() => {
    
    const getPrevisao = async () => {
      const result = await api.get('/api/empresas/prevchegada/'+getIdEmpresa());    
      setDataPrevisao(result.data.result);    
      }
      const getEmbarqueContinente = async () => {
        let filtro = ""
        filtro = `where (p.Cod_Consignee = ${getIdEmpresa()}  or p.cod_importador = ${getIdEmpresa()} ) and p3.confirmacaodata between ' ${dataInicialFormatada()} ' and ' ${dataAtualFormatada()} ' and p.CanceladoData is null`;
        const result = await api.post('/api/empresas/ec2/',{filtro});    
        setEmbarqueCont(result.data);                  
      }

      const GetEmbarqueAeroporto = async () => {
        let filtro = ""
        filtro = ` where (p.Cod_Consignee =  ${getIdEmpresa()}  or p.cod_importador =  ${getIdEmpresa()} ) and p3.confirmacaodata between '${ dataInicialFormatada()} ' and '${dataAtualFormatada()}' and p.CanceladoData is null`;          
        const result = await api.post('/api/empresas/ap/',{filtro});    
        setEmbarqueAereo(result.data.result);                  
      }
      const GetTopFornecedores = async () => {
        let filtro = ""
        filtro = ` where (p.Cod_Consignee = ${getIdEmpresa()} or p.cod_importador = ${getIdEmpresa()}) and p.dt_ab_pro between ' ${dataInicialFormatada()} ' and ' ${dataAtualFormatada()}' and p.CanceladoData is null`;    
        const result = await api.post('/api/empresas/topfornecedores/',{filtro});    
        setDataTopFornecedor(result.data.result);                  
      }
      const GetCanais = async () => {
        let filtro = ""
        filtro = ` where (p.Cod_Consignee = ${getIdEmpresa()} or p.cod_importador = ${getIdEmpresa()}) and p.dt_lib_pro between ' ${dataInicialFormatada()} ' and ' ${dataAtualFormatada()} ' and p.CanceladoData is null`;     
        const result = await api.post('/api/empresas/canais/',{filtro});    
        setDataCanais(result.data.result);                  
      }
      if (!getNomeUsuario()){
        return <Redirect to='/'  />
      }
      

      if (getIdEmpresa() > 0){
        GetEmbarqueAeroporto();
        GetTopFornecedores();
        GetCanais();
        getPrevisao();
        getEmbarqueContinente();
      }
  }, [])
  if (!getNomeUsuario()){
    return <Redirect to='/'  />
  }

  if (getNivelUsuario() > 0){
    return <Redirect to='/'  />
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
  }
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const chartConfigs = {
    type: 'world',
    width: "100%",
    height: 380,
    dataFormat: 'json',
    dataSource: dataEmbarque,    
  };

  const GetEmbarqueContinente2 = async () => {
    let dI = '';
    let dF = '';
    valueDataIni === '' ? dI = dataInicialFormatada() : dI = valueDataIni; 
    valueDataFim === '' ? dF = dataAtualFormatada() : dF = valueDataFim;
    let filtro = ""
    filtro = ` where (p.Cod_Consignee =  ${getIdEmpresa()}  or p.cod_importador = ${getIdEmpresa()}) and p3.confirmacaodata between ' ${dI} '  and '  ${dF}' and p.CanceladoData is null`;
    const result = await api.post('/api/empresas/ec2/',{filtro});    
    setEmbarqueCont(result.data);                  
  }

  const GetEmbarqueAeroporto2 = async () => {
    let dI = '';
    let dF = '';
    valueDataIni === '' ? dI = dataInicialFormatada() : dI = valueDataIni; 
    valueDataFim === '' ? dF = dataAtualFormatada() : dF = valueDataFim;
    let filtro = ""
    filtro = ` where (p.Cod_Consignee = ${getIdEmpresa()} or p.cod_importador = ${getIdEmpresa()}) and p3.confirmacaodata between '${dI}' and '${dF}' and p.CanceladoData is null`;          
    const result = await api.post('/api/empresas/ap/',{filtro});    
    setEmbarqueAereo(result.data.result);                  
  }
  const GetTopFornecedores2 = async () => {
    let dI = '';
    let dF = '';
    valueDataIni === '' ? dI = dataInicialFormatada() : dI = valueDataIni; 
    valueDataFim === '' ? dF = dataAtualFormatada() : dF = valueDataFim;
    let filtro = ""
    filtro = ` where (p.Cod_Consignee =  ${getIdEmpresa()} or p.cod_importador = ${getIdEmpresa()}) and p.dt_ab_pro between '${dI} ' and ' ${dF}' and p.CanceladoData is null`;          
    const result = await api.post('/api/empresas/topfornecedores/',{filtro});    
    setDataTopFornecedor(result.data.result);                  
  }
  const GetCanais2 = async () => {
    let dI = '';
    let dF = '';
    valueDataIni === '' ? dI = dataInicialFormatada() : dI = valueDataIni; 
    valueDataFim === '' ? dF = dataAtualFormatada() : dF = valueDataFim;
    let filtro = ""
    filtro = ` where (p.Cod_Consignee = ${getIdEmpresa()} or p.cod_importador = ${getIdEmpresa()}) and p.dt_lib_pro between '${dI}' and '${dF}' and p.CanceladoData is null`;          
    const result = await api.post('/api/empresas/canais/',{filtro});    
    setDataCanais(result.data.result);                  
  }

  
  function atualizar(){
    if (getIdEmpresa() > 0){
      GetEmbarqueContinente2();  
      GetEmbarqueAeroporto2();  
      GetTopFornecedores2()
      GetCanais2();
    }
  }    

  return (
    <>
      <Header />
      <Container>
        <div className="App__relatorios">
        <div style={{width: '40%', margin: '0 20PX', float: 'left'}}>
          <TableContainer>
          <div className="App__relatorios__paper">PREVISÕES DE CHEGADA</div>
            <Table aria-labelledby="tableTitle" size='small' aria-label="enhanced table">  
              <TableHead> 
                <TableRow>
                  <td align="center" className="App__relatorios__table__cell">PROCESSO</td>
                  <td align="center" className="App__relatorios__table__cell">DATA CHEGADA</td>
                  <td align="center" className="App__relatorios__table__cell">MODALIDADE</td>
                  <td align="center" className="App__relatorios__table__cell">NAVIO / VOÔ</td>
                  <td align="center" className="App__relatorios__table__cell">DESTINO</td>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(dataPrevisao, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {  
                    const isItemSelected = isSelected(row.nro_pro);
                    const labelId = `enhanced-table-checkbox-${index}`;                    
                    return (  
                        <StyledTableRow   
                        hover
                        onClick={(event) => handleClick(event, row.nro_pro)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.processoid}
                        selected={isItemSelected}
                        >  
                          <td align="center" id={labelId} className="App__relatorios__table__dados" width={'10%'}>{row.nro_pro}/{row.ano_pro}</td>  
                          <td align="center" className="App__relatorios__table__dados" width={'10%'}>{row.previsaodata}</td>  
                          <td align="center" className="App__relatorios__table__dados" width={'10%'}>{row.via_fretemodo}</td>  
                          <td align="center" className="App__relatorios__table__dados" width={'10%'}>{row.nome}</td>  
                          <td align="center" className="App__relatorios__table__dados" width={'10%'}>{row.nome_portoaeroporto}</td>  
                        </StyledTableRow>  
                        );  
                  })}  
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination  
                rowsPerPageOptions={[10, 15, 20]}  
                component="div"  
                count={dataPrevisao.length}  
                rowsPerPage={rowsPerPage}  
                page={page} 
                labelRowsPerPage={"REGISTROS POR PÁGINA"}
                classes={{
                    root: classes.tablePagination,
                    caption: classes.tablePaginationCaption,
                    selectIcon: classes.tablePaginationSelectIcon,
                    select: classes.tablePaginationSelect,
                    actions: classes.tablePaginationActions,
                  }}
                onChangePage={handleChangePage}  
                onChangeRowsPerPage={handleChangeRowsPerPage}  
          /> 
        </div>
        <div style={{width: '100%'}}>
            <div className="App__relatorios__filtro">
                <div className="App__relatorios__filtro__marcador"/>
                <span className="App__relatorios__filtro__marcador__span">PERÍODO</span>
                <form className={classes.container} noValidate>
                  <input type="date" id="datei" className={classes.root}
                    defaultValue={valueDataI}                      
                    onChange={e => setValueDataIni(e.target.value)}/>
                </form>
                <span className="App__relatorios__filtro__marcador__span">A</span>
                <form className={classes.container} noValidate>
                  <input type="date" id="datef" className={classes.root}
                    defaultValue={valueDataF}                      
                    onChange={e => setValueDataFim(e.target.value)}/>
                </form>
                <button type="submit" 
                        className="App__relatorios__filtro__btn"
                        onClick={atualizar}                      
                        >ATUALIZAR</button>  
              </div>    
          <div className="App__relatorios__charts" style={{float: 'left'}}>
            <div style={{width: width <=  1366 ? '25rem' : '50rem'}}>
            <div className="App__relatorios__charts__top__tl">
                  <div className="App__relatorios__charts__top__tl__marcador"/>
                  <span className="App__relatorios__charts__top__tl__span">EMBARQUE POR CONTINENTE</span>
                  <div className="App__relatorios__charts__top__tl__grafico">
                  <ReactFC {...chartConfigs}
                    fcEvent-initialized={this}
                    ref={React.createRef()}/>
                  </div>                
                  </div>
            </div>
            <div style={{width: width <=  1366 ? '22rem' : '50rem'}}>
              <div className="App__relatorios__charts__bottom">
                  <div className="App__relatorios__charts__bottom__bl">
                    <div className="App__relatorios__charts__bottom__bl__marcador"/>
                    <div className="App__relatorios__charts__bottom__bl__grafico">
                      <Fornecedores dataFornecedores={dataEmbarqueeAereo} colors={COLORS} width={matches ? 280 : 340} height={matches ? 180 : 320} radius={matches ? 50 : 70} cx={matches ? 60 : 100}  cy={matches ? 50 : 80} sliceQtde={8} title={"PRINCIPAIS AEROPORTOS"}/>
                    </div>
                  </div>
                  <div className="App__relatorios__charts__top__tr">
                    <div className="App__relatorios__charts__top__tr__marcador"/>
                      <div className="App__relatorios__charts__top__tr__grafico">
                        <Fornecedores dataFornecedores={dataTopFornecedor} colors={COLORS} width={matches ? 290 : 360} height={matches ? 180 : 320} radius={matches ? 50 : 70} cx={matches ? 50 : 100}  cy={matches ? 50 : 80} sliceQtde={8} title={"PRINCIPAIS FORNECEDORES"}/>
                      </div>
                    </div>
                  <div className="App__relatorios__charts__bottom__br">
                    <div className="App__relatorios__charts__bottom__br__marcador"/>
                      <div className="App__relatorios__charts__bottom__br__grafico">
                        <Fornecedores dataFornecedores={dataCanais} colors={COLORS} width={matches ? 250 : 300} height={matches ? 180 : 320} radius={matches ? 50 : 70} cx={matches ? 60 : 100}  cy={matches ? 50 : 80} sliceQtde={8} title={"CANAIS DE PARAMETRIZAÇÃO"}/>
                      </div>
                  </div>
                  
                </div>
            </div>
          </div>
        </div> 
        </div>
      </Container>
      <Footer />
  
    </>
  )
}
