import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import api from '../../services/api'

import leader from '../../assets/imgs/leaderlog2.png'
import logo from '../../assets/imgs/logo3S2.png'
import ship from '../../assets/imgs/cargo-ship256.png'
import shipGray from '../../assets/imgs/cargo-ship-gray.png'
import air from '../../assets/imgs/air-freight-red.png'
import airGray from '../../assets/imgs/air-freight-gray.png'


const Tracking = () => {
  const [getData, setData] = useState([]);
  const [getTransbordo, setTransbordo] = useState([]);
  const [modal, setModal] = useState('');

  // console.log('Tracking')
  const { id } = useParams();
  useEffect(() => {
    let i = '';
    const GetData = async () => {
      i = id.substring(0, 2);
      setModal(i);
      if (i === 'IM') {
        const result = await api.get('/api/hs/tracking/' + id);

        console.log(result.data.result)
        setData(result.data.result);
        setTransbordo(result.data.transbordo)
      } else if (i === 'IA') {
        const result = await api.get('/api/hs/trackinga/' + id);
        console.log(result.data.result)
        setData(result.data.result);
        setTransbordo(result.data.escala)
      }
    }
    if (id != '') {
      GetData();
    }


  }, []);

  return (
    <>
      <div className="App">
        <header className="header">
          <img src={leader} />
          <img src={logo} />
        </header>

        {getData.map((item) =>

          <>
            <div className="tracking">
              <span className="trackingSpan">Tracking</span>
            </div>
            <div className="dadosBL">
              <div className="bl">
                {modal === 'IM' ?
                  <span className="span">B/L No.: {item.Conhecimentos}</span>
                  :
                  <span className="span">AWB No.: {item.Conhecimentos}</span>
                }
              </div>
              <span className="spanRed">* Vermelho: Confirmado</span>
              <span className="spanGray">* Cinza: Estimado</span>
              <br />
            </div>
            <div className="embarque">
              {modal === 'IM' ? <span>Porto de Embarque</span> : <span>Aeroporto de Embarque</span>}
            </div>
            <div className="dadosBL">

              {modal === 'IM' ?
                <div className="porto">
                  <img src={item.ConfEmbarque !== null ? ship : shipGray} alt="" width={80} />
                  {item.ConfEmbarque !== null ?
                    <>
                      <span className="spanRed">{item.Origem}, {item.ConfEmbarque !== null ? item.ConfEmbarque : item.PrevEmbarque}</span>
                    </>
                    :
                    <span className="spanGray">{item.Origem}, {item.ConfEmbarque !== null ? item.ConfEmbarque : item.PrevEmbarque}</span>
                  }
                </div>
                :
                <div className="porto">
                  <img src={item.ConfEmbarque !== null ? air : airGray} alt="" width={80} />
                  {item.ConfEmbarque !== null ?
                    <>
                      <span className="spanRed">{item.Origem}, {item.ConfEmbarque !== null ? item.ConfEmbarque : item.PrevEmbarque}</span>
                    </>
                    :
                    <span className="spanGray">{item.Origem}, {item.ConfEmbarque !== null ? item.ConfEmbarque : item.PrevEmbarque}</span>
                  }
                </div>
              }
            </div>
            <div className="embarque">
              {modal === 'IM' ? <span>Porto de Desembarque</span> : <span>Aeroporto de Desembarque</span>}
            </div>
            <div className="dadosBL">
              {modal === 'IM' ?
                <di className="porto">
                  <img src={item.ConfDesembarque !== null ? ship : shipGray} alt="" width={80} />
                  {item.ConfDesembarque !== null ?
                    <span className="spanRed">{item.Destino}, {item.ConfDesembarque !== null ? item.ConfDesembarque : item.PrevDesembarque}</span>
                    :
                    <span className="spanGray">{item.Destino}, {item.ConfDesembarque !== null ? item.ConfDesembarque : item.PrevDesembarque}</span>
                  }
                </di>
                :
                <di className="porto">
                  <img src={item.ConfDesembarque !== null ? air : airGray} alt="" width={80} />
                  {item.ConfDesembarque !== null ?
                    <span className="spanRed">{item.Destino}, {item.ConfDesembarque !== null ? item.ConfDesembarque : item.PrevDesembarque}</span>
                    :
                    <span className="spanGray">{item.Destino}, {item.ConfDesembarque !== null ? item.ConfDesembarque : item.PrevDesembarque}</span>
                  }
                </di>
              }
            </div>
            <br />
            <div className="embarque">
              {modal === 'IM' ? <span>Porto de Transbordo</span> : <span>Aeroporto de Escala</span>}
            </div>
            {modal === 'IM' ?
              <div className="dadosBL">
                {getTransbordo.map((transbordo) => {
                  return (
                    <div className="portoTransbordo">
                      <img src={transbordo.ConfTransbordo !== null ? ship : shipGray} alt="" width={80} />
                      <span>{transbordo.Transbordo}, {transbordo.ConfTransbordo !== null ? transbordo.ConfEmbarque : transbordo.PrevTransbordo}</span>
                    </div>
                  )
                })}
              </div>
              :
              <div className="dadosBL">
                {getTransbordo.map((transbordo) => {
                  return (
                    <div className="portoTransbordo">
                      <img src={transbordo.ConfTransbordo !== null ? air : airGray} alt="" width={80} />
                      {transbordo.ConfTransbordo !== null ?
                        <span className="spanRed">{transbordo.Transbordo}, {transbordo.ConfTransbordo !== null ? transbordo.ConfTransbordo : transbordo.PrevTransbordo}</span>
                        :
                        <span className="spanGray">{transbordo.Transbordo}, {transbordo.ConfTransbordo !== null ? transbordo.ConfTransbordo : transbordo.PrevTransbordo}</span>
                      }
                    </div>
                  )
                })}
              </div>
            }
            <br />
            <hr />
            <br />
            <div className="footer" >
              <p>Todas as datas são estimadas e sujeitas a alterações.</p>
              <p>Consulte seu analista em caso de dúvidas.</p>
            </div>
            <br />
            <br />
          </>

        )}
      </div>
    </>
  );
}

export default Tracking;
