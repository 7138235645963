import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/Container'
import api from '../../services/api';
import Header from '../../components/Header'
import { Redirect, useLocation } from "react-router-dom";
import { getNomeUsuario, getNivelUsuario } from '../../services/auth';
import TableFPagar from '../../components/Table/TableFPagar'

    function dataInicialFormatada() {
        var dia, diaF, mes, mesF, dataFinal;
        var data = new Date(new Date().setDate(new Date().getDate() - 30));
        dia  = data.getDate().toString();
        diaF = (dia.length === 1) ? '0'+dia : dia;
        mes  = (data.getMonth()+1).toString(); //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length === 1) ? '0'+mes : mes;
        dataFinal =data.getFullYear()+"-"+mesF+"-"+dia;
        return dataFinal;   
    }

    function dataAtualFormatada(){
        var data = new Date(),
        dia  = data.getDate().toString(),
        diaF = (dia.length === 1) ? '0'+dia : dia,
        mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length === 1) ? '0'+mes : mes,
        anoF = data.getFullYear();
        return diaF+"/"+mesF+"/"+anoF;   
    }

    const valueDataI = dataInicialFormatada();
    const valueDataF = dataAtualFormatada();    

    const useStyles = makeStyles((theme) => ({
        root: {
            background: "#242628",
            fontFamily: 'GHOTIC',
            color: '#696C72',
            alignItems: 'center',
            verticalAlign: 'middle',
            border: 'none',      
            fontWeight: 'bold',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),                        
          },
          container: {
            display: 'flex',
            marginLeft: '10px',
            marginTop: '10px',
            height: '35px',
            backgroundColor: "#242628",
          },
          tableTr:{
            fontSize: '0.8rem',
            fontWeight: 'bold',
            backgroundColor: '#232323', 
            color: "#858585",   
          },
          tableTdL:{
            width: '250px',
            textAlign: 'left',
          },
          tableTdR:{
            textAlign: 'right'
          },
          tableTdRW:{
            textAlign: 'right',
            fontWeight: 'bold',
          },
        tablePagination: {
          },
        tablePaginationCaption: {
            color:  "#858585",
            fontWeight: 'bold',
            fontSize: '0.9rem'
          },
        tablePaginationSelectIcon: {
            color:  "#858585",
            fontWeight: 'bold',
          },
        tablePaginationSelect: {
            color:  "#858585",
            fontWeight: 'bold',
          },
        tablePaginationActions: {
            color:  "#858585",
            fontWeight: 'bold',
          },
          indicator: {
            backgroundColor: '#858585',
            height: '3px'
          },
    }));

const FinApagar = () =>{
    const currentPath = useLocation();
    const  classes = useStyles();
    const [getTitle, setTitle] = useState('VALORES NÃO PAGOS');
    const [valueDataFim, setValueDataFim] = useState('');   
    const [getData, setData] = React.useState([]);    

    useEffect(() => {
      const getData = async () => {    
        const result = await api.post('/api/empresas/fincapagar/');    
        setData(result.data.result.filter(contas => contas.dt_pgto_cop === null));     	    
      }  

      getData();        
    }, []);

    if (!getNomeUsuario()){
        return <Redirect to='/'  />
    }

    if (getNivelUsuario() === 0 ){
      return <Redirect to='/'  />
    }
    if ((getNivelUsuario() === 2)){
      return <Redirect to='/'  />
    }

    const getDataPagar = async (qual) => {    
      qual === 1 ? setTitle("VALORES NÃO PAGOS") :      	
      qual === 2 ? setTitle("VALORES PAGOS") :       
      setTitle("VALORES ATRASADOS");      
      const result = await api.post('/api/empresas/fincapagar/');    
      
      qual === 1 ? setData(result.data.result.filter(contas => contas.dt_pgto_cop === null)) :      	
      qual === 2 ? setData(result.data.result.filter(contas => contas.dt_pgto_cop !== null)) :       
      setData(result.data.result.filter(contas => contas.situacao === 'Atrasado'));      
    }  

    
    
    function atualizar(qual){
      getDataPagar(qual);
     
    }

    return(
        <>
            <Header currentPath={currentPath.pathname}/> 
           
            <Container>
            <div style={{display: 'flex', width: '100%'}}>
              <button className="App__button2" onClick={e => atualizar(1)}>Não Pago </button>
              <button className="App__button2" onClick={e => atualizar(2)}>Pago </button>
              <button className="App__button2" onClick={e => atualizar(3)}>Atrasado </button>
            </div>
                <div className="App__homepage">                  
                    <TableFPagar data={getData} qual={getTitle} />
                </div>
            </Container>
        </>
    );

}
export default FinApagar;