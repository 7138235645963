import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'
import { makeStyles, withStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    filtros:{
        width: '100%',
        display: 'block'
    },    
    
    filtros2:{
        margin: '0 auto',
        width: '90%',
        display: 'flex',
        justifyContent: 'space-between',
    },    
    filtros3:{
        margin: '10px 70px',
        display: 'flex',
        justifyContent: 'space-between',
    },    
    title:{
        textAlign: 'center',
        height: 'auto',
        margin: '0 auto',
        padding: '10px',
        fontSize: '16px',
    },
    resultado: {
        marginTop: '10px',        
        marginBottom: '10px',
        marginLeft: '10px',
        marginRight: '10px',
        height: 'auto',
    },
    table:{
        borderRadius: '5px',
        height: '20px',
        padding: '0',
        
    },
    btnAtivo:{
        border: 'none',
        backgroundColor: 'transparent',
        color: 'green',
        cursor: 'normal'
    },
    btn:{
        border: 'none',
        backgroundColor: 'transparent',
        color: 'red'
    },
    inputFiltros: {
        width: "200px",
        fontSize: '0.65em',
    },
    inputFiltros2: {
        width: "200px",
        fontSize: '0.65em',
    },
    rowHeight:{
        height: 24,
    }
}));

const StyledTableCell = withStyles((theme) => ({
    root: {
        borderBottom: "none"
      },
    head: {
        backgroundColor: "#242628",
        color: "#858585",
        fontSize: 12,
        fontWeight: 'bold',
    },
    body: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#8F929B'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#2c2c2c',
        }
    },
}))(TableRow);

export default function CustoItens({data}){
    const classes = useStyles();
    return (
        <>
            <TableContainer>  
                <Table size='small' border='1px solid #000'>
                    <TableHead>
                        <TableRow  className={classes.table}>
                            <StyledTableCell align="center" colSpan={12} style={{borderRight: '1px solid grey', borderBottom: 'none', fontSize: '10px'}}>VALORES ADUANEIROS</StyledTableCell>
                            <StyledTableCell align="center" colSpan={5} style={{borderRight: '1px solid grey', borderBottom: 'none', fontSize: '10px'}}>ENCARGOS TRIBUTÁRIOS</StyledTableCell>
                        </TableRow>
                        <TableRow  className={classes.table}>
                            <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'auto', whiteSpace: 'nowrap'}}>FOB</StyledTableCell>
                            <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'auto', whiteSpace: 'nowrap'}}>MOEDA</StyledTableCell>
                            <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'auto', whiteSpace: 'nowrap'}}>TAXA</StyledTableCell>
                            <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'auto', whiteSpace: 'nowrap'}}>FOB EM R$</StyledTableCell>
                            <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'auto', whiteSpace: 'nowrap'}}>FRETE</StyledTableCell>
                            <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'auto', whiteSpace: 'nowrap'}}>MOEDA</StyledTableCell>
                            <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'auto', whiteSpace: 'nowrap'}}>TAXA</StyledTableCell>
                            <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'auto', whiteSpace: 'nowrap'}}>FRETE EM R$</StyledTableCell>
                            <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'auto', whiteSpace: 'nowrap'}}>SEGURO</StyledTableCell>
                            <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'auto', whiteSpace: 'nowrap'}}>MOEDA</StyledTableCell>
                            <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'auto', whiteSpace: 'nowrap'}}>TAXA</StyledTableCell>
                            <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'auto', whiteSpace: 'nowrap'}}>SEGURO EM R$</StyledTableCell>
                            <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'auto', whiteSpace: 'nowrap'}}>II</StyledTableCell>
                            <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'auto', whiteSpace: 'nowrap'}}>IPI</StyledTableCell>
                            <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'auto', whiteSpace: 'nowrap'}}>PIS</StyledTableCell>
                            <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'auto', whiteSpace: 'nowrap'}}>CONFIS</StyledTableCell>
                            <StyledTableCell style={{textAlign: 'center', fontSize: '10px', width:'auto', whiteSpace: 'nowrap'}}>ICMS</StyledTableCell>                                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => {  
                        return ( 
                            <StyledTableRow key={row.processoid}>
                                <StyledTableCell style={{fontSize: '10px', width:'auto'}}>{row.vlr_fob_pro}</StyledTableCell>
                                <StyledTableCell style={{fontSize: '10px', width:'auto'}}>{row.moeda_vlr_fob_pro}</StyledTableCell>
                                <StyledTableCell style={{fontSize: '10px', width:'auto'}}>{row.taxafob}</StyledTableCell>
                                <StyledTableCell style={{fontSize: '10px', width:'auto'}}>{row.fobtotal}</StyledTableCell>
                                <StyledTableCell style={{fontSize: '10px', width:'auto'}}>{row.frete_imp}</StyledTableCell>
                                <StyledTableCell style={{fontSize: '10px', width:'auto'}}>{row.moeda_frete_imp}</StyledTableCell>
                                <StyledTableCell style={{fontSize: '10px', width:'auto'}}>{row.taxafob}</StyledTableCell>
                                <StyledTableCell style={{fontSize: '10px', width:'auto'}}>{row.freteotal}</StyledTableCell>
                                <StyledTableCell style={{fontSize: '10px', width:'auto'}}>{row.vlr_seguro_pro}</StyledTableCell>
                                <StyledTableCell style={{fontSize: '10px', width:'auto'}}>{row.moeda_vlr_seg_pro}</StyledTableCell>
                                <StyledTableCell style={{fontSize: '10px', width:'auto'}}>{row.taxafob}</StyledTableCell>
                                <StyledTableCell style={{fontSize: '10px', width:'auto'}}>{row.seguroetotal}</StyledTableCell>
                                <StyledTableCell style={{fontSize: '10px', width:'auto'}}>{row.II}</StyledTableCell>
                                <StyledTableCell style={{fontSize: '10px', width:'auto'}}>{row.IPI}</StyledTableCell>
                                <StyledTableCell style={{fontSize: '10px', width:'auto'}}>{row.COFINS}</StyledTableCell>
                                <StyledTableCell style={{fontSize: '10px', width:'auto'}}>{row.ICMS}</StyledTableCell>
                                <StyledTableCell style={{fontSize: '10px', width:'auto'}}>{row.PIS}</StyledTableCell>                                
                            </StyledTableRow>
                            );
                        })}     
                    </TableBody>
                </Table> 
            </TableContainer>  
        </>
    );
}